<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_delete_location')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteLocation"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_delete_location", {
                            locationName: getModalData.location.Name,
                        })
                    }}
                    <span class="bold-font-style">{{
                        $t("modals.are_you_sure")
                    }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import {
    LOCATION_DELETE,
    LOCATIONS_GET_COMPANY_LOCATIONS,
} from "@/store/types/locations";
import { M_LOCATION_DELETE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

export default {
    data() {
        return {
            type: M_LOCATION_DELETE,
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getActiveLocation"]),
    },
    methods: {
        deleteLocation() {
            const isActive =
                this.getActiveLocation &&
                this.getActiveLocation.Id == this.getModalData.location.Id;
            const companyId = this.getModalData.location.CompanyId;
            this.$store
                .dispatch(LOCATION_DELETE, this.getModalData.location)
                .then(() => {
                    if (isActive) {
                        this.$router.push({
                            name: "dashboard",
                        });
                    } else {
                        this.$store.dispatch(
                            LOCATIONS_GET_COMPANY_LOCATIONS,
                            companyId
                        );
                    }
                });
        },
        onClose() {
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>

<style>
#card-title {
    font-size: 1em;
}
</style>
