export const companyTypes = [
    {
        key: "companies.types_0",
        value: 0,
        disabled: false,
    },
    {
        key: "companies.types_1",
        value: 1,
        disabled: true,
    },
    {
        key: "companies.types_2",
        value: 2,
        disabled: true,
    },
];

export const doorTypes = [
    {
        key: "doors.types_0",
        value: 0,
    },
    {
        key: "doors.types_1",
        value: 1,
    },
    {
        key: "doors.types_2",
        value: 2,
    },
    {
        key: "doors.types_3",
        value: 3,
    },
    {
        key: "doors.types_4",
        value: 4,
    },
    {
        key: "doors.types_5",
        value: 5,
    },
    {
        key: "doors.types_6",
        value: 6,
    },
    {
        key: "doors.types_7",
        value: 7,
    },
];

export const deviceProviders = [
    {
        key: "Danalock",
        value: 0,
        enabled: 1
    },
    {
        key: "Doorpad",
        value: 1,
        enabled: 1
    },
    {
        key: "Yale",
        value: 2,
        enabled: 0
    },
    {
        key: "ModuleX",
        value: 3,
        enabled: 1
    },
    {
        key: "Salto",
        value: 4,
        enabled: 1
    },
    {
        key: "Salto Online",
        value: 5,
        enabled: 1
    },
    {
        key: "Salto Relay",
        value: 7,
        enabled: 1
    },
    {
        key: "Inlet Online",
        value: 6,
        enabled: 1
    },
];

export const doorpadCodeTypes = [
    {
        key: "YaleDoorman",
        value: 1,
    },
    {
        key: "IDLock",
        value: 2,
    },
    {
        key: "companies.types_2",
        value: 3,
    },
];

export const inviteStatuses = [
    {
        key: "Created",
        variant: "secondary",
        value: 0,
    },
    {
        key: "Pending",
        variant: "warning",
        value: 1,
    },
    {
        key: "Canceled",
        variant: "danger",
        value: 2,
    },
    {
        key: "Accepted",
        variant: "success",
        value: 3,
    },
];

export const keyAccessTypes = [
    {
        key: "Permanent",
        value: 4,
    },
    {
        key: "Temporary",
        value: 5,
    },
];

export const weekDays = [
    {
        DayNumber: 0,
        DayName: "M",
        active: true,
        timeFrom: "00:00",
        timeTo: "23:59",
    },
    {
        DayNumber: 1,
        DayName: "T",
        active: true,
        timeFrom: "00:00",
        timeTo: "23:59",
    },
    {
        DayNumber: 2,
        DayName: "W",
        active: true,
        timeFrom: "00:00",
        timeTo: "23:59",
    },
    {
        DayNumber: 3,
        DayName: "T",
        active: true,
        timeFrom: "00:00",
        timeTo: "23:59",
    },
    {
        DayNumber: 4,
        DayName: "F",
        active: true,
        timeFrom: "00:00",
        timeTo: "23:59",
    },
    {
        DayNumber: 5,
        DayName: "S",
        active: true,
        timeFrom: "00:00",
        timeTo: "23:59",
    },
    {
        DayNumber: 6,
        DayName: "S",
        active: true,
        timeFrom: "00:00",
        timeTo: "23:59",
    },
];
export const getWeekDays = () => JSON.parse(JSON.stringify(weekDays));
export const getDeviceProvider = (id) =>
    deviceProviders.find((item) => item.value == id);
