import KeyController from "../../api/key";
import MemberController from "../../api/member";

import {
    KEY_REQUEST,
    KEY_ERROR,
    KEY_SUCCESS,
    KEYS_GET,
    KEYS_CLEAR,
    KEYS_FILL,
    KEY_SET_ACTIVE,
    KEY_CLEAR_ACTIVE,
    KEY_GET,
    KEY_DEVICES_GET,
    KEY_DEVICES_FILL,
    KEY_DEVICE_ADD,
    KEY_DEVICES_ADD,
    KEY_DEVICE_REMOVE,
    KEY_USER_REMOVE,
    KEY_DELETE,
    KEY_PATCH,
    KEY_CREATE,
    KEY_INVITES_GET,
    KEY_INVITES_FILL,
    KEY_DEVICES_CLEAR,
    KEY_INVITES_CLEAR,
    KEY_USERS_GET,
    KEY_USERS_FILL,
    KEY_USERS_CLEAR, KEY_USER_MEMBER_REMOVE
} from "../types/keys";

import {
    STATUS_KEY_REQUEST,
    STATUS_KEY_SUCCESS,
    STATUS_KEY_ERROR
} from "../types/statuses";

import {
    DEVICE_KEYS_GET
} from "@/store/types/devices";

import { MEMBER_KEYS_GET } from "@/store/types/members";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const getDefaultState = () => {
    return {
        activeKey: null,
        keys: [],
        keyDevices: [],
        keyInvites: [],
        allKeysUsers: {},
        status: "",
        requestErrors: ""
    };
};

const state = getDefaultState();

const getters = {
    getActiveKey: state => state.activeKey,
    getKeyById: state => id => state.keys.find(key => key.Id == id),
    getKeyUsersById: state => id => state.allKeysUsers[id] || [],
    getLocationKeys: state => state.keys,
    getKeyDevices: state => state.keyDevices,
    getKeyInvites: state => state.keyInvites,
    getKeyRequestErrors: state => state.requestErrors,
    isKeyRequestOn: state => state.status == STATUS_KEY_REQUEST
};

const actions = {
    [KEYS_GET]: ({
        commit,
        dispatch
    }, locationId) => {
        commit(KEY_REQUEST);
        return KeyController.getKeys(locationId)
            .then(response => {
                commit(KEY_SUCCESS);
                commit(KEYS_FILL, response.data);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_GET]: ({
        commit,
        dispatch
    }, keyId) => {
        commit(KEY_REQUEST);
        return KeyController.getKey(keyId)
            .then(response => {
                commit(KEY_SUCCESS);
                commit(KEY_SET_ACTIVE, response.data);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_CREATE]: ({
        commit,
        dispatch
    }, key) => {
        commit(KEY_REQUEST);
        return KeyController.postKey(key)
            .then(response => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Key created",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEYS_GET, key.LocationId);
                return response;
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(KEY_REQUEST);
        return KeyController.patchKey(patch)
            .then(() => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Key updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEYS_GET, patch.LocationId);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_DELETE]: ({
        commit,
        dispatch
    }, key) => {
        commit(KEY_REQUEST);
        return KeyController.deleteKey(key)
            .then(() => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Key deleted",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEYS_GET, key.LocationId);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_DEVICES_GET]: ({
        commit,
        dispatch
    }, keyId) => {
        commit(KEY_REQUEST);
        return KeyController.getKeyDevices(keyId)
            .then(response => {
                commit(KEY_SUCCESS);
                commit(KEY_DEVICES_FILL, response.data);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_INVITES_GET]: ({
        commit,
        dispatch
    }, keyId) => {
        commit(KEY_REQUEST);
        return KeyController.getKeyInvites(keyId)
            .then(response => {
                commit(KEY_SUCCESS);
                commit(KEY_INVITES_FILL, response.data);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_USERS_GET]: ({
        commit,
        dispatch
    }, keyId) => {
        commit(KEY_REQUEST);
        return KeyController.getKeyUsers(keyId)
            .then(response => {
                commit(KEY_SUCCESS);
                const data = response.data.filter(
                    (item, index) =>
                    response.data.findIndex((i) => {
                            return i.User.Id == item.User.Id;
                        }) == index
                ).sort((i1, i2) => {
                    return (i1.User.FirstName + " " + i1.User.LastName).toUpperCase() > (i2.User.FirstName + " " + i2.User.LastName).toUpperCase() ? 1 : -1;
                });
                commit(KEY_USERS_FILL, {[keyId]:data});
                return response.data;
                // commit(KEY_USERS_FILL, {[keyId]:response.data});
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_DEVICE_ADD]: ({
        commit,
        dispatch
    }, payload) => {
        commit(KEY_REQUEST);
        return KeyController.addKeyDevice(payload.keyId, payload.deviceId)
            .then(() => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door added to key",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEYS_GET, payload.locationId);
                dispatch(KEY_DEVICES_GET, payload.keyId);
                dispatch(DEVICE_KEYS_GET, payload.deviceId);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_DEVICES_ADD]: ({
        commit,
        dispatch
    }, payload) => {
        commit(KEY_REQUEST);
        return KeyController.addKeyDevices(payload.keyId, payload.devicesIds)
            .then(() => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door added to key",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEYS_GET, payload.locationId);
                dispatch(KEY_DEVICES_GET, payload.keyId);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_DEVICE_REMOVE]: ({
        commit,
        dispatch
    }, payload) => {
        commit(KEY_REQUEST);
        return KeyController.deleteKeyDevice(payload.keyId, payload.deviceId)
            .then(() => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door removed from key",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEYS_GET, payload.locationId);
                dispatch(KEY_DEVICES_GET, payload.keyId);
                dispatch(DEVICE_KEYS_GET, payload.deviceId);
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

 /*    dispatch(MEMBER_KEYS_GET, {
        locationId,
        userId
    });
    dispatch(LOCATION_INVITES_GET, locationId); */
    [KEY_USER_REMOVE]: ({
        commit,
        dispatch
    }, {
        keyId,
        userId,
        locationId
    }) => {
        commit(KEY_REQUEST);
        return KeyController.deleteKeyUser(keyId, userId)
            .then(() => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "User removed from key",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEY_USERS_GET,keyId);
                dispatch(MEMBER_KEYS_GET,{locationId,userId})
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_USER_MEMBER_REMOVE]: ({
        commit,
        dispatch
    }, {
        accessId,
        keyId,
        userId,
        locationId
    }) => {
        commit(KEY_REQUEST);
        return MemberController.deleteMemberInvite(accessId)
            .then(() => {
                commit(KEY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "User access removed from key",
                    title: "Success",
                    variant: "success"
                });
                dispatch(KEY_USERS_GET,keyId);
                dispatch(MEMBER_KEYS_GET,{locationId,userId})
            })
            .catch(error => {
                dispatch(KEY_ERROR, error);
            });
    },

    [KEY_ERROR]: ({
        commit
    }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger"
        });
        commit(KEY_ERROR, error);
    }
};

const mutations = {
    [KEY_REQUEST]: state => {
        state.status = STATUS_KEY_REQUEST;
    },
    [KEY_SUCCESS]: state => {
        state.status = STATUS_KEY_SUCCESS;
        state.requestErrors = "";
    },
    [KEY_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_KEY_ERROR;
    },
    [KEYS_FILL]: (state, keys) => {
        state.keys = keys;
    },
    [KEY_DEVICES_FILL]: (state, keyDevices) => {
        state.keyDevices = keyDevices;
    },
    [KEY_DEVICES_CLEAR]: state => {
        state.keyDevices = [];
    },
    [KEY_INVITES_FILL]: (state, keyInvites) => {
        state.keyInvites = keyInvites;
    },
    [KEY_INVITES_CLEAR]: state => {
        state.keyInvites = [];
    },
    [KEY_USERS_FILL]: (state, data) => {
        state.allKeysUsers = {...state.allKeysUsers, ...data}
    },
    [KEY_USERS_CLEAR]: (state, keyId) => {
        state.keyUsers[keyId] = [];
    },
    [KEYS_CLEAR]: state => {
        state.keys = [];
    },
    [KEY_SET_ACTIVE]: (state, key) => {
        state.activeKey = key;
    },
    [KEY_CLEAR_ACTIVE]: state => {
        state.activeKey = null;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
