<template>
    <b-navbar
        toggleable="md"
        type="light"
        sticky
        class="navbar navbar-expand-md navbar-light d-none d-md-flex"
    >
        <b-container fluid>
            <zs-breadcrumbs />
            <b-navbar-nav class="mr-lg-auto"> </b-navbar-nav>
            <navbar-user :user="getUser" :companies="getUserCompanies"/>
            <!-- <navbar-user :user="getUser" :companies="userCompanies"/> -->
        </b-container>
        <zs-company-modal />
        <zs-profile-modal />
        <zs-location-modal />
        <zs-delete-location-modal />
        <!-- <zs-image-upload-modal @imageUploaded="updateLocationImage" /> -->
    </b-navbar>
</template>

<script>
import CreateCompanyModal from "@/components/modals/CreateCompany";
import ImageUploadModal from "@/components/modals/ImageUpload";
import CreateLocationModal from "@/components/modals/CreateLocation";
import DeleteLocationModal from "@/components/modals/DeleteLocation";
import EditProfileModal from "@/components/modals/EditProfile";
import NavbarUser from "@/components/common/Nav/Header/NavbarUser";
import Breadcrumbs from "@/components/common/Nav/Breadcrumbs";
import { mapGetters } from "vuex";

import {
    COMPANIES_GET,
    COMPANY_GET,
    COMPANY_CLEAR_ACTIVE,
} from "@/store/types/companies";
import {
    LOCATIONS_GET_COMPANY_LOCATIONS,
    LOCATION_GET,
    LOCATION_CLEAR_ACTIVE,
} from "@/store/types/locations";

import { INIT_COMPLETED, INIT_STARTED } from "@/store/types/global";

import { M_COMPANY_FORM, M_LOCATION_FORM, M_IMAGE_UPLOAD } from "@/components/modals/types";
import { IMAGE_TYPE_LOCATION } from "@/handlers/const";
import {
    LOCATION_PATCH,
} from "@/store/types/locations";
import { definedKeyRolesIdsArray, maxRoleName } from "@/acl/roles";

import modalMixin from "@/mixins/modalMixin";
import {
    USER_GET,
    USER_GET_ROLES,
    USER_ROLE_CLEAR_ACTIVE,
    USER_ROLE_SET_ACTIVE,
    USER_ROLES_LIST_SET_ACTIVE
} from "@/store/types/user";
import { EventBus } from "@/main";

export default {
    data() {
        return {};
    },
    mixins: [modalMixin],
    name: "zs-header",
    computed: {
        ...mapGetters([
            "getUser",
            "getUserRoles",
            "getActiveCompany",
            "getLocationsList",
            "getActiveLocation",
            "getUserCompanies",
            "getUserNotificationsCount",
        ]),
        getCompanyToManage() {
            return this.getUserCompanies.find((item) =>
                this.$can("viewStaff", item)
            );
        },
    },
    async created() {
        this.$store.commit(INIT_STARTED);
        await Promise.all([
            this.$store.dispatch(USER_GET),
            this.$store.dispatch(USER_GET_ROLES),
            this.$store.dispatch(COMPANIES_GET)
        ])

        //console.log(this.$route.params)
        if (this.$route.params.companyId) {
            await this.setCompany(this.$route.params.companyId);
        } else {
            this.$store.commit(COMPANY_CLEAR_ACTIVE);
        }

        if (this.$route.params.locationId) {
            await this.setLocation(this.$route.params.locationId);
        } else {
            this.$store.commit(LOCATION_CLEAR_ACTIVE);
        }

        await this.updateActiveUserRole()
        this.$store.commit(INIT_COMPLETED);
    },
    methods: {
        async setCompany(companyId) {
            let response = await this.$store.dispatch(COMPANY_GET, companyId)
            await this.$store.dispatch(LOCATIONS_GET_COMPANY_LOCATIONS, companyId)
        },
        async setLocation(locationId) {
            await this.$store.dispatch(LOCATION_GET, locationId);
        },
        async updateActiveUserRole() {
            this.$store.commit(USER_ROLE_CLEAR_ACTIVE)
            let role = ""
            let uroles = []
            if (this.getActiveCompany?.Id != null) {
                uroles = this.getUserRoles.filter(item => item.CompanyId == this.getActiveCompany.Id).map(item => item.Name)
                role = maxRoleName(uroles)
            }
            if (!role && this.getActiveLocation?.Id != null) {
                uroles = this.getUserRoles.filter(item => item.LocationId == this.getActiveLocation.Id).map(item => item.Name)
                role = maxRoleName(uroles)
            }
            // go to lower role at any unknown situations
            //if (!role) {
            //    uroles = this.getUserRoles.filter(() => true).map(item => item.Name)
            //    role = maxRoleName(uroles)
            //}
            if (role) {
                this.$store.commit(USER_ROLE_SET_ACTIVE, role)
                this.$store.commit(USER_ROLES_LIST_SET_ACTIVE, uroles)
            } else {
                this.$store.commit(USER_ROLE_CLEAR_ACTIVE)
            }
        },
        createLocation() {
            this.showModal(null, M_LOCATION_FORM);
        },
        editCompany() {
            this.showModal({ company: this.getActiveCompany }, M_COMPANY_FORM);
        },
        locationImage(location) {
            this.$session.set("location",location)
            this.showModal(
                {
                    image: {
                        url:
                            location.MediumImageUrl ||
                            "/images/zesec-placeholder.png",
                        type: IMAGE_TYPE_LOCATION,
                    },
                },
                M_IMAGE_UPLOAD
            );
        },
        updateLocationImage(response) {
            this.location = this.$session.get("location");
            this.$store
                .dispatch(LOCATION_PATCH, {
                    ...this.location,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(
                        LOCATION_GET,
                        this.location.Id
                    );
                })
                .catch((error) => {});
        },
    },
    watch: {
        $route: function (to, from) {
            if (
                to.params.companyId &&
                to.params.companyId != from.params.companyId
            ) {
                this.setCompany(to.params.companyId);
            } else if (!to.params.companyId) {
                this.$store.commit(COMPANY_CLEAR_ACTIVE);
            }
            if(!to.params.locationId){
                this.$store.commit(LOCATION_CLEAR_ACTIVE);
            }
        },
        getActiveLocation: function(from, to) {
            if (to && from != to)
                this.updateActiveUserRole()
        },
        getActiveCompany: function(from, to) {
            if (to && from != to)
                this.updateActiveUserRole()
        },
        getActiveUserRole(val) {
            if (val) {
                this.$session.set("userRole", val)
                EventBus.$emit("userRole", val);
            }
        },
    },
    components: {
        "zs-company-modal": CreateCompanyModal,
        "zs-profile-modal": EditProfileModal,
        "zs-location-modal": CreateLocationModal,
        "zs-delete-location-modal": DeleteLocationModal,
        "zs-breadcrumbs": Breadcrumbs,
        NavbarUser,
    },
};
</script>
<style scoped>
.bd-navbar {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}
</style>
