import Vue from "vue";
import Router from "vue-router";
import cookies from "vue-cookies";
import store from "./store/index";
import { SET_BREADCRUMBS } from "@/store/types/global";

Vue.use(Router);

const checkAuthorized = (to, from, next) => {
    if (cookies.get("Authorization")) {
        next();
    } else {
        next({
            name: "login"
        });
    }
};

const checkNotAuthorized = (to, from, next) => {
    if (!cookies.get("Authorization")) {
        next();
    } else {
        next({
            name: "dashboard"
        });
    }
};

const routesViews = [{
        path: "/login",
        name: "login",
        component: "Login",
        meta:{
            breadcrumbs: false
        },
        beforeEnter: checkNotAuthorized
    },
    {
        path: "/password-reset",
        name: "password-reset",
        component: "PasswordReset",
        meta:{
            breadcrumbs: false
        },
        beforeEnter: checkNotAuthorized
    },
    {
        path: "/register",
        name: "register",
        component: "Register",
        meta:{
            breadcrumbs: false
        },
        beforeEnter: checkNotAuthorized
    },
    {
        path: "/",
        component: "AllLocations",
        name: "dashboard",
        alias: "/dashboard",
        meta:{
            // breadcrumbs: []
            breadcrumbs: [
                {
                    name: 'locations.title',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/admin",
        component: "Admin",
        name: "admin",
        meta:{
            breadcrumbs: [
                {
                    name: 'admin.title',
                    active: false,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/zdashboard",
        component: "ZDashboard",
        name: "zdashboard",
        meta:{
            breadcrumbs: false
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/notifications",
        component: "Notifications",
        name: "notifications",
        meta:{
            breadcrumbs: [
                {
                    name: 'notifications.title',
                    active: true
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId",
        name: "location",
        component: "Location",
        meta:{
            breadcrumbs: []
            /* breadcrumbs: [
                {
                    name: 'locations.location',
                    active: true
                }
            ] */
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/",
        name: "private-location",
        component: "PrivateLocation",
        meta:{
            breadcrumbs: []
            /* breadcrumbs: [
                {
                    name: 'locations.location',
                    active: true
                }
            ]  */
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/doors",
        name: "alldoors",
        component: "AllDoors",
        meta:{
            breadcrumbs: [
                {
                    name: 'doors.title_all',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/doors",
        name: "doors",
        component: "Doors",
        meta:{
            breadcrumbs: [
                {
                    name: 'doors.doors',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/doors/:doorId",
        name: "door",
        component: "Door",
        meta:{
            breadcrumbs: [
                {
                    name: 'doors.doors',
                    active: false,
                    to: {name: 'doors', params: {companyId: null, locationId: null}}
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/keys",
        name: "allkeys",
        component: "AllKeys",
        meta:{
            breadcrumbs: [
                {
                    name: 'keys.title_all',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/keys",
        name: "keys",
        component: "Keys",
        meta:{
            breadcrumbs: [
                {
                    name: 'keys.keys',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/keys/:keyId",
        name: "key",
        component: "Key",
        meta:{
            breadcrumbs: [
                {
                    name: 'keys.keys',
                    active: false,
                    to: {name: 'keys', params: {companyId: null, locationId: null}}
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/pass",
        name: "pass",
        component: "Pass",
        meta:{
            breadcrumbs: [
                {
                    name: 'pass.pass',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/activity",
        name: "activity",
        component: "Activity",
        meta:{
            breadcrumbs: [
                {
                    name: 'activity.activity',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/members",
        name: "members",
        component: "Members",
        meta:{
            breadcrumbs: [
                {
                    name: 'users.users',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/staff",
        name: "company-staff",
        component: "CompanyStaff",
        meta:{
            breadcrumbs: [
                {
                    name: 'staff.title',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/staff",
        name: "location-staff",
        component: "LocationStaff",
        meta:{
            breadcrumbs: [
                {
                    name: 'staff.title',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/location/:locationId/user/:userId",
        name: "profile",
        component: "UserProfile",
        meta:{
            breadcrumbs: [
                {
                    name: 'users.profile',
                    active: true
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId/user/:userId",
        name: "profile-owner",
        component: "UserProfile",
        meta:{
            breadcrumbs: [
                {
                    name: 'users.profile',
                    active: true
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/location/:locationId/user/:userId",
        name: "profile-owner-new",
        component: "UserProfile",
        meta:{
            breadcrumbs: [
                {
                    name: 'users.profile',
                    active: true
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/invite-management",
        name: "invite-management",
        component: "InviteManagement",
        beforeEnter: checkAuthorized
    },
    {
        path: "/company/:companyId",
        component: "Company",
        name: "company",
        meta:{
            breadcrumbs: []
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "/profile-edit",
        name: "profile-edit",
        component: "ProfileEdit",
        meta:{
            breadcrumbs: [
                {
                    name: 'users.profile',
                    active: true,
                }
            ]
        },
        beforeEnter: checkAuthorized
    },
    {
        path: "*",
        name: "error",
        meta:{
            breadcrumbs: []
        },
        component: "Error"
    },
    {
        path: "/developers/:page?",
        name: "developers",
        meta:{
            breadcrumbs: []
        },
        component: "ApiDoc/ApiDoc"
    },
    // {
    //     path: "/pricing",
    //     name: "pricing",
    //     component: "Pricing"
    // },
];

const routes = routesViews.map(route => {
    return {
        ...route,
        component: () => import(`./views/${route.component}.vue`)
    };
});

const router = new Router({
    routes: routes,
    mode: "history",
    base: process.env.BASE_URL
});

router.beforeEach((to, from, next) => {
    if(to.meta.breadcrumbs){
        to.meta.breadcrumbs.map(bc => {
            if('to' in bc){
                for (const [key, value] of Object.entries(bc.to.params)) {
                    bc.to.params[key] = to.params[key]
                }
            }
        })
        store.commit(SET_BREADCRUMBS, to.meta.breadcrumbs);
    } else {
        store.commit(SET_BREADCRUMBS, []);
    }

    next()
})

export default router;
