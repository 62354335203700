import ProvidersController from "@/api/providers";

import {
    LOGIN_DANALOCK,
    REGISTER_DANALOCK,
    CHECK_DANALOCK,
    CONNECT_DANALOCK,
    DANALOCK_ERROR
} from "../types/providers";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const state = {
    translatedMessages:{

    }
};

const getters = {
    
};

const actions = {
    [CHECK_DANALOCK]: ({commit}, userId) => {
        return ProvidersController.checkDanalock(userId)
            .then(response => {
                return true;
            })
            .catch((error) => {return false});
    },
    [LOGIN_DANALOCK]: ({commit, dispatch}, credentials) => {
        return ProvidersController.loginDanalock(credentials)
            .then(response => {
                return response;
            })
            .catch((error) => {
                dispatch(DANALOCK_ERROR, error);
                return false
            });
    },
    [REGISTER_DANALOCK]: ({commit, dispatch}, credentials) => {
        return ProvidersController.registerDanalock(credentials)
            .then(response => {
                commit(SHOW_NOTIFY, {
                    text: "Danalock connected",
                    title: "Success",
                    variant: "success"
                });
                return response;
            })
            .catch((error) => {
                dispatch(DANALOCK_ERROR, JSON.parse(error.response.data.error));
                return false
            });
    },
    [CONNECT_DANALOCK]: ({commit, dispatch}, credentials) => {
        return ProvidersController.loginDanalock(credentials)
            .then(response => {
                commit(SHOW_NOTIFY, {
                    text: "Danalock connected",
                    title: "Success",
                    variant: "success"
                });
                return response;
            })
            .catch((error) => {
                return dispatch(REGISTER_DANALOCK, credentials)
            });
    },
    [DANALOCK_ERROR]: ({
        commit
    }, error) => {
        let translation = 'danalock_1';
        if(error.error == '15'){
            translation = 'danalock_2';
        } else if(error.error == 'validation_failed'){
            translation = 'danalock_3';
        }
        commit(SHOW_NOTIFY, {
            text: '',
            title: "Error",
            variant: "danger",
            translation: translation
        });
    }
};

const mutations = {
    
};

export default {
    state,
    getters,
    actions,
    mutations
};
