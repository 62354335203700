/* jshint esversion: 2015 */
// import axios from "axios";

export default {
    getCalendar(calendarId) {
        return window.axios.get("calendar/" + calendarId);
    },
    postCalendar(calendar) {
        return window.axios.post("calendar", calendar);
    }
};
