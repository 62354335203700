export default {
    getAllLocationPassports(locationId) {
        return window.axios.get("passport/location/" + locationId);
    },
    getLocationPassportUser(locationId) {
        return window.axios.get("passport/location/" + locationId + "/user");
    },
    getPassport(passportId) {
        return window.axios.get("passport/" + passportId);
    },
    deletePassport(ids) {
        return window.axios.post("passport/bulkdelete", { PassportIds: ids });
    },
    activatePassport(passportId) {
        return window.axios.post("passport/" + passportId + "/activate");
    },
    deactivatePassport(passportId) {
        return window.axios.post("passport/" + passportId + "/deactivate");
    },
    createPassport(passport) {
        return window.axios.post("passport", passport);
    },
    updatePassport(passport) {
        return window.axios.put("passport", passport);
    },
    setPassportImage(image) {
        const form = new FormData();
        form.append('Type', '4');
        form.append('Body', image);
        return window.axios.post("file/image", form);
    },
}
