<template>
    <b-navbar-nav v-if="getActiveLocation" class="navbar-nav ml-2">
        <b-nav-item
            :active="$route.name == 'location'"
            v-if="getActiveLocation.CompanyId != null"
            :to="{
              name: 'location',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.overview") }}</b-nav-item
        >
        <b-nav-item
            v-else-if="getActiveLocation.CompanyId == null"
            :active="$route.name == 'private-location'"
            :to="{
                name: 'private-location',
                params: {
                    locationId: getActiveLocation.Id,
                },
              }"
            class="text-capitalize"
        >{{ $t("menu.overview") }}</b-nav-item
        >
        <b-nav-item
            v-if="!admin && !roleFlag"
            :active="$route.name == 'members'"
            :disabled="!$can('viewUsers', getActiveLocation)"
            :to="{
              name: 'members',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.users") }}</b-nav-item
        ><b-nav-item
            v-else-if="!admin && roleFlag"
            :active="$route.name == 'members'"
            :to="{
              name: 'members',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.users")}}</b-nav-item
        >
        <b-nav-item
            v-else
            :active="$route.name == 'members'"
            :to="{
              name: 'members',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.users") }}</b-nav-item
        >
        <b-nav-item
            v-if="!admin && !roleFlag"
            :active="['doors', 'door'].includes($route.name)"
            :to="{
              name: 'doors',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.doors") }}</b-nav-item
        >
        <b-nav-item
            v-else-if="!admin && roleFlag"
            :active="['doors', 'door'].includes($route.name)"
            :to="{
              name: 'doors',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.doors") }}</b-nav-item
        >
        <b-nav-item
            v-else
            :active="['doors', 'door'].includes($route.name)"
            :to="{
              name: 'doors',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.doors") }}</b-nav-item
        >
        <b-nav-item
            v-if="!admin && !roleFlag"
            :active="['keys', 'key'].includes($route.name)"
            :disabled="!$can('viewKeys', getActiveLocation)"
            :to="{
              name: 'keys',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.keys") }}</b-nav-item
        >
        <b-nav-item
            v-else-if="!admin && roleFlag"
            :active="['keys', 'key'].includes($route.name)"
            :to="{
              name: 'keys',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.keys") }}</b-nav-item
        >
        <b-nav-item
            v-else
            :active="['keys', 'key'].includes($route.name)"
            :to="{
              name: 'keys',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.keys") }}</b-nav-item
        >
        <b-nav-item
            v-if="!admin && !roleFlag"
            :active="$route.name == 'activity'"
            :disabled="disableFlag"
            :to="{
              name: 'activity',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.activity") }}</b-nav-item
        >
        <b-nav-item
            v-else-if="!admin && roleFlag"
            :active="$route.name == 'activity'"
            :disabled="disableFlag"
            :to="{
              name: 'activity',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.activity") }}</b-nav-item
        >
        <b-nav-item
            v-else
            :active="$route.name == 'activity'"
            :disabled="!$can('viewActivity', getActiveLocation) && disableFlag"
            :to="{
              name: 'activity',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.activity") }}</b-nav-item
        >
        <b-nav-item
            :active="$route.name == 'admin'"
            v-if="admin"
            :to="{ name: 'admin' }"
            class="text-capitalize"
        >{{ $t("admin.title") }}</b-nav-item
        >
        <b-nav-item
            :active="$route.name === 'pass'"
            :to="{
              name: 'pass',
              params: {
                locationId: getActiveLocation.Id,
                companyId: getActiveLocation.CompanyId,
              },
            }"
            class="text-capitalize"
        >{{ $t("menu.pass") }}</b-nav-item>
        <small class="text-muted mr-2" v-if="showUserRoles">
            <br/>{{ getActiveUserRole || ""}}
            <br/>{{ getActiveUserRoles }}
        </small>
    </b-navbar-nav>
    <b-navbar-nav v-else class="mr-lg-auto ml-2">
        <b-nav-item
            :active="$route.name == 'dashboard'"
            :to="{
              name: 'dashboard',
            }"
            class="text-capitalize"
        >{{ $t("menu.dashboard") }}</b-nav-item
        >

        <b-nav-item
            :active="$route.name == 'alldoors'"
            :to="{ name: 'alldoors', }"
            class="text-capitalize"
        >{{ $t("menu.all_doors") }}</b-nav-item
        >
        <b-nav-item
            :active="$route.name == 'allkeys'"
            :to="{ name: 'allkeys', }"
            class="text-capitalize"
        >{{ $t("menu.all_keys") }}</b-nav-item
        >
        <b-nav-item
            :active="$route.name == 'company-staff'"
            :to="{
              name: 'company-staff',
              params: {
                companyId: getActiveCompany.Id,
              },
            }"
            v-if="getActiveCompany"
            class="text-capitalize"
        >{{ $t("menu.staff") }}</b-nav-item
        >
         <b-nav-item
            :active="$route.name == 'admin'"
            v-if="admin"
            :to="{ name: 'admin' }"
            class="text-capitalize"
        >{{ $t("admin.title") }}</b-nav-item
        >
    </b-navbar-nav>
</template>
<script>
import { mapGetters } from "vuex";
import {
    CHECK_ADMIN_USER
  } from "@/store/types/admin";
import { USER_GET, USER_GET_ROLES } from "@/store/types/user";
import { definedRoles, maxRoleName } from "@/acl/roles";
import { COMPANIES_GET } from "@/store/types/companies";
import { sleep } from "@/store/modules/global";

export default {
    data: function () {
    return {
      admin: false,
      roleFlag: false,
      disableFlag: true,
    }
    },
    computed: {
        ...mapGetters([
            "getActiveLocation",
            "getUserCompanies",
            "getActiveCompany",
            "getSuperAdmin",
            "getUserRoles",
            "getInitProcessing",
            "getActiveUserRole",
            "getActiveUserRoles",
        ]),
        getCompanyToManage() {
            return this.getUserCompanies.find((item) => this.$can("viewStaff", item));
        },
        showUserRoles() {
            return location.hostname === "127.0.0.1" || location.hostname === "web-dev.zesec.com"
        },
    },
    async created(){
        // wait for init completed
        for (let i = 0; i < 30; i++) {
            await sleep(100)
            if (this.getInitProcessing === false) {
                break
            }
        }

      this.admin = this.getAdminUser();
      //  console.log("a-userRole:" + this.getActiveUserRole)
    },
    watch: {
        getActiveUserRole(val) {
            //console.log(`getActiveUserRole: ${val}`)

            if( val == "CompanyOwner" || val == "CompanyManager" || val == "LocationManager" || val == "KeyManager"){
                this.roleFlag = true
            } else{
                this.roleFlag = false
            }
            if(this.roleFlag || val == 'KeyManager' || val == 'Installer'){
                this.disableFlag = false
            }else{
                this.disableFlag = true
            }
        },
    },
    methods:{
      getAdminUser(){
          this.$store.dispatch(CHECK_ADMIN_USER).then((response) => {
            if (response.data.status == true) {
              this.admin = response.data.status;
              this.$session.set("adminUser", true);
            }else{
              this.admin = false;
            }
          });
          return this.admin;
        }
    }
};
</script>
