/* jshint esversion: 2015 */
// import axios from "axios";

export default {
    postLocation(location) {
        return window.axios.post(
            `b2b/company/${location.CompanyId}/location`,
            location
        );
    },

    getCompanyLocations(companyId) {
        return window.axios.get(`b2b/company/${companyId}/location`);
    },

    getAllLocations() {
        return window.axios.get("user/location");
    },

    getAllCompanyLocations() {
        return window.axios.get("user/allLocation");
    },

    getLocation(locationId) {
        return window.axios.get(`location/${locationId}`);
    },

    patchLocation(locationId, patch) {
        return window.axios.put(`location/${locationId}`, patch);
    },

    deleteLocation(locationId) {
        return window.axios.delete(`location/${locationId}`);
    },

    getLocationUsers(locationId) {
        return window.axios.get(`location/${locationId}/user`);
    },

    getLocationMembers(locationId) {
        return window.axios.get(`location/${locationId}/member`);
    },

    getLocationCompanyUsers(locationId) {
        return window.axios.get(`location/${locationId}/company/users`);
    },

    getLocationInvites(locationId) {
        return window.axios.get(`location/${locationId}/invite`);
    },

    deleteLocationUser(payload) {
        if (payload.silent === true)
            return window.axios.delete(`location/${payload.locationId}/user/${payload.userId}?silent=true`);
        else
            return window.axios.delete(`location/${payload.locationId}/user/${payload.userId}`);
    },
};
