export const DEVICE_SUCCESS = "DEVICE_SUCCESS";
export const DEVICE_ERROR = "DEVICE_ERROR";
export const DEVICE_REQUEST = "DEVICE_REQUEST";

export const DEVICE_CODE_ERROR = "DEVICE_CODE_ERROR";
export const DEVICE_CODE_SUCCESS = "DEVICE_CODE_SUCCESS";
export const DEVICE_CODE_REQUEST = "DEVICE_CODE_REQUEST";

export const DEVICES_GET = "DEVICES_GET";
export const DEVICES_FILL = "DEVICES_FILL";
export const DEVICES_CLEAR = "DEVICES_CLEAR";
export const MX_DEVICE_INFO_SET = "MX_DEVICE_INFO_SET";
export const MX_DEVICE_INFO_CLEAR = "MX_DEVICE_INFO_CLEAR";
export const SALTO_DEVICE_INFO_SET = "SALTO_DEVICE_INFO_SET";
export const SALTO_DEVICE_INFO_CLEAR = "SALTO_DEVICE_INFO_CLEAR";

export const DEVICE_SET_ACTIVE = "DEVICE_SET_ACTIVE";
export const DEVICE_GET = "DEVICE_GET";
export const DEVICE_CREATE = "DEVICE_CREATE";
export const DEVICE_PATCH = "DEVICE_PATCH";
export const DEVICE_DELETE = "DEVICE_DELETE";
export const MX_DEVICE_INFO = "MX_DEVICE_INFO";
export const SALTO_DEVICE_INFO = "SALTO_DEVICE_INFO";

export const DEVICE_CODE_PATCH = "DEVICE_CODE_PATCH";
export const DEVICE_CODE_GET = "DEVICE_CODE_GET";

export const DEVICE_KEYS_GET = "DEVICE_KEYS_GET";
export const DEVICE_KEYS_FILL = "DEVICE_KEYS_FILL";

export const DEVICE_USERS_GET = "DEVICE_USERS_GET";
export const DEVICE_USERS_FILL = "DEVICE_USERS_FILL";
export const DEVICE_USERS_CLEAR = "DEVICE_USERS_CLEAR";

export const DEVICE_PERMANENT_USERS_GET = "DEVICE_PERMANENT_USERS_GET";
export const DEVICE_CONFIGURED = "DEVICE_CONFIGURED";
