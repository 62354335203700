<template>
    <div class="navbar-user">
        <div class="dropdown w4r d-none d-md-flex">
            <a
                href="#"
                class="navbar-user-link"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <span
                    class="icon"
                    v-bind:class="{ active: getUserNotificationsCount > 0 }"
                >
                    <i class="fe fe-bell"></i>
                </span>
            </a>
            <navbar-notifications :notifications="notificationList" />
        </div>
        <div class="dropdown w4r">
            <a
                href="#"
                class="avatar avatar-sm avatar-online dropdown-toggle float-right"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <img
                    :src="user.SmallImageUrl || '/images/person-big_MyZesec_140dp.png'"
                    class="avatar-img rounded-circle"
                />
            </a>
<!--  dd-h-600 -->
            <div class="dropdown-menu dropdown-menu-right">
              <!--  v-for="company in companies"  -->
              
                    <form>
                        <b-input-group>
                            <template #prepend>
                                <b-input-group-text style="border: 0px !important;"><span class="fe fe-search"></span
                                ></b-input-group-text>
                            </template>

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filter"
                                type="search"
                                style="border: 0px !important;"
                            ></b-form-input>
                        </b-input-group>
                    </form>
                <hr class="dropdown-divider" style="margin-top: 1px !important;"/>
                <div class="dd-h-200">
                <b-link
                    class="dropdown-item"
                    v-for="company in userCompanies"
                    v-bind:key="company.Id"
                    :disabled="activeCompany && activeCompany.Id == company.Id"
                    :active="activeCompany && activeCompany.Id == company.Id"
                    :to="{
                        name: 'company',
                        params: {
                            companyId: company.Id,
                        },
                    }"               
                >
                   {{ companyName(company.Name) }} 
                </b-link>  
                </div>             
                <hr class="dropdown-divider" />
                <b-link class="dropdown-item" :to="{ name: 'profile-edit' }">
                    {{ $t("users.profile") }}
                </b-link>

                <hr class="dropdown-divider" />

                <language-switcher class="p-0 w-100" />
                <hr class="dropdown-divider" />
                <b-link class="dropdown-item" @click="logout">{{
                    $t("auth.logout")
                }}</b-link>
                </div>            
        </div>
    </div>
</template>
<script>
import { INIT_STARTED } from "@/store/types/global";
import { AUTH_LOGOUT } from "@/store/types/auth";
import { GET_NOTIFICATIONS } from "@/store/types/notifications";

import { mapGetters } from "vuex";

import LanguageSwitcher from "@/components/common/LanguageSwitcher";
import NavbarNotifications from "@/components/common/Nav/Header/NavbarNotifications";

export default {
    data() {
        return {
            filter: "",            
        };
    },
    props: ["user", "activeCompany", "companies"],
    computed: {
        ...mapGetters([
            "getUserNotificationsCount",
            "getUserUnreadedNotifications",
            "getUserCompanies",
        ]),
        notificationList() {
            return [...this.getUserUnreadedNotifications].sort(
                (i1, i2) => new Date(i2.CreatedAt) - new Date(i1.CreatedAt)
            );
        }, 
        userCompanies() {            
            return this.getUserCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            });
        },        
    },    
    created() {
        this.$store.dispatch(GET_NOTIFICATIONS);        
    },
    methods: { 
        companyName(name){            
            if(name.length > 20){
                name = name.slice(0, 16).concat('...');
            }
            return name; 
        },
        logout: function () {
            this.$store.commit(INIT_STARTED);
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$session.destroy();
                this.$router
                    .push({
                        name: "login",
                    })
                    .catch(() => {});
            });
        },
    },
    components: {
        LanguageSwitcher,
        NavbarNotifications,
    },
};
</script>
<style >
.navbar-expand-md .dropdown:hover > .dropdown-menu {
    display: block;
}
.dd-h-600 {
    max-height: 600px;
    overflow-y: auto;
}
.dd-h-200 {
    max-height: 354px;
    overflow-y: auto;
}
.w4r {
    width: 4rem;
}
</style>