export const MEMBER_SUCCESS = "MEMBER_SUCCESS";
export const MEMBER_ERROR = "MEMBER_ERROR";
export const MEMBER_REQUEST = "MEMBER_REQUEST";

export const DEVICE_MEMBERS_GET = "DEVICE_MEMBERS_GET";
export const LOCATION_MEMBERS_GET = "LOCATION_MEMBERS_GET";
export const LOCATION_COMPANY_USERS_GET = "LOCATION_COMPANY_USERS_GET";
export const LOCATION_MEMBERS_BY_ROLES_GET = "LOCATION_MEMBERS_BY_ROLES_GET";
export const LOCATION_INVITES_GET = "LOCATION_INVITES_GET";
export const DEVICE_MEMBERS_FILL = "DEVICE_MEMBERS_FILL";
export const DEVICE_MEMBERS_CLEAR = "DEVICE_MEMBERS_CLEAR";
export const LOCATION_MEMBERS_FILL = "LOCATION_MEMBERS_FILL";
export const LOCATION_MEMBERS_SORTED_FILL = "LOCATION_MEMBERS_SORTED_FILL";
export const LOCATION_MEMBERS_CLEAR = "LOCATION_MEMBERS_CLEAR";
export const LOCATION_COMPANY_USERS_FILL = "LOCATION_COMPANY_USERS_FILL";
export const LOCATION_COMPANY_USERS_CLEAR = "LOCATION_COMPANY_USERS_CLEAR";
export const LOCATION_INVITES_FILL = "LOCATION_INVITES_FILL";
export const LOCATION_INVITES_CLEAR = "LOCATION_INVITES_CLEAR";

export const MEMBER_GET = "MEMBER_GET";
export const MEMBER_CREATE = "MEMBER_CREATE";
export const MEMBER_PATCH = "MEMBER_PATCH";
export const MEMBER_DELETE = "MEMBER_DELETE";
export const MEMBER_GET_ADMIN = "MEMBER_GET_ADMIN";

export const MEMBER_KEYS_GET = "MEMBER_KEYS_GET";
export const MEMBER_KEYS_FILL = "MEMBER_KEYS_FILL";
export const MEMBER_KEYS_CLEAR = "MEMBER_KEYS_CLEAR";
export const MEMBER_KEYS_GET_ADMIN = "MEMBER_KEYS_GET_ADMIN";

export const MEMBER_DEVICES_GET = "MEMBER_DEVICES_GET";
export const MEMBER_DEVICES_FILL = "MEMBER_DEVICES_FILL";
export const MEMBER_DEVICES_CLEAR = "MEMBER_DEVICES_CLEAR";
export const MEMBER_DEVICES_GET_ADMIN = "MEMBER_DEVICES_GET_ADMIN";

export const MEMBER_TO_KEY_INVITE = "MEMBER_TO_KEY_INVITE";
export const MEMBER_TO_KEY_REINVITE = "MEMBER_TO_KEY_REINVITE";
export const MEMBER_LIST_TO_KEY_INVITE = "MEMBER_LIST_TO_KEY_INVITE";
export const MEMBER_CANCEL_INVITE = "MEMBER_CANCEL_INVITE";

export const MEMBER_ROLES_GET = "MEMBER_ROLES_GET";
export const MEMBER_ROLES_FILL = "MEMBER_ROLES_FILL";

export const MEMBER_PERMANENT_KEYS_GET = "MEMBER_PERMANENT_KEYS_GET";
