<template>
    <nav
        class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
        id="sidebar"
    >
    <!-- style="position: fixed !important; top: 0; width: 100%;"  -->
        <div class="container-fluid">
            <!-- Toggler -->
            <b-button
                v-b-toggle.sidebarCollapse
                class="navbar-toggler"
                variant="dc"
                ><span class="navbar-toggler-icon"></span
            ></b-button>
            <!-- Brand -->

            <!-- <sidebar-user
                :user="getUser"
                :companies="getUserCompanies"
                :activeCompany="getActiveCompany"
            /> -->
            <sidebar-user
                :user="getUser"
                :companies="userCompanies"
                :activeCompany="getActiveCompany"
            />
            <!-- Collapse -->
            <b-collapse class="collapse navbar-collapse" id="sidebarCollapse">
                <!-- Navigation -->
                <router-link
                    class="navbar-brand bot-divider d-none d-md-block"
                    :to="{
                        name: 'company',
                        params: {
                            companyId: getActiveCompany.Id,
                        },
                    }"
                    v-if="getActiveCompany"
                >
                    <div
                        class="
                            row
                            d-flex
                            align-items-center
                            justify-content-between
                        "
                    >
                        <div class="col-auto mx-auto">
                            <div class="avatar avatar-xxl header-avatar-top">
                                <img
                                    :src="
                                        getActiveCompany.SmallImageUrl ||
                                        '/images/zesec-placeholder.png'
                                    "
                                    :alt="getActiveCompany.Name"
                                    class="avatar-img rounded border-body"
                                />
                            </div>
                        </div>
                    </div>
                </router-link>
                <div v-else class="navbar-brand bot-divider d-none d-md-block">
                    <div
                        class="
                            row
                            d-flex
                            align-items-center
                            justify-content-between
                        "
                    >
                        <div class="col-auto mx-auto">
                            <div class="avatar avatar-xxl header-avatar-top">
                                <img
                                    src="/images/empty-placeholder.png"
                                    class="avatar-img border-body"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <template
                    v-if="
                        locationContext && getActiveLocation && getActiveCompany
                    "
                >
                    <h6 class="navbar-heading">
                        {{ $t("locations.location") }}
                    </h6>
                    <Select2
                        style="left: 0px !important"
                        :options="companyLocations"
                        :disabled="
                            getActiveCompany &&
                            !$can('locations-list', getActiveCompany)
                        "
                        :value="getActiveLocation.Id"
                        :settings="{
                            placeholder: $t('menu.select_location'),
                            // minimumResultsForSearch: -1,
                            templateSelection: locationHtml,
                            containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                            dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                        }"
                        @change="initLocation"
                    />
                    <hr class="navbar-divider my-4" v-if="getActiveLocation" />
                </template>
                <template v-if="!locationContext">
                    <h6 class="navbar-heading">
                        {{ $t("companies.company") }}
                    </h6>
                    <!-- company-select -->
                    <Select2
                        :options="userCompanies"
                        :value="getActiveCompany ? getActiveCompany.Id : null"
                        :settings="{
                            placeholder: $t('menu.all'),
                            // minimumResultsForSearch: -1,
                            templateSelection: companyHtml,
                            containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                            dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                        }"
                        @change="initCompany"
                    />

                    <hr class="navbar-divider my-3" />
                </template>

                <main-nav />
                <!-- Divider -->
                <hr class="navbar-divider my-3" />

                <b-button
                    variant="primary"
                    v-if="
                        getActiveLocation && $can('shareKey', getActiveLocation)
                    "
                    size="sm"
                    @click="inviteMember"
                    >{{ $t("keys.send_invite") }}</b-button
                >
                <b-button
                    variant="primary"
                    v-if="
                        getActiveLocation && roleFlag && !$can('shareKey', getActiveLocation)
                    "
                    size="sm"
                    @click="inviteMember"
                    >{{ $t("keys.send_invite") }}</b-button
                >
                <!-- Push content down -->
                <div class="mt-auto">
                    <router-link
                        class="navbar-brand"
                        :to="{ name: 'dashboard' }"
                    >
                        <img
                            src="/logo-p.png"
                            class="navbar-brand-img mx-auto py-1"
                            alt="Zesec"
                        />
                    </router-link>
                </div>

                <!-- User (md) -->
            </b-collapse>
            <zs-sharekey-modal
                v-if="getActiveLocation && $can('shareKey', getActiveLocation)"
                :locationId="getActiveLocation.Id"
            />
            <zs-sharekey-modal
                v-if="getActiveLocation && roleFlag && !$can('shareKey', getActiveLocation)"
                :locationId="getActiveLocation.Id"
            />
            <zs-resharekey-modal />
        </div>
    </nav>
</template>

<script>
import { mapGetters } from "vuex";
import modalMixin from "@/mixins/modalMixin";

import MainNav from "@/components/common/Nav/Sidebar/MainNav";
import SidebarUser from "@/components/common/Nav/Sidebar/SidebarUser";
import Select2 from "@/components/common/Select2";
import ShareKeyModal from "@/components/modals/ShareKeyAccordion";
import ReshareKeyModal from "@/components/modals/ReshareKeyAccordion";

import { M_MEMBER_INVITE_FORM } from "@/components/modals/types";
import { M_MEMBER_REINVITE_FORM } from "@/components/modals/types";

export default {
    mixins: [modalMixin],
    props: ["user", "activeCompany", "companies"],
    data() {
        return {
            filter: "",
            roleFlag: true,
            userRole: "",
        };
    },
    computed: {
        ...mapGetters([
            "getActiveCompany",
            "getActiveLocation",
            "getCompanyLocations",
            "getUser",
            "getUserCompanies",
        ]),
        companyLocations() {
            return this.getCompanyLocations.map((item) => {
                if(item.Name.length > 20){
                    item.Name = item.Name.slice(0, 16).concat('...');
                }
                return { text: item.Name, id: item.Id };
            });
        },
        userCompanies() {
            return this.getUserCompanies
                .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
                let name = item.Name
                if(item.Name.length > 20){
                    name = item.Name.slice(0, 18).concat('...');
                }else{
                    name = item.Name;
                }
                return {
                        id: item.Id,
                        text: name,
                }
            });
        },
        locationContext() {
            return !!this.$route.params.locationId && this.getActiveLocation;
        },
    },
    created(){
        this.userRole = this.$session.get("userRole")
        if( this.userRole == "CompanyOwner" || this.userRole == "CompanyManager" || this.userRole == "LocationManager" || this.userRole == "KeyManager"){
            this.roleFlag = true
        }
    },
    methods: {
        initLocation(locationId) {
            let companyId = this.getCompanyLocations.find(
                (item) => item.Id == locationId
            ).CompanyId;
            this.$router.push({
                name: "location",
                params: {
                    locationId: locationId,
                    companyId: companyId,
                },
            });
        },
        initCompany(companyId) {
            if (!companyId) {
                this.$router.push({
                    name: "dashboard",
                });
            }
            this.$router.push({
                name: "company",
                params: {
                    companyId: companyId,
                },
            });
        },
        inviteMember() {
            this.showModal(null, M_MEMBER_INVITE_FORM);
        },
        locationHtml(state) {
            const wrapper = document.createElement("div");
            let originalLocText = state.text
            /* if(originalLocText.length > 25){
                originalLocText = originalLocText.slice(0, 16).concat('...');
            }else{
                originalLocText = state.text
            }  */
            // state.text
            wrapper.innerHTML =
                '<span>'+
                '<table><tbody><tr><td style="vertical-align: top;">'+
                '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16"><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg></td>'+
                '<td class="ml-1"><span>' +originalLocText +'</span></td></tr></tbody></table></span>';
            return wrapper.firstChild;
        },
        companyHtml(state) {
            const wrapper = document.createElement("div");
            let originalText = state.text
            if(originalText.length > 25){
                originalText = originalText.slice(0, 16).concat('...');
            }else{
                originalText = state.text
            }
            const icon = state.id
                ? `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                </svg>`
                : `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                </svg>`;
            wrapper.innerHTML = `<span>${icon}<span class="ml-1">${originalText}</span></span>`;
            return wrapper.firstChild;
        },
    },
    components: {
        MainNav,
        SidebarUser,
        Select2,
        "zs-sharekey-modal": ShareKeyModal,
        "zs-resharekey-modal": ReshareKeyModal,
    },
};

</script>
<style scoped>
.bot-divider {
    border-color: #e3ebf6;
    border-bottom: 1px solid #e3ebf6;
}
div.bot-divider {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.select2-dropdown .dropdown-menu{
    left: 0px !important;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
</style>
