import NotificationsController from "@/api/notifications";

import {
    GET_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    FILL_NOTIFICATIONS,
    CLEAR_NOTIFICATIONS
} from "../types/notifications";

const state = {
    userNotifications: []
};

const getters = {
    getUserNotifications: state => state.userNotifications,
    getUserUnreadedNotifications: state => state.userNotifications.filter(item => !item.IsRead),
    getUserNotificationsCount: state => state.userNotifications.filter(item => !item.IsRead).length,
};

const actions = {
    [GET_NOTIFICATIONS]: ({
        commit
    }) => {
        return NotificationsController.getNotifications()
            .then(response => {
                commit(FILL_NOTIFICATIONS, response.data);
            })
            .catch(() => {});
    },
    [READ_NOTIFICATIONS]: ({
        commit
    }) => {
        return NotificationsController.readNotifications()
            .then(() => {
                commit(CLEAR_NOTIFICATIONS);
            })
            .catch(() => {});
    },
};

const mutations = {
    [FILL_NOTIFICATIONS]: (state, userNotifications) => {
        let filtered = userNotifications.filter(item => !item.Text.toLowerCase().includes('locked '))
        state.userNotifications = filtered;
    },
    [CLEAR_NOTIFICATIONS]: (state) => {
        state.userNotifications = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
