<template>
    <select
        class="custom-select custom-select-sm form-control-flush"
        :id="id"
        :name="name"
        :disabled="disabled"
        :required="required"
        :showNoResults="showNoResults"
    ></select>
</template>

<script>
import $ from "jquery";
import "select2/dist/js/select2.full";

export default {
    name: "Select2",
    data() {
        return {
            select2: null,
        };
    },
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        id: {
            type: String,
            default: "",
        },
        cssClass: {
            type: String,
            default: "form-control",
        },
        name: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        options: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        settings: {
            type: Object,
            default: () => {},
        },
        value: null,   
        showNoResults:{
            type: Boolean,
            default: true,
        },  
    },
    watch: {
        options(val) {
            this.setOption(val);
        },
        value(val) {
            this.setValue(val);
        },
    },
    methods: {
        setOption(val = []) {
            let customText = this.$t("buttons.no_result_found");
            this.select2.empty();
            this.select2.select2({
                placeholder: this.placeholder,
                ...this.settings,
                data: val,
                language: {
                    noResults: function () {
                        return customText;
                    }
                }
            });
            this.setValue(this.value);
        },
        setValue(val) {
            if (val instanceof Array) {
                this.select2.val([...val]);
            } else {
                this.select2.val([val]);
            }
            this.select2.trigger("change");
        },
    },
    mounted() {
        let customText = this.$t("buttons.no_result_found");
        this.select2 = $(this.$el)
            .select2({
                placeholder: this.placeholder,
                ...this.settings,
                data: this.options,
                language: {
                    noResults: function () {
                        return customText;
                    }
                }
            })
            .on("select2:select", (ev) => {
                this.$emit("change", this.select2.val());
                this.$emit("select", ev["params"]["data"]);
            })
            .on("select2:unselect", (ev) => {
                this.$emit("change", this.select2.val());
                this.$emit("unselect", ev["params"]["data"]);
            });        
        this.setValue(this.value);
    },
    beforeDestroy() {
        this.select2.select2("destroy");
    },
};
</script>
<style>
.select2-results__option[aria-selected="true"] {
    background-color: #e3ebf6;
    width: auto;
    overflow: hidden;
}
</style>