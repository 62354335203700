export default {
    postCompanyRoleInvite(invite) {
        if (invite.changeRole === true)
            return window.axios.post(
                `/b2b/company/${invite.CompanyId}/invite?changerole=true`,
                invite
            );
        else
            return window.axios.post(
                `/b2b/company/${invite.CompanyId}/invite`,
                invite
            );
    },

    postLocationRoleInvite(invite) {
        if (invite.changeRole === true)
            return window.axios.post(
                `/location/${invite.LocationId}/invite?changerole=true`,
                invite
            );
        else
            return window.axios.post(
                `/location/${invite.LocationId}/invite`,
                invite
            );
    },

    createCompanyManagerRole(companyId) {
        return window.axios.post(`/role/company-manager`, {
            CompanyId: companyId
        });
    },

    createLocationManagerRole(locationId) {
        return window.axios.post(`/role/location-manager`, {
            LocationId: locationId
        });
    },

    createDeviceInstallerRole(keyId) {
        return window.axios.post(`/role/installer`, {
            KeyId: keyId
        });
    },

    createPermanentKeyRole(keyId) {
        return window.axios.post(`role/permanent`, {
            KeyId: keyId
        });
    },

    createTemporaryKeyRole(keyId) {
        return window.axios.post(`role/temporary`, {
            KeyId: keyId
        });
    },

    getUserRoles() {
        return window.axios.get(`role`);
    },

    deleteInvite(inviteId) {
        return window.axios.delete(`invite/${inviteId}`);
    }
};
