import en from './en.json'
import sv from './sv.json'

export const defaultLocale = 'en'

export const languages = {
    en: en,
    sv: sv,
}

export const langSettings = {
    en: {
        iconClass: 'gb',
        label: 'English',
        local: "language.en",
        locale: 'en'
    },
    sv: {
        iconClass: 'se',
        label: 'Sverige',
        local: "language.sv",
        locale: 'sv'
    }
}
