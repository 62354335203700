/* jshint esversion: 2015 */
import axios from "axios";

export default {
    getDevices(locationId) {
        return window.axios.get("location/" + locationId + "/device");
    },

    getDeviceKeys(deviceId) {
        return window.axios.get("device/" + deviceId + "/key");
    },

    getDevice(deviceId) {
        return window.axios.get("device/" + deviceId);
    },

    postDevice(device) {
        return window.axios.post("location/" + device.LocationId + "/device", device);
    },

    patchDevice(patch) {
        return window.axios.put("device/" + patch.Id, patch);
    },

    patchDeviceCode(deviceId, doorcode) {
        return window.axios.put("device/" + deviceId + "/doorcode/" + doorcode);
    },

    getDeviceCode(deviceId) {
        return window.axios.get(`device/${deviceId}/doorcode`);
    },

    deleteDevice(deviceId) {
        return window.axios.delete("device/" + deviceId);
    },

    getDeviceInvites(deviceId) {
        return window.axios.get(`device/${deviceId}/invite`);
    },

    getDevicePermanentUsers(deviceId) {
        // return window.axios.get(`device/${deviceId}/users-PermanentKeys`);
    },

    getDeviceUsers(deviceId) {
        // return window.axios.get(`device/${deviceId}/users-keys`);
        return window.axios.get(`device/${deviceId}/users-PermanentKeys`);
    },

    getConfiguredDevices(saltoInstallationId){
        return window.axios.get("SaltoInstallation/" + saltoInstallationId + "/devices");
    },

    getMXDeviceInfo(deviceId) {
        return window.axios.get("provider/modulex/device/" + deviceId + "/info");
    },

    getSaltoDeviceInfo(deviceId) {
        return window.axios.get("salto/device/" + deviceId + "/info");
    },
};
