<template>
    <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
            <!-- Toggle -->
            <a
                href="#"
                class="avatar avatar-sm avatar-online dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <img
                    :src="user.SmallImageUrl || '/images/person-big_MyZesec_140dp.png'"
                    class="avatar-img rounded-circle"
                />
            </a>

            <!-- Menu -->
            <div class="dropdown-menu dropdown-menu-right">
                 <form>
                        <b-input-group>
                            <template #prepend>
                                <b-input-group-text style="border: 0px !important;"><span class="fe fe-search"></span
                                ></b-input-group-text>
                            </template>

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filter"
                                type="search"
                                style="border: 0px !important;"
                            ></b-form-input>
                        </b-input-group>
                    </form>
                <hr class="dropdown-divider" style="margin-top: 1px !important;"/>
                <b-link
                    class="dropdown-item"
                    v-for="company in userCompanies"
                    v-bind:key="company.Id"
                    :disabled="activeCompany && activeCompany.Id == company.Id"
                    :active="activeCompany && activeCompany.Id == company.Id"
                    :to="{
                        name: 'company',
                        params: {
                            companyId: company.Id,
                        },
                    }"
                > 
                    {{ companyName(company.Name) }}
                </b-link>
                <hr class="dropdown-divider" />
                <b-link class="dropdown-item" @click="editProfile">
                    {{ $t("users.profile") }}
                </b-link>
                <b-link
                    :to="{ name: 'invite-management' }"
                    class="dropdown-item"
                >
                    {{ $t("permissions.title") }}
                </b-link>
                <hr class="dropdown-divider" />

                <language-switcher class="p-0 w-100" />
                <hr class="dropdown-divider" />
                <b-link class="dropdown-item" @click="logout">{{
                    $t("auth.logout")
                }}</b-link>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { INIT_STARTED } from "@/store/types/global";
import { AUTH_LOGOUT } from "@/store/types/auth";
import { M_PROFILE_FORM } from "@/components/modals/types";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";

export default {
    data() {
        return {
            filter: "",            
        };
    },
    props: ["user", "activeCompany", "companies"],
     computed: {
        ...mapGetters([
           "getUserCompanies",
        ]),        
        userCompanies() {            
            return this.getUserCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            });
        },        
    }, 
    methods: {
        editProfile() {
            this.$bvModal.show(M_PROFILE_FORM);
        },
        companyName(name){
            if(name.length > 20){
                name = name.slice(0, 16).concat('...');
            }     
            return name 
        },
        logout: function () {
            this.$store.commit(INIT_STARTED);
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$session.destroy();
                this.$router
                    .push({
                        name: "login",
                    })
                    .catch(() => {});
            });
        },
    },
    components: {
        "language-switcher": LanguageSwitcher,
    },
};
 
</script>
