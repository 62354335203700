import MembersController from "@/api/member";
import LocationsController from "@/api/location";

import {
    MEMBER_REQUEST,
    MEMBER_ERROR,
    MEMBER_SUCCESS,
    DEVICE_MEMBERS_GET,
    LOCATION_MEMBERS_GET,
    LOCATION_INVITES_GET,
    DEVICE_MEMBERS_FILL,
    DEVICE_MEMBERS_CLEAR,
    LOCATION_MEMBERS_FILL,
    LOCATION_INVITES_FILL,
    LOCATION_MEMBERS_CLEAR,
    LOCATION_INVITES_CLEAR,
    MEMBER_TO_KEY_INVITE,
    MEMBER_TO_KEY_REINVITE,
    MEMBER_LIST_TO_KEY_INVITE,
    MEMBER_CANCEL_INVITE,
    MEMBER_KEYS_GET,
    MEMBER_KEYS_GET_ADMIN,
    MEMBER_KEYS_FILL,
    MEMBER_KEYS_CLEAR,
    MEMBER_DEVICES_GET,
    MEMBER_DEVICES_GET_ADMIN,
    MEMBER_DEVICES_FILL,
    MEMBER_DEVICES_CLEAR,
    MEMBER_GET,
    MEMBER_GET_ADMIN,
    MEMBER_ROLES_GET,
    MEMBER_ROLES_FILL,
    MEMBER_DELETE,
    MEMBER_PATCH,
    MEMBER_CREATE,
    MEMBER_PERMANENT_KEYS_GET,
    LOCATION_MEMBERS_SORTED_FILL,
    LOCATION_COMPANY_USERS_GET,
    LOCATION_COMPANY_USERS_FILL,
    LOCATION_COMPANY_USERS_CLEAR
} from "../types/members";

import {
    STATUS_MEMBER_REQUEST,
    STATUS_MEMBER_SUCCESS,
    STATUS_MEMBER_ERROR,
} from "../types/statuses";

import { SHOW_NOTIFY } from "@/store/types/global";
import {
    definedKeyRolesIdsArray, maxRoleName,
    ROLE_COMPANY_MANAGER,
    ROLE_COMPANY_OWNER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_MANAGER,
    ROLE_LOCATION_MANAGER
} from "@/acl/roles";

import { i18n } from '@/main.js'

const getDefaultState = () => {
    return {
        member: null,
        memberKeys: [],
        memberRoles: [],
        memberDevices: [],
        locationMembers: [],
        locationMembersSorted: [],
        locationAllMembers: [],
        locationCompanyUsers: [],
        locationInvites: [],
        deviceMembers: [],
        status: "",
        requestErrors: "",
    };
};

const state = getDefaultState();

const getters = {
    getLocationMember: (state) => state.member,
    getLocationMemberRoles: (state) => state.memberRoles,
    getLocationMemberKeys: (state) => state.memberKeys,
    getLocationMemberKeysAdmin: (state) => state.memberKeys,
    getLocationMemberDevices: (state) => state.memberDevices,
    getMemberLocationDevicesAdmin: (state) => state.memberDevices,
    getLocationMembers: (state) => state.locationMembers,
    getLocationMembersSorted: (state) => state.locationMembersSorted,
    getLocationCompanyUsers: (state) => state.locationCompanyUsers,
    getLocationInvites: (state) => state.locationInvites,
    getDeviceInvites: (state) => state.deviceMembers,
    getMemberRequestErrors: (state) => state.requestErrors,
    isMemberRequestOn: (state) => state.status == STATUS_MEMBER_REQUEST,
};

const actions = {
    [MEMBER_GET]: ({ commit }, { locationId, userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getLocationMember(locationId, userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_GET, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },
    [MEMBER_GET_ADMIN]: ({ commit }, { userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getLocationMemberAdmin(userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_GET_ADMIN, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },
    [MEMBER_KEYS_GET]: ({ commit }, { locationId, userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getMemberLocationKeys(locationId, userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_KEYS_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [MEMBER_KEYS_GET_ADMIN]: ({ commit }, { userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getMemberLocationKeysAdmin(userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_KEYS_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [MEMBER_DEVICES_GET]: ({ commit }, { locationId, userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getMemberLocationDevices(locationId, userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_DEVICES_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [MEMBER_DEVICES_GET_ADMIN]: ({ commit }, { userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getMemberLocationDevicesAdmin(userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_DEVICES_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [MEMBER_ROLES_GET]: ({ commit }, { companyId, userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getMemberRoles(companyId, userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_ROLES_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [LOCATION_MEMBERS_GET]: ({ commit }, locationId) => {
        commit(MEMBER_REQUEST);
        return LocationsController.getLocationMembers(locationId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(LOCATION_MEMBERS_FILL, response.data);
                commit(LOCATION_MEMBERS_SORTED_FILL, response.data);
                return response.data;
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [LOCATION_COMPANY_USERS_GET]: ({ commit }, locationId) => {
        commit(MEMBER_REQUEST);
        commit(LOCATION_COMPANY_USERS_CLEAR);
        return LocationsController.getLocationCompanyUsers(locationId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(LOCATION_COMPANY_USERS_FILL, response.data);
                return response.data;
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [LOCATION_INVITES_GET]: ({ commit }, locationId) => {
        commit(MEMBER_REQUEST);

        return LocationsController.getLocationInvites(locationId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(LOCATION_INVITES_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [DEVICE_MEMBERS_GET]: ({ commit }, deviceId) => {
        commit(MEMBER_REQUEST);
        return MembersController.getDeviceMembers(deviceId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(DEVICE_MEMBERS_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },

    [MEMBER_TO_KEY_INVITE]: ({ commit, dispatch }, invite) => {
        commit(MEMBER_REQUEST);
        return MembersController.sendMemberInvite(invite)
            .then(() => {
                commit(MEMBER_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite sent",
                    title: "Success",
                    variant: "success",
                });
                dispatch(LOCATION_MEMBERS_GET, invite.LocationId);
                dispatch(LOCATION_INVITES_GET, invite.LocationId);
            })
            .catch((error) => {
                dispatch(MEMBER_ERROR, error);
            });
    },

    [MEMBER_TO_KEY_REINVITE]: ({ commit, dispatch }, {inviteId, invite}) => {
        commit(MEMBER_REQUEST);
        return MembersController.updateMemberInvite({inviteId, invite})
            .then(() => {
                commit(MEMBER_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite updated",
                    title: "Success",
                    variant: "success",
                });
            })
            .catch((error) => {
                dispatch(MEMBER_ERROR, error);
            });
    },

    [MEMBER_LIST_TO_KEY_INVITE]: (
        { commit, dispatch },
        { invitesList, locationId }
    ) => {
        commit(MEMBER_REQUEST);
        return MembersController.sendMemberListInvite(invitesList)
            .then(() => {
                commit(MEMBER_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite sent",
                    title: "Success",
                    variant: "success",
                });
                dispatch(LOCATION_MEMBERS_GET, locationId);
                dispatch(LOCATION_INVITES_GET, locationId);
            })
            .catch((error) => {
                dispatch(MEMBER_ERROR, error);
            });
    },

    [MEMBER_CANCEL_INVITE]: ({ commit, dispatch }, invite) => {
        commit(MEMBER_REQUEST);
        return MembersController.cancelMemberInvite(invite)
            .then(() => {
                commit(MEMBER_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite cancelled",
                    title: "Success",
                    variant: "success",
                });
                dispatch(LOCATION_MEMBERS_GET, invite.Key.LocationId);
                dispatch(LOCATION_INVITES_GET, invite.Key.LocationId);
            })
            .catch((error) => {
                dispatch(MEMBER_ERROR, error);
            });
    },

    [MEMBER_ERROR]: ({ commit }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger",
        });
        commit(MEMBER_ERROR, error);
    },
};

const mutations = {
    [MEMBER_REQUEST]: (state) => {
        state.status = STATUS_MEMBER_REQUEST;
    },
    [MEMBER_SUCCESS]: (state) => {
        state.status = STATUS_MEMBER_SUCCESS;
        state.requestErrors = "";
    },
    [MEMBER_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_MEMBER_ERROR;
    },
    [MEMBER_GET]: (state, member) => {
        state.member = {
            ...member,
            ...{
                Location: member.Location,
                __type: "User",
                Role: member.Role,
            },
        };
    },
    [MEMBER_GET_ADMIN]: (state, member) => {
        state.member = {
            ...member,
            ...{
                Location: member.Location,
                __type: "User",
                Role: member.Role,
            },
        };
    },
    [MEMBER_KEYS_FILL]: (state, memberKeys) => {
        let mapped = memberKeys.map((item) => {
            return {
                ...item,
                ...{
                    __type: "Key",
                },
            };
        });
        state.memberKeys = mapped;
    },
    [MEMBER_ROLES_FILL]: (state, memberRoles) => {
        let mapped = memberRoles
            .filter((item) => item.Name != "LocationOwner")
        state.memberRoles = mapped;
    },
    [MEMBER_KEYS_CLEAR]: (state) => {
        state.memberKeys = [];
    },
    [MEMBER_DEVICES_FILL]: (state, memberDevices) => {
        let mapped = memberDevices.map((item) => {
            return {
                ...item,
                ...{
                    __type: "Device",
                },
            };
        });
        state.memberDevices = mapped;
    },
    [MEMBER_DEVICES_CLEAR]: (state) => {
        state.memberDevices = [];
    },
    [DEVICE_MEMBERS_FILL]: (state, members) => {
        state.deviceMembers = members;
    },
    [DEVICE_MEMBERS_CLEAR]: (state) => {
        state.deviceMembers = [];
    },
    [LOCATION_MEMBERS_SORTED_FILL]: (state, members) => {
        state.locationMembersSorted = [];
        if (!members)
            return

        try {
            state.locationMembersSorted = members.reduce((r, a) => {
                let index = r.findIndex((item) => item.User.Id == a.User.Id);
                a.Role.TName = a.Role.Name
                if(a.Role.Name === ROLE_COMPANY_OWNER){
                    a.Role.TName = i18n.t("permissions.role_owner");
                }else if(a.Role.Name === ROLE_COMPANY_MANAGER){
                    a.Role.TName = i18n.t("permissions.role_cm");
                }else if(a.Role.Name === ROLE_LOCATION_MANAGER){
                    a.Role.TName = i18n.t("permissions.role_lm");
                }else if(a.Role.Name === ROLE_KEY_MANAGER){
                    a.Role.TName = i18n.t("permissions.role_km");
                }else if(a.Role.Name === ROLE_DEVICE_INSTALLER){
                    a.Role.TName = i18n.t("permissions.role_installer");
                }
                if (index >= 0) {
                    r[index]["Roles"].push(a.Role);
                    if (!definedKeyRolesIdsArray().includes(a.Role.Id) && a.Role.TName) {
                        if (r[index]["RolesString"])
                            r[index]["RolesString"] += ", "

                        r[index]["RolesString"] += a.Role.TName;

                        r[index].RoleNames.push(a.Role.Name)
                        r[index].MemberIds.push(a.MemberId)
                        r[index].VisibleInIntercom = r[index].VisibleInIntercom || a.VisibleInIntercom
                   }
                } else {
                    a["Roles"] = [a.Role];
                    if (!definedKeyRolesIdsArray().includes(a.Role.Id) && a.Role.TName) {
                        a["RolesString"] = a.Role.TName;
                        a.MemberIds = [a.MemberId];
                    } else {
                        a["RolesString"] = "";
                        a.MemberIds = [];
                        a.VisibleInIntercom = false
                    }
                    a.RoleNames = [a.Role.Name];
                    a.FullName = a.User?.FirstName + " " + a.User?.LastName
                    r.push(a);
                }
                return r;
            }, []);

            state.locationMembersSorted.sort((i1, i2) => {
                return i1.FullName.toUpperCase() > i2.FullName.toUpperCase() ? 1 : -1;
            })
            .forEach(item => {
                item.MaxRoleName = maxRoleName(item.RoleNames)
            })
        } catch {
            state.locationMembersSorted = [];
        }
    },
    [LOCATION_MEMBERS_FILL]: (state, members) => {
        let mapped = members
            .filter((item) => item.Role && item.Role.Name != "LocationOwner")
            .map((item) => {
                return {
                    ...item,
                    ...{
                        __type: "User",
                    },
                };
            });
        state.locationMembers = mapped;
    },
    [LOCATION_MEMBERS_CLEAR]: (state) => {
        state.locationMembers = [];
        state.locationMembersSorted = [];
    },

    [LOCATION_COMPANY_USERS_FILL]: (state, users) => {
        state.locationCompanyUsers = users;
    },
    [LOCATION_COMPANY_USERS_CLEAR]: (state) => {
        state.locationCompanyUsers = [];
    },

    [LOCATION_INVITES_FILL]: (state, invites) => {
        let mapped = invites.map((item) => {
            return {
                ...item,
                ...{
                    __type: "Invite",
                },
            };
        });
        state.locationInvites = mapped;
    },
    [LOCATION_INVITES_CLEAR]: (state) => {
        state.locationInvites = [];
    },

    [MEMBER_PERMANENT_KEYS_GET]: ({ commit }, { locationId, userId }) => {
        commit(MEMBER_REQUEST);
        return MembersController.getLocationUserPermanentKeys(locationId, userId)
            .then((response) => {
                commit(MEMBER_SUCCESS);
                commit(MEMBER_KEYS_FILL, response.data);
            })
            .catch((error) => {
                commit(MEMBER_ERROR, error);
            });
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
