export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_REQUEST = "USER_REQUEST";

export const USER_FILL = "USER_FILL";
export const USER_GET = "USER_GET";
export const USER_GET_ROLES = "USER_GET_ROLES";
export const USER_GET_KEYS = "USER_GET_KEYS";
export const USER_GET_DEVICES = "USER_GET_DEVICES";
export const USER_FILL_INVITES = "USER_FILL_INVITES";
export const USER_FILL_ROLES = "USER_FILL_ROLES";
export const USER_FILL_DEVICES = "USER_FILL_DEVICES";
export const USER_FILL_KEYS = "USER_FILL_KEYS";
export const USER_ACCEPT_INVITE = "USER_ACCEPT_INVITE";
export const USER_DECLINE_INVITE = "USER_DECLINE_INVITE";
export const USER_PATCH = "USER_PATCH";
export const USER_CLEAR = "USER_CLEAR";
export const USER_ROLE_CLEAR_ACTIVE = "USER_ROLE_CLEAR_ACTIVE";
export const USER_ROLE_SET_ACTIVE = "USER_ROLE_SET_ACTIVE";
export const USER_ROLES_LIST_SET_ACTIVE = "USER_ROLES_LIST_SET_ACTIVE";
export const USER_CHECK_EXIST = "USER_CHECK_EXIST";
