var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-breadcrumb',{staticClass:"pl-2"},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('b-icon',{attrs:{"icon":"globe","scale":"1","shift-v":"1","aria-hidden":"true"}}),_vm._v(" "+_vm._s(!_vm.getActiveCompany && !_vm.getActiveLocation && this.getBreadcrumbs.length == 0 ? _vm.$t("menu.all") : "")+" ")],1),(_vm.getActiveCompany && _vm.status)?_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'company',
            params: {
                companyId: this.getActiveCompany.Id,
            },
        }}},[_c('b-icon',{attrs:{"icon":"building","scale":"1","shift-v":"1","aria-hidden":"true"}}),_vm._v(" "+_vm._s(this.getActiveCompany.Name)+" ")],1):(_vm.getActiveCompany && !_vm.status && !_vm.roleFlag)?_c('b-breadcrumb-item',{attrs:{"active":!_vm.$can('locations-list', _vm.getActiveCompany),"to":{
            name: 'company',
            params: {
                companyId: this.getActiveCompany.Id,
            },
        }}},[_c('b-icon',{attrs:{"icon":"building","scale":"1","shift-v":"1","aria-hidden":"true"}}),_vm._v(" "+_vm._s(this.getActiveCompany.Name)+" ")],1):(_vm.getActiveCompany && !_vm.status && _vm.roleFlag)?_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'company',
            params: {
                companyId: this.getActiveCompany.Id,
            },
        }}},[_c('b-icon',{attrs:{"icon":"building","scale":"1","shift-v":"1","aria-hidden":"true"}}),_vm._v(" "+_vm._s(this.getActiveCompany.Name)+" ")],1):_vm._e(),(_vm.getActiveCompany && _vm.getActiveLocation)?_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'location',
            params: {
                locationId: this.getActiveLocation.Id,
                companyId: this.getActiveCompany.Id,
            },
        }}},[_c('b-icon',{attrs:{"icon":"geo-alt-fill","scale":"1","shift-v":"1","aria-hidden":"true"}}),_vm._v(" "+_vm._s(this.getActiveLocation.Name)+" ")],1):_vm._e(),(_vm.getActiveLocation && (_vm.getActiveLocation.CompanyId == null) && (_vm.$route.name != 'admin'))?_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'private-location',
            params: {
                locationId: this.getActiveLocation.Id,
            }, 
        }}},[_c('b-icon',{attrs:{"icon":"geo-alt-fill","scale":"1","shift-v":"1","aria-hidden":"true"}}),_vm._v(" "+_vm._s(this.getActiveLocation.Name)+" ")],1):_vm._e(),_vm._l((this.getBreadcrumbs),function(bc,index){return _c('b-breadcrumb-item',{key:index,attrs:{"active":bc.active,"to":!bc.active ? bc.to : ''}},[_vm._v(_vm._s(_vm.$t(bc.name))+" ")])}),(_vm.status && _vm.$route.name == 'admin' && !_vm.getActiveCompany && !_vm.getActiveLocation)?_c('b-breadcrumb-item',{attrs:{"to":{ name: 'admin' }}},[(this.activeTabVal == 0)?_c('b-icon',{attrs:{"icon":"building","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),(this.activeTabVal == 1)?_c('b-icon',{attrs:{"icon":"geo-alt-fill","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),(this.activeTabVal == 2)?_c('b-icon',{attrs:{"icon":"people-fill","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),(this.activeTabVal == 3)?_c('b-icon',{attrs:{"icon":"door-closed","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),(this.activeTabVal == 4)?_c('b-icon',{attrs:{"icon":"key-fill","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),(this.activeTabVal == 5)?_c('b-icon',{attrs:{"icon":"people-fill","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),(this.activeTabVal == 6)?_c('b-icon',{attrs:{"icon":"door-closed","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),(this.activeTabVal == 7)?_c('b-icon',{attrs:{"icon":"card-list","scale":"1","shift-v":"1","aria-hidden":"true"}}):_vm._e(),_vm._v(" "+_vm._s(this.activeTabTitleVal)+" ")],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }