import { SET_MODAL_DATA } from "@/store/types/global";

export default {
    methods: {
        showModal(data, type) {
            this.$store.commit(SET_MODAL_DATA, {
                data,
                type
            });
            this.$bvModal.show(type);
        }
    }
};
