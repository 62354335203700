// Polyfills
//check https://stackoverflow.com/questions/35090153/babel-support-for-object-entries
//https://forum.vuejs.org/t/vue-cli-3-babel-polyfills-and-ie11/89531

require("intersection-observer");
// require("es7-object-polyfill");
import "whatwg-fetch";

//Init
import App from "./App";
import Vue from "vue";
import store from "./store/index";
import router from "./router";
import VueSession from "vue-session";
export const EventBus = new Vue();
Vue.use(VueSession);

//* Bootstrap & Style
import {
    BootstrapVue,
    BootstrapVueIcons
} from "bootstrap-vue";

require("./assets/scss/theme.scss");
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

//* cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

//CSV Parser
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

//Axios
window.axios = require("axios");
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
//Moment js
Vue.use(require("vue-moment"));

//i18n
import {
    languages,
    defaultLocale
} from "@/i18n";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const lang = localStorage.getItem("lang") || defaultLocale;
export const i18n = new VueI18n({
    locale: lang,
    messages: {
        ...languages,
    }, // set locale messages
});

//Flags
import FlagIcon from "vue-flag-icon";
Vue.use(FlagIcon);

//Highlight
import VueHighlightJS from 'vue-highlightjs'
Vue.use(VueHighlightJS)

// import VueIntercom from 'vue-intercom';
// Vue.use(VueIntercom, { appId: 'yfasxlfk' });

// ACL Casl
import {
    abilitiesPlugin
} from "@casl/vue";
import {
    defineAbilityFor,
    defaultAbility
} from "./acl/ability";
let ability = defineAbilityFor([]);
Vue.use(abilitiesPlugin, defaultAbility);

Vue.config.productionTip = false;
/* eslint-disable no-new */

//Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDr79zN9luZ7NSSASGfJdnp2DW7p0lGpv4",
        libraries: "places",
    },
});

import {
    USER_GET,
    USER_GET_ROLES,
    USER_FILL_ROLES,
} from "@/store/types/user";
import {
    INIT_STARTED
} from "@/store/types/global";
import {
    AUTH_LOGOUT
} from "@/store/types/auth";
import reportController from "@/api/bugreport";
import {
    CHECK_ADMIN_USER
  } from "@/store/types/admin";

new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
    async created() {
        await this.initUser();
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === USER_FILL_ROLES) {
                this.$ability.update([]);
                this.$ability.update(defineAbilityFor(mutation.payload));
            }
        });
    },
    methods: {
        async initUser() {
            window.axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if (error.response.status == 401) {
                        this.$store.commit(AUTH_LOGOUT);
                    } else {
                        //if (process.env.VUE_APP_SLACK_REPORT == "true") {
                        //    reportController.reportAxiosSlack(error.response);
                        //}
                    }
                    return Promise.reject(error);
                }
            );
            window.axios.interceptors.request.use(
                (config) => {
                    let token = this.$cookies.get("Authorization");
                    token &&
                        (config.headers["Authorization"] = `Bearer ${token}`);
                    config.headers["AppType"] = 0;

                    return config;
                },

                (error) => {
                    return Promise.reject(error);
                }
            );
            if (this.$store.getters.isUserAuthenticated) {
                await Promise.all([
                    this.$store.commit(INIT_STARTED),
                    this.$store.dispatch(USER_GET_ROLES),
                    this.$store.dispatch(CHECK_ADMIN_USER).then((response) => {
                        if(response.data.status == true){
                            this.$session.set("adminUser", true);
                        }
                    }),
                    this.$store.dispatch(USER_GET).then(response => {
                        // this.$session.set("uid", response.Id);
                        /*this.$intercom.boot({
                            user_id: response.Id,
                            name: `${response.FirstName} ${response.LastName || ""}`,
                            email: response.Email,
                            language_override: lang,
                            avatar: {
                                "type": "avatar",
                                "image_url" :response.SmallImageUrl
                            },
                        });*/
                    })
                ]);
            }
        },
    },
}).$mount("#app");
