/* jshint esversion: 2015 */
import axios from "axios";

export default {
    loginDanalock(credentials) {
        return axios.post(`provider/danalock/user/${credentials.userId}/login`, credentials);
    },
    registerDanalock(credentials) {
        return axios.post(`provider/danalock/user/register`, credentials);
    },
    checkDanalock(userId) {
        return axios.post(`provider/danalock/user/${userId}/login`, {});
    },
};
