<template>
    <div v-bind:class="authPages ? 'container-fluid' : 'w-100'">
        <b-overlay
            :show="getInitProcessing"
            variant="light"
            opacity="1"
            rounded="sm"
            no-wrap
            fixed
            z-index="1200"
        >
            <template #overlay>
                <div class="text-center">
                    <img
                        src="/images/loader_logo.svg"
                        class="img-fluid"
                        style="max-width: 182px"
                    />
                </div>
            </template>
        </b-overlay>

        <zs-sidebar
            v-if="isUserAuthenticated && getUser && !authPages && !docPages"
        />
        <zs-doc-sidebar v-if="docPages" />        
        <div class="main-content">
            <zs-header v-if="isUserAuthenticated && getUser && !authPages" />
            <vue-page-transition name="fade">
                <router-view />
            </vue-page-transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SHOW_NOTIFY } from "@/store/types/global";

import VuePageTransition from "@/components/common/PageTransition/components/VuePageTransition";
import Sidebar from "@/components/common/Nav/Sidebar";
import DocSidebar from "@/views/ApiDoc/Sidebar";
import Header from "@/components/common/Nav/Header";

export default {
    name: "App",
    components: {
        "zs-sidebar": Sidebar,
        "zs-header": Header,
        "zs-doc-sidebar": DocSidebar,
        "vue-page-transition": VuePageTransition,
    },
    data() {
        return {
            user: {},
            breadcrumbsOn: false,
            sidebarOn: false,             
        };
    },

    computed: {
        ...mapGetters(["isUserAuthenticated", "getInitProcessing", "getUser"]),
        authPages() {
            return ["login", "password-reset", "register"].includes(
                this.$route.name
            );
        },
        docPages() {
            return ["developers"].includes(this.$route.name);
        },
    },
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === SHOW_NOTIFY) {
                this.showNotify(mutation.payload);
            }
        });
    },
    methods: {
        showNotify(notifyData) {
            let text =
                "translation" in notifyData
                    ? this.$t("alerts." + notifyData.translation)
                    : notifyData.text;

            if(notifyData.title == "Error"){
                notifyData.title = this.$t("notify.error");
            }else if(notifyData.title == "Success"){
                notifyData.title = this.$t("notify.success");
            }

            if(text == "Profile updated"){
                text = this.$t("notify.profile_update");
            }else if(text == "Company updated"){
                text = this.$t("notify.company_update");
            }else if(text == "Company created"){
                text = this.$t("notify.company_create");
            }else if(text == "Company deleted"){
                text = this.$t("notify.company_delete");
            }else if(text == "Location created"){
                text = this.$t("notify.location_create");
            }else if(text == "Location updated"){
                text = this.$t("notify.location_update");
            }else if(text == "Location deleted"){
                text = this.$t("notify.location_delete");
            }else if(text == "Door created"){
                text = this.$t("notify.door_create");
            }else if(text == "Door updated"){
                text = this.$t("notify.door_update");
            }else if(text == "Door deleted"){
                text = this.$t("notify.door_delete");
            }else if(text == "Key created"){
                text = this.$t("notify.key_create");
            }else if(text == "Key updated"){
                text = this.$t("notify.key_update");
            }else if(text == "Key deleted"){
                text = this.$t("notify.key_delete");
            }else if(text == "Invite sent"){
                text = this.$t("notify.invite_sent");
            }else if(text == "Manager removed"){
                text = this.$t("notify.manager_removed");
            }else if(text == "User removed"){
                text = this.$t("notify.user_removed_new");
            }else if(text == "The user is not member of the company."){
                text = this.$t("notify.not_member");
            }else if(text == "The user already exists with this role."){                
                text = this.$t("notify.member_exist");
            }else if(text == "The user is not member of this location."){
                text = this.$t("notify.not_member_loc");
            }else if(text == "Door removed from key"){
                text = this.$t("notify.door_removed");
            }else if(text == "Door added to key"){
                text = this.$t("notify.door_add_key");
            }else if(text == "User removed from key"){
                text = this.$t("notify.user_removed");
            }else if(text == "Invite updated"){
                text = this.$t("notify.invite_update");
            }else if(text == "Not authorized"){
                text = this.$t("notify.not_authorized");
            }else if(text == "undefined"){
                text = this.$t("notify.undefined");
            }else if(text == "Not enough rights."){
                text = this.$t("notify.no_rights");
            }else if(text == "The member already exists."){                
                text = this.$t("notify.member_exist");
            }else if(text == "Salto Installation created"){
                text = this.$t("notify.salto_installation_created"); 
            }else if(text == "Salto Installation updated"){
                text = this.$t("notify.salto_installation_updated"); 
            }else if(text == "Salto Installation deleted"){
                text = this.$t("notify.salto_installation_deleted"); 
            }else if(text == "Danalock authentication fail"){
                text = this.$t("notify.danalock_authentication_fail");
            }else if(text == "Rights transfered"){
                text = this.$t("notify.rights_transfered");
            }else if(text == "Devices connected"){
                text = this.$t("notify.devices_connected_msg");
            }else if(text == "Devices disconnected"){
                text = this.$t("notify.devices_disconnected_msg");
            }else if(text == "There are no devices"){
                text = this.$t("notify.no_devices");
            }else if(text == "User disconnected from company"){
                text = this.$t("notify.notify_deleted_from_company");
            }else if(text == "User disconnected from location"){
                text = this.$t("notify.notify_deleted_from_location");
            }else if(text == "User disconnected from device"){
                text = this.$t("notify.notify_deleted_from_device");
            }else if(text == "User disconnected from key"){
                text = this.$t("notify.notify_deleted_from_key");
            }else if(text == "User disconnected from system"){
                text = this.$t("notify.notify_deleted_from_system");
            }else if(text == "User disconnected"){
                text = this.$t("notify.user_disconnected");
            }else if(text == "Users removed"){
                text = this.$t("notify.users_removed_new");
            }

            
            this.$bvToast.toast(text, {
                title: notifyData.title,
                variant: " __" + notifyData.variant,
                solid: true,
                autoHideDelay: 2000,
            });
        },        
    },   
};
</script>
<style>
.__success .toast .toast-header {
    color: #098360 !important;
}

.__danger .toast .toast-header {
    color: #e63857 !important;
}
</style>