/* jshint esversion: 2015 */

export default {
    getUser() {
        return window.axios({
            url: `user`,
            method: "GET"
        });
    },

    patchUser(patch) {
        return window.axios.put("/user", patch);
    },

    getInvitesList() {
        return window.axios.get(`user/invite/recipient`);
    },

    getKeysList() {
        return window.axios.get(`user/key`);
    },

    getDevicesList() {
        return window.axios.get(`user/device`);
    },

    acceptInvite(inviteId) {
        return window.axios.post(`/user/invite/accept`, {
            Ids: [inviteId]
        });
    },
    checkUserExists(phoneNumber) {
        return window.axios.get(`user/exists`, {
            params: {
                phoneNumber: phoneNumber
            },
        });
    }
};
