<template>
    <b-button-group v-if="getLanguage">
        <b-button
            v-for="lang in langSettings"
            v-bind:key="lang.locale"
            @click="setLocale(lang.locale)"
            :variant="getLanguage==lang.locale ? '':'light'"
            class="text-truncate"
            size="sm"
        >
            <flag :iso="lang.iconClass" :squared="false" />
            <span class="ml-1">{{$t(lang.local)}}</span>
        </b-button>
    </b-button-group>
</template>
<script>
import { langSettings } from "@/i18n/index.js";
import { SET_LANGUAGE } from "@/store/types/global";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            langSettings: langSettings
        };
    },
    computed: {
        ...mapGetters(["getLanguage"])
    },
    methods: {
        setLocale(locale) {
            this.$store.commit(SET_LANGUAGE, locale);
            this.$i18n.locale = locale;
        }
    }
};
</script>
