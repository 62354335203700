export const COMPANY_STATE_RESET = "COMPANY_STATE_RESET";

export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const COMPANY_REQUEST = "COMPANY_REQUEST";

export const COMPANIES_GET = "COMPANIES_GET";
export const ALL_COMPANIES_GET = "ALL_COMPANIES_GET";
export const COMPANIES_FILL = "COMPANIES_FILL";
export const COMPANIES_CLEAR = "COMPANIES_CLEAR";

export const COMPANY_SET_ACTIVE = "COMPANY_SET_ACTIVE";
export const COMPANY_CLEAR_ACTIVE = "COMPANY_CLEAR_ACTIVE";
export const COMPANY_REFRESH_ACTIVE = "COMPANY_REFRESH_ACTIVE";
export const COMPANY_GET = "COMPANY_GET";
export const COMPANY_CREATE = "COMPANY_CREATE";
export const COMPANY_PATCH = "COMPANY_PATCH";
export const COMPANY_DELETE = "COMPANY_DELETE";

export const COMPANY_GET_EMPLOYEES = "COMPANY_GET_EMPLOYEES";
export const COMPANY_GET_ALL_EMPLOYEES = "COMPANY_GET_ALL_EMPLOYEES";
export const COMPANY_FILL_EMPLOYEES = "COMPANY_FILL_EMPLOYEES";
export const COMPANY_GET_INVITES = "COMPANY_GET_INVITES";
export const COMPANY_FILL_INVITES = "COMPANY_FILL_INVITES";
export const COMPANY_USER_DELETE = "COMPANY_USER_DELETE";
