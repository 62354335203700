import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import auth from "./modules/auth";
import companies from "./modules/companies";
import globals from "./modules/global";
import locations from "./modules/locations";
import devices from "./modules/devices";
import keys from "./modules/keys";
import members from "./modules/members";
import activity from "./modules/activity";
import acl from "./modules/acl";
import intercom from "./modules/intercom";
import providers from "./modules/providers";
import notifications from "./modules/notifications";
import salto from "./modules/salto";
import admin from "./modules/admin";
import passport from "./modules/passport";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        auth,
        companies,
        locations,
        devices,
        keys,
        members,
        activity,
        acl,
        notifications,
        intercom,
        providers,
        globals,
        salto,
        admin,
        passport,
    },
    actions: {
        resetState: function (context) {
            // context.commit("user/resetState");
            // context.commit("company/resetState");
            // context.commit("location/resetState");
        },
    },
});
