import Vue from "vue";
import AclController from "../../api/acl";

import {
    ACL_REQUEST,
    ACL_SUCCESS,
    ACL_ERROR,
    ACL_INVITE_TO_COMPANY_ROLE,
    ACL_INVITE_TO_LOCATION_ROLE
} from "../types/acl";

import {
    STATUS_ACL_REQUEST,
    STATUS_ACL_SUCCESS,
    STATUS_ACL_ERROR
} from "../types/statuses";

import { SHOW_NOTIFY } from "@/store/types/global";

const state = {
    requestErrors: ""
};

const getters = {
    getAclRequestErrors: state => state.requestErrors
};

const actions = {

    [ACL_INVITE_TO_COMPANY_ROLE]: ({ commit, dispatch }, invite) => {
        commit(ACL_REQUEST);
        return AclController.postCompanyRoleInvite(invite)
            .then(() => {
                commit(ACL_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite sent",
                    title: "Success",
                    variant: "success"
                });
            })
            .catch(error => {
                dispatch(ACL_ERROR, error);
            });
    },

    [ACL_INVITE_TO_LOCATION_ROLE]: ({ commit, dispatch }, invite) => {
        commit(ACL_REQUEST);
        return AclController.postLocationRoleInvite(invite)
            .then(() => {
                commit(ACL_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite sent",
                    title: "Success",
                    variant: "success"
                });
            })
            .catch(error => {
                dispatch(ACL_ERROR, error);
            });
    },

    [ACL_ERROR]: ({ commit }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger"
        });
        commit(ACL_ERROR, error);
    }
};

const mutations = {
    [ACL_REQUEST]: state => {
        state.status = STATUS_ACL_REQUEST;
    },
    [ACL_SUCCESS]: state => {
        state.status = STATUS_ACL_SUCCESS;
        state.requestErrors = "";
    },
    [ACL_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_ACL_ERROR;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
