import axios from "axios";

export default {
    getKeys(locationId) {
        return window.axios.get("location/" + locationId + "/key");
    },

    getKey(keyId) {
        return window.axios.get("key/" + keyId);
    },

    getKeyDevices(keyId) {
        return window.axios.get("key/" + keyId + "/device");
    },

    getKeyInvites(keyId) {
        return window.axios.get(`key/${keyId}/invite`);
    },

    getKeyUsers(keyId) {
        return window.axios.get(`key/${keyId}/user`);
    },

    addKeyDevices(keyId, payload) {
        return window.axios.post(`key/${keyId}/device`, {
            DeviceIds: payload
        });
    },

    addKeyDevice(keyId, deviceId) {
        return window.axios.post("key/" + keyId + "/device", {
            DeviceIds: [deviceId]
        });
    },

    deleteKeyDevice(keyId, deviceId) {
        return window.axios.delete("key/" + keyId + "/device/" + deviceId);
    },

    deleteKeyUser(keyId, userId) {
        return window.axios.delete(`key/${keyId}/user/${userId}`);
    },

    postKey(payload) {
        return window.axios.post("location/" + payload.LocationId + "/key", payload);
    },

    patchKey(payload) {
        return window.axios.put(
            "location/" + payload.LocationId + "/key/" + payload.Id,
            payload
        );
    },

    deleteKey(key) {
        return window.axios.delete("location/" + key.LocationId + "/key/" + key.Id);
    }
};
