import cookies from "vue-cookies";
import Vue from "vue";

import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_ERROR_CLEAR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    AUTH_REGISTER,
    AUTH_LOGIN,
    AUTH_REFRESH,
    AUTH_REQUEST_CODE,
    AUTH_RESET_PASSWORD,
    AUTH_SUBMIT_CODE,
    REGISTER_USER
} from "../types/auth";

import {
    USER_FILL,
    USER_GET,
    USER_CLEAR,
    USER_GET_ROLES
} from "../types/user";

import {
    COMPANY_STATE_RESET
} from "../types/companies";

import {
    LOCATION_STATE_RESET
} from "../types/locations";

import {
    STATUS_AUTH_SUCCESS,
    STATUS_AUTH_ERROR,
    STATUS_AUTH_REQUEST
} from "../types/statuses";

import authController from "../../api/auth";

const state = {
    token: cookies.get("Authorization"),
    status: "",
    authErrors: null
};

const getters = {
    getAuthStatus: state => state.status,
    getAuthErrors: state => state.authErrors,
    isUserAuthenticated: state => state.token !== null
};

const actions = {
    [AUTH_LOGIN]: ({
        commit,
        dispatch
    }, credentials) => {
        commit(AUTH_REQUEST);
        return authController
            .login(credentials)
            .then(response => {
                commit(AUTH_SUCCESS, response.data);
                commit(USER_FILL, response.data.User);
                dispatch(USER_GET_ROLES);
            })
            .catch(error => {
                commit(AUTH_ERROR, error);
                return Promise.reject(error);
            });
    },
    [AUTH_REGISTER]: ({
        commit
    }, credentials) => {
        commit(AUTH_REQUEST);
        return authController
            .register(credentials)
            .then(response => {
                commit(AUTH_SUCCESS, response.data);
                commit(USER_FILL, response.data);
                return response.data;
            })
            .catch(error => {
                commit(AUTH_ERROR, error);
                return Promise.reject(error);
            });
    },
    [REGISTER_USER]: ({
        commit
    }, credentials) => {
        commit(AUTH_REQUEST);
        return authController.registerUser(credentials)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            commit(AUTH_ERROR, error);
            return Promise.reject(error);
        });
    },
    [AUTH_REQUEST_CODE]: ({
        commit
    }, credentials) => {
        commit(AUTH_REQUEST);
        return authController
            .requestCode(credentials)
            .then(response => {
                return response.data
            })
            .catch(error => {
                commit(AUTH_ERROR, error);
                return Promise.reject(error);
            });
    },

    [AUTH_RESET_PASSWORD]: ({
        commit,
        dispatch
    }, credentials) => {
        commit(AUTH_REQUEST);
        return authController
            .resetPassword(credentials)
            .then(response => {
                commit(AUTH_SUCCESS, response.data);
                dispatch(USER_GET);
                dispatch(USER_GET_ROLES);
            })
            .catch(error => {
                commit(AUTH_ERROR, error);
                return Promise.reject(error);
            });
    },

    [AUTH_SUBMIT_CODE]: ({
        commit,
        dispatch
    }, credentials) => {
        commit(AUTH_REQUEST);
        return authController
            .submitCode(credentials)
            .then(response => {
                dispatch(USER_GET_ROLES);
                commit(AUTH_SUCCESS, response.data);
                commit(USER_FILL, response.data.User);
            })
            .catch(error => {
                commit(AUTH_ERROR, error);
                return Promise.reject(error);
            });
    },

    [AUTH_LOGOUT]: ({
        commit
    }) => {
        return authController
            .logout()
            .then(() => {
                commit(AUTH_LOGOUT);
                commit(USER_CLEAR);
            })
            .catch(() => {
                commit(AUTH_LOGOUT);
                commit(USER_CLEAR);
            });
    },
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = STATUS_AUTH_REQUEST;
    },
    [AUTH_SUCCESS]: (state, data) => {
        state.authErrors = null;
        state.status = STATUS_AUTH_SUCCESS;
        state.token = data.AccessToken;
        cookies.set("Authorization", data.AccessToken, "30d");
        localStorage.setItem("reftesh_token", data.RefreshToken);
    },
    [AUTH_ERROR]: (state, error) => {
        if (error.response.data.error)
            Vue.set(state, "authErrors", error.response.data.error);
        else if (error.response.data.message)
            Vue.set(state, "authErrors", error.response.data.message);
        else {
            Vue.set(state, "authErrors", error);
        }
        state.status = STATUS_AUTH_ERROR;
    },
    [AUTH_ERROR_CLEAR]: state => {
        Vue.set(state, "authErrors", null);
    },
    [AUTH_LOGOUT]: state => {
        cookies.remove("Authorization");
        window.location.href = "/login";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
