/* jshint esversion: 2015 */
import axios from "axios";

export default {
    getDeviceMembers(deviceId) {
        return window.axios.get(`device/${deviceId}/invite`);
    },

    sendMemberInvite(invite) {
        return window.axios.post(`key/${invite.KeyId}/invite`, invite);
    },

    updateMemberInvite (payload) {
        return window.axios.put(`key/access/${payload.inviteId}`, payload.invite);
    },

    deleteMemberInvite (inviteId) {
        return window.axios.delete(`key/access/${inviteId}`);
    },

    sendMemberListInvite(invitesList) {
        return window.axios.post("/invite/list", {
            Invites: invitesList
        });
    },

    getMemberInvites(deviceId) {
        return window.axios.get(`b2b/device/${deviceId}/invite`);
    },

    cancelMemberInvite(invite) {
        return window.axios.delete(`invite/${invite.Id}`);
    },

    getLocationMember(locationId, memberId) {
        return window.axios.get(`user/${memberId}`);
    },

    getLocationMemberAdmin(memberId) {
        return window.axios.get(`user/${memberId}`);
    },

    getMemberLocationKeys(locationId, memberId) {
        return window.axios.get(`location/${locationId}/user/${memberId}/key`);
    },

    getMemberLocationKeysAdmin(memberId) {
        return window.axios.get(`user/${memberId}/keys`);
    },

    getLocationUserPermanentKeys(locationId, memberId) {
        return window.axios.get(`location/${locationId}/user/${memberId}/key/permanentKey`);
    },

    getMemberLocationDevices(locationId, memberId) {
        return window.axios.get(`location/${locationId}/user/${memberId}/device`);
    },

    getMemberLocationDevicesAdmin(memberId) {
        return window.axios.get(`user/${memberId}/device`);
    },

    getMemberRoles(companyId, memberId) {
        return window.axios.get(`b2b/company/${companyId}/user/${memberId}/roles`);
    },

    updateMemberByKeyIdUserIdAndLocationIdShouldShowInIntercomList(keyId, userId, locationId, visibleInIntercomInt) {
        return window.axios.put(`member/key/${keyId}/user/${userId}/location/${locationId}/visible/${visibleInIntercomInt}`)
    },

    updateMemberIntercom(memberId, visibleInIntercomInt) {
        return window.axios.put(`member/${memberId}/visible/${visibleInIntercomInt}`)
    }
};
