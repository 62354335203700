export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const ADMIN_REQUEST = "ADMIN_REQUEST";

export const CHECK_ADMIN_USER = "CHECK_ADMIN_USER";
export const ADMIN_USERS_GET = "ADMIN_USERS_GET";
export const ADMIN_USERS_FILL = "ADMIN_USERS_FILL";

export const ADMIN_STAFF_GET = "ADMIN_STAFF_GET";
export const ADMIN_STAFF_FILL = "ADMIN_STAFF_FILL";
export const ACL_INVITE_TO_COMPANY_ROLE_ADMIN = "ACL_INVITE_TO_COMPANY_ROLE_ADMIN";
export const ADMIN_COMPANY_GET_INVITES = "ADMIN_COMPANY_GET_INVITES";
export const ADMIN_COMPANY_FILL_INVITES = "ADMIN_COMPANY_FILL_INVITES";

export const ADMIN_ACTIVITY_GET = "ADMIN_ACTIVITY_GET";
export const ADMIN_ACTIVITY_FILL = "ADMIN_ACTIVITY_FILL";

export const ADMIN_KEY_CREATE = "ADMIN_KEY_CREATE";
export const ADMIN_KEYS_FILL = "ADMIN_KEYS_FILL";
export const ADMIN_KEYS_GET = "ADMIN_KEYS_GET";
export const ADMIN_KEY_PATCH = "ADMIN_KEY_PATCH";
export const ADMIN_KEY_DELETE = "ADMIN_KEY_DELETE";
export const ADMIN_MEMBER_LIST_TO_KEY_INVITE = "ADMIN_MEMBER_LIST_TO_KEY_INVITE";
export const ADMIN_KEY_DEVICES_ADD = "ADMIN_KEY_DEVICES_ADD";

export const ADMIN_COMPANIES_GET = "ADMIN_COMPANIES_GET";
export const ADMIN_COMPANY_CREATE = "ADMIN_COMPANY_CREATE";
export const ADMIN_COMPANY_PATCH = "ADMIN_COMPANY_PATCH";
export const ADMIN_COMPANY_DELETE = "ADMIN_COMPANY_DELETE";
export const ADMIN_COMPANIES_FILL = "ADMIN_COMPANIES_FILL";

export const ADMIN_DEVICES_GET = "ADMIN_DEVICES_GET";
export const ADMIN_DEVICE_PATCH = "ADMIN_DEVICE_PATCH";
export const ADMIN_DEVICE_DELETE = "ADMIN_DEVICE_DELETE";
export const ADMIN_DEVICE_CODE_PATCH = "ADMIN_DEVICE_CODE_PATCH";
export const ADMIN_DEVICE_CODE_ERROR = "ADMIN_DEVICE_CODE_ERROR";
export const ADMIN_DEVICE_CODE_SUCCESS = "ADMIN_DEVICE_CODE_SUCCESS";
export const ADMIN_DEVICES_FILL = "ADMIN_DEVICES_FILL";
export const ADMIN_DEVICE_CREATE = "ADMIN_DEVICE_CREATE";

export const ADMIN_LOCATIONS_GET = "ADMIN_LOCATIONS_GET";
export const ADMIN_LOCATION_PATCH = "ADMIN_LOCATION_PATCH";
export const ADMIN_LOCATION_DELETE = "ADMIN_LOCATION_DELETE";
export const ADMIN_LOCATIONS_FILL = "ADMIN_LOCATIONS_FILL";
export const ADMIN_LOCATION_CREATE = "ADMIN_LOCATION_CREATE";
export const ADMIN_LOCATION_USER_DELETE = "ADMIN_LOCATION_USER_DELETE";
export const ADMIN_COMPANY_USER_DELETE = "ADMIN_COMPANY_USER_DELETE";

export const ADMIN_KEY_COMPANIES_GET = "ADMIN_KEY_COMPANIES_GET";
export const ADMIN_KEY_LOCATIONS_GET = "ADMIN_KEY_LOCATIONS_GET";
export const ADMIN_KEY_DEVICES_GET = "ADMIN_KEY_DEVICES_GET";
export const ADMIN_COMPANY_STAFF_GET = "ADMIN_COMPANY_STAFF_GET";
export const ADMIN_KEY_COMPANY_FILL = "ADMIN_KEY_COMPANY_FILL";
export const ADMIN_KEY_LOCATION_FILL = "ADMIN_KEY_LOCATION_FILL";
export const ADMIN_KEY_DEVICE_FILL = "ADMIN_KEY_DEVICE_FILL";
export const ADMIN_USER_LOCATIONS_GET = "ADMIN_USER_LOCATIONS_GET";
export const ADMIN_USER_LOCATION_FILL = "ADMIN_USER_LOCATION_FILL";
export const ADMIN_COMPANY_STAFF_FILL = "ADMIN_COMPANY_STAFF_FILL";
export const ADMIN_UNIQUE_KEY_GET = "ADMIN_UNIQUE_KEY_GET";
export const ADMIN_UNIQUE_KEY_FILL = "ADMIN_UNIQUE_KEY_FILL";
export const ADMIN_DISCONNECTED_DEVICES = "ADMIN_DISCONNECTED_DEVICES";
export const ADMIN_TRANSFER_RIGHTS = "ADMIN_TRANSFER_RIGHTS";
export const ADMIN_CONNECT_MULTIPLE_DEVICES = "ADMIN_CONNECT_MULTIPLE_DEVICES";
export const ADMIN_DISCONNECT_MULTIPLE_DEVICES = "ADMIN_DISCONNECT_MULTIPLE_DEVICES";
export const ADMIN_DISCONNECT_USER = "ADMIN_DISCONNECT_USER";
export const ADMIN_MULTPLE_USER_DELETE = "ADMIN_MULTPLE_USER_DELETE";

export const REGISTERED_USERS_GET = "REGISTERED_USERS_GET";
export const REGISTERED_USERS_FILL = "REGISTERED_USERS_FILL";