var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-vertical fixed-left navbar-expand-md navbar-light",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"container-fluid"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebarCollapse",modifiers:{"sidebarCollapse":true}}],staticClass:"navbar-toggler",attrs:{"variant":"dc"}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('sidebar-user',{attrs:{"user":_vm.getUser,"companies":_vm.userCompanies,"activeCompany":_vm.getActiveCompany}}),_c('b-collapse',{staticClass:"collapse navbar-collapse",attrs:{"id":"sidebarCollapse"}},[(_vm.getActiveCompany)?_c('router-link',{staticClass:"navbar-brand bot-divider d-none d-md-block",attrs:{"to":{
                    name: 'company',
                    params: {
                        companyId: _vm.getActiveCompany.Id,
                    },
                }}},[_c('div',{staticClass:"row d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col-auto mx-auto"},[_c('div',{staticClass:"avatar avatar-xxl header-avatar-top"},[_c('img',{staticClass:"avatar-img rounded border-body",attrs:{"src":_vm.getActiveCompany.SmallImageUrl ||
                                    '/images/zesec-placeholder.png',"alt":_vm.getActiveCompany.Name}})])])])]):_c('div',{staticClass:"navbar-brand bot-divider d-none d-md-block"},[_c('div',{staticClass:"row d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col-auto mx-auto"},[_c('div',{staticClass:"avatar avatar-xxl header-avatar-top"},[_c('img',{staticClass:"avatar-img border-body",attrs:{"src":"/images/empty-placeholder.png"}})])])])]),(
                    _vm.locationContext && _vm.getActiveLocation && _vm.getActiveCompany
                )?[_c('h6',{staticClass:"navbar-heading"},[_vm._v(" "+_vm._s(_vm.$t("locations.location"))+" ")]),_c('Select2',{staticStyle:{"left":"0px !important"},attrs:{"options":_vm.companyLocations,"disabled":_vm.getActiveCompany &&
                        !_vm.$can('locations-list', _vm.getActiveCompany),"value":_vm.getActiveLocation.Id,"settings":{
                        placeholder: _vm.$t('menu.select_location'),
                        // minimumResultsForSearch: -1,
                        templateSelection: _vm.locationHtml,
                        containerCssClass:
                            'custom-select custom-select-sm form-control-flush',
                        dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                    }},on:{"change":_vm.initLocation}}),(_vm.getActiveLocation)?_c('hr',{staticClass:"navbar-divider my-4"}):_vm._e()]:_vm._e(),(!_vm.locationContext)?[_c('h6',{staticClass:"navbar-heading"},[_vm._v(" "+_vm._s(_vm.$t("companies.company"))+" ")]),_c('Select2',{attrs:{"options":_vm.userCompanies,"value":_vm.getActiveCompany ? _vm.getActiveCompany.Id : null,"settings":{
                        placeholder: _vm.$t('menu.all'),
                        // minimumResultsForSearch: -1,
                        templateSelection: _vm.companyHtml,
                        containerCssClass:
                            'custom-select custom-select-sm form-control-flush',
                        dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                    }},on:{"change":_vm.initCompany}}),_c('hr',{staticClass:"navbar-divider my-3"})]:_vm._e(),_c('main-nav'),_c('hr',{staticClass:"navbar-divider my-3"}),(
                    _vm.getActiveLocation && _vm.$can('shareKey', _vm.getActiveLocation)
                )?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.inviteMember}},[_vm._v(_vm._s(_vm.$t("keys.send_invite")))]):_vm._e(),(
                    _vm.getActiveLocation && _vm.roleFlag && !_vm.$can('shareKey', _vm.getActiveLocation)
                )?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.inviteMember}},[_vm._v(_vm._s(_vm.$t("keys.send_invite")))]):_vm._e(),_c('div',{staticClass:"mt-auto"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"navbar-brand-img mx-auto py-1",attrs:{"src":"/logo-p.png","alt":"Zesec"}})])],1)],2),(_vm.getActiveLocation && _vm.$can('shareKey', _vm.getActiveLocation))?_c('zs-sharekey-modal',{attrs:{"locationId":_vm.getActiveLocation.Id}}):_vm._e(),(_vm.getActiveLocation && _vm.roleFlag && !_vm.$can('shareKey', _vm.getActiveLocation))?_c('zs-sharekey-modal',{attrs:{"locationId":_vm.getActiveLocation.Id}}):_vm._e(),_c('zs-resharekey-modal')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }