import CompanyController from "../../api/company";
import { definedKeyRolesIdsArray } from "@/acl/roles";

import {
    COMPANY_REQUEST,
    COMPANY_ERROR,
    COMPANY_SUCCESS,
    COMPANIES_GET,
    ALL_COMPANIES_GET,
    COMPANIES_CLEAR,
    COMPANIES_FILL,
    COMPANY_SET_ACTIVE,
    COMPANY_REFRESH_ACTIVE,
    COMPANY_CLEAR_ACTIVE,
    COMPANY_GET,
    COMPANY_DELETE,
    COMPANY_PATCH,
    COMPANY_CREATE,
    COMPANY_STATE_RESET,
    COMPANY_GET_EMPLOYEES,
    COMPANY_GET_ALL_EMPLOYEES,
    COMPANY_GET_INVITES,
    COMPANY_FILL_EMPLOYEES,
    COMPANY_FILL_INVITES,
    COMPANY_USER_DELETE
} from "@/store/types/companies";

import {
    USER_GET_ROLES
} from "@/store/types/user";

import {
    STATUS_COMPANY_REQUEST,
    STATUS_COMPANY_SUCCESS,
    STATUS_COMPANY_ERROR
} from "@/store/types/statuses";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const getDefaultState = () => {
    return {
        status: "",
        companies: [],
        activeCompany: null,
        requestErrors: "",
        employees: [],
        invites: [],
        allCompanies: [],
    };
};

const state = getDefaultState();

const getters = {
    getUserCompanies: state => state.companies,
    getAllCompanies: state => state.allCompanies,
    getActiveCompany: state => state.activeCompany,
    getCompanyEmployees: state => state.employees,
    getCompanyInvites: state => state.invites,
    getCompanyRequestErrors: state => state.requestErrors
};

const actions = {
    [COMPANIES_GET]: ({
        commit,
        dispatch
    }) => {
        commit(COMPANY_REQUEST);
        return CompanyController.getCompanies()
            .then(response => {
                commit(COMPANY_SUCCESS);
                commit(COMPANIES_FILL, response.data);
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },
    [ALL_COMPANIES_GET]: ({
        commit,
        dispatch
    }) => {
        commit(COMPANY_REQUEST);
        return CompanyController.getAllCompanies()
            .then(response => {
                commit(COMPANY_SUCCESS);
                commit(COMPANIES_FILL, response.data);
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },
    [COMPANY_GET]: ({
        commit,
        dispatch
    }, companyId) => {
        commit(COMPANY_REQUEST);
        return CompanyController.getCompany(companyId)
            .then(response => {
                commit(COMPANY_SUCCESS);
                commit(COMPANY_SET_ACTIVE, response.data);
                return {
                    ...response.data,
                    ...{
                        __type: "Company"
                    },
                };
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },

    [COMPANY_DELETE]: ({
        commit,
        dispatch
    }, companyId) => {
        commit(COMPANY_REQUEST);
        return CompanyController.deleteCompany(companyId)
            .then(response => {
                commit(COMPANY_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Company deleted",
                    title: "Success",
                    variant: "success"
                });
                dispatch(COMPANIES_GET).then(() =>
                    commit(COMPANY_REFRESH_ACTIVE)
                );
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },

    [COMPANY_CREATE]: ({
        commit,
        dispatch
    }, company) => {
        commit(COMPANY_REQUEST);
        CompanyController.postCompany(company)
            .then(response => {
                commit(SHOW_NOTIFY, {
                    text: "Company created",
                    title: "Success",
                    variant: "success"
                });
                dispatch(COMPANIES_GET);
                dispatch(USER_GET_ROLES);
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },

    [COMPANY_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(COMPANY_REQUEST);
        return CompanyController.patchCompany(patch.Id, patch)
            .then(response => {
                commit(SHOW_NOTIFY, {
                    text: "Company updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(COMPANIES_GET).then(() =>
                    commit(COMPANY_REFRESH_ACTIVE)
                );
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },

    [COMPANY_GET_EMPLOYEES]: ({
        commit,
        dispatch
    }, companyId) => {
        commit(COMPANY_REQUEST);
        return CompanyController.getCompanyEmployees(companyId)
            .then(response => {
                commit(COMPANY_SUCCESS);
                commit(COMPANY_FILL_EMPLOYEES, response.data);
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },
    [COMPANY_GET_ALL_EMPLOYEES]: ({
        commit,
        dispatch
    }) => {
        commit(COMPANY_REQUEST);
        return CompanyController.getAllCompanyEmployees()
            .then(response => {
                commit(COMPANY_SUCCESS);
                commit(COMPANY_FILL_EMPLOYEES, response.data);
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },
    [COMPANY_GET_INVITES]: ({
        commit,
        dispatch
    }, companyId) => {
        commit(COMPANY_REQUEST);
        return CompanyController.getCompanyInvites(companyId)
            .then(response => {
                commit(COMPANY_SUCCESS);
                commit(COMPANY_FILL_INVITES, response.data);
            })
            .catch(error => {
                dispatch(COMPANY_ERROR, error);
            });
    },

    [COMPANY_USER_DELETE]: ({
        commit,
        dispatch
    }, payload) => {
        commit(COMPANY_REQUEST);
        return CompanyController.deleteCompanyUser(payload)
            .then(() => {
                commit(COMPANY_SUCCESS);
                if(payload.silent !== true){
                    commit(SHOW_NOTIFY, {
                        text: "Manager removed",
                        title: "Success",
                        variant: "success",
                    });
                }
                if('norefresh' in payload && payload.norefresh){
                    return;
                }
                dispatch(COMPANY_GET_INVITES, payload.CompanyId);
                dispatch(COMPANY_GET_EMPLOYEES, payload.CompanyId);
            })
            .catch(error => {
                // dispatch(COMPANY_ERROR, error);
            });
    },

    [COMPANY_ERROR]: ({
        commit
    }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger"
        });
        commit(COMPANY_ERROR, error);
    }
};

const mutations = {
    [COMPANY_REQUEST]: state => {
        state.status = STATUS_COMPANY_REQUEST;
    },
    [COMPANY_SUCCESS]: state => {
        state.status = STATUS_COMPANY_SUCCESS;
        state.requestErrors = "";
    },
    [COMPANY_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_COMPANY_ERROR;
    },
    [COMPANIES_FILL]: (state, companies) => {
        state.companies = companies
            .filter(item => {
                const roles = item.Roles.filter(
                    (item) => !definedKeyRolesIdsArray().includes(item.Type)
                );
                return roles.length > 0
            })
            .map(item => {
                return {
                    ...item.Company,
                    ...{
                        Role: item.Roles.find(
                            (item) => !definedKeyRolesIdsArray().includes(item.Type)
                        ),
                        __type: "Company"
                    }
                };
            });
    },
    [COMPANY_FILL_EMPLOYEES]: (state, employees) => {
        let filteredRoles = employees.filter(item => {
            if (!item.Role) {
                return false;
            }
            if (
                (item.Role.Name == "Owner" &&
                    item.Role.CompanyId &&
                    !item.Role.Location) ||
                (item.Role.Name != "Owner" && item.Role.Name != "LocationOwner")
            ) {
                return true;
            }
            return false;
        });
        state.employees = filteredRoles;
    },
    [COMPANY_FILL_INVITES]: (state, invites) => {
        let filteredInvites = invites.filter(item => item.Status == 0);
        state.invites = filteredInvites;
    },
    [COMPANIES_CLEAR]: state => {
        state.companies = [];
    },
    [COMPANY_SET_ACTIVE]: (state, company) => {
        state.activeCompany = {
            ...company,
            ...{
                __type: "Company"
            }
        };
    },
    [COMPANY_REFRESH_ACTIVE]: state => {
        let id = state.activeCompany.Id;
        state.activeCompany = state.companies.find(company => company.Id == id);
    },
    [COMPANY_CLEAR_ACTIVE]: state => {
        state.activeCompany = null;
    },
    [COMPANY_STATE_RESET]: state => {
        state = {
            ...getDefaultState()
        };
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
