var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('keys.sharing_options'),"label-cols-sm":"2","label-align-sm":"right","label-size":"sm"}},[_c('b-form-radio-group',{staticClass:"w-100",attrs:{"id":"keyType","size":"sm","options":_vm.keyTypesOptions,"buttons":"","button-variant":"outline-primary","name":"keyType-default"},on:{"input":_vm.shareTypeChanged},model:{value:(_vm.shareOptions.keyCalendarType),callback:function ($$v) {_vm.$set(_vm.shareOptions, "keyCalendarType", $$v)},expression:"shareOptions.keyCalendarType"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.sharing_ability'),"label-cols-sm":"2","label-align-sm":"right","label-size":"sm"}},[_c('b-form-radio-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.focus.bottom",value:({
                content: _vm.$t('keys.sharing_warning_text'),
                disabled:
                    _vm.shareOptions.keyCalendarType == _vm.ROLE_KEY_PERMANENT,
            }),expression:"{\n                content: $t('keys.sharing_warning_text'),\n                disabled:\n                    shareOptions.keyCalendarType == ROLE_KEY_PERMANENT,\n            }",modifiers:{"hover":true,"focus":true,"bottom":true}}],staticClass:"w-100",attrs:{"id":"RoleType","size":"sm","options":_vm.keySharingTypesOptions,"buttons":"","disabled":_vm.shareOptions.keyCalendarType !== _vm.ROLE_KEY_PERMANENT,"button-variant":"outline-primary","name":"RoleType-default"},on:{"input":function($event){return _vm.updateOptions()}},model:{value:(_vm.shareOptions.mayShare),callback:function ($$v) {_vm.$set(_vm.shareOptions, "mayShare", $$v)},expression:"shareOptions.mayShare"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.shareOptions.keyCalendarType != _vm.ROLE_KEY_PERMANENT),expression:"shareOptions.keyCalendarType != ROLE_KEY_PERMANENT"}],attrs:{"label":_vm.$t('keys.time_settings'),"label-cols-sm":"2","label-align-sm":"right","label-size":"sm"}},[_c('b-card',{staticClass:"text-center",attrs:{"aria-hidden":"true","bg-variant":"light"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.showDays),callback:function ($$v) {_vm.showDays=$$v},expression:"showDays"}},[_vm._v(_vm._s(_vm.$t("keys.type_recurring")))])],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.access_from'),"label-cols-sm":"2","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',[_c('b-row',{staticClass:"w-100"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-datepicker',{ref:"dateFromPicker",staticStyle:{"width":"auto !important"},attrs:{"id":"accessStart","value-as-date":"","size":"sm","min":_vm.minDate,"locale":_vm.$i18n.locale,"date-format-options":{
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                }},on:{"input":_vm.checkDateValidity},model:{value:(_vm.shareOptions.dateFrom),callback:function ($$v) {_vm.$set(_vm.shareOptions, "dateFrom", $$v)},expression:"shareOptions.dateFrom"}})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-timepicker',{attrs:{"size":"sm"},on:{"input":function($event){return _vm.updateOptions()}},model:{value:(_vm.shareOptions.timeFrom),callback:function ($$v) {_vm.$set(_vm.shareOptions, "timeFrom", $$v)},expression:"shareOptions.timeFrom"}})],1)],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.access_to'),"label-cols-sm":"2","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',[_c('b-row',{staticClass:"w-100"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-datepicker',{staticStyle:{"width":"auto !important"},attrs:{"id":"accessEnd","size":"sm","value-as-date":"","min":_vm.minDate,"locale":_vm.$i18n.locale,"date-format-options":{
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                }},on:{"input":function($event){return _vm.updateOptions()}},model:{value:(_vm.shareOptions.dateTo),callback:function ($$v) {_vm.$set(_vm.shareOptions, "dateTo", $$v)},expression:"shareOptions.dateTo"}})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-timepicker',{attrs:{"size":"sm"},on:{"input":function($event){return _vm.updateOptions()}},model:{value:(_vm.shareOptions.timeTo),callback:function ($$v) {_vm.$set(_vm.shareOptions, "timeTo", $$v)},expression:"shareOptions.timeTo"}})],1)],1)],1)],1),(
                    _vm.showDays &&
                    _vm.shareOptions.keyCalendarType == _vm.ROLE_KEY_TEMPORARY
                )?_c('b-row',{staticClass:"d-flex h2 p-2 justify-content-center"},[_c('b-list-group',{staticClass:"w-100",attrs:{"horizontal":""}},_vm._l((_vm.shareOptions.weekDays),function(day,index){return _c('b-list-group-item',{key:day.DayNumber,staticClass:"p-0 w-1-of-7"},[_c('b-button',{staticClass:"mx-1 btn-md px-0 w-100",attrs:{"squared":"","variant":day.active ? 'primary' : 'secondary'},on:{"click":function($event){day.active = !day.active;
                                _vm.updateOptions();},"input":function($event){return _vm.updateOptions()}}},[_vm._v(_vm._s(_vm.dayName(day.DayNumber)))]),_c('b-list-group',{staticClass:"mx-1 px-0 w-100",attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"p-0 rounded-0",attrs:{"variant":day.active ? 'primary' : 'secondary'},on:{"click":_vm.updateOptions}},[(day.active)?_c('flat-pickr',{staticClass:"form-control form-control-sm",staticStyle:{"display":"block !important"},attrs:{"config":_vm.timeConfig},on:{"on-close":function($event){return _vm.validateTime(index)}},model:{value:(day.timeFrom),callback:function ($$v) {_vm.$set(day, "timeFrom", $$v)},expression:"day.timeFrom"}}):_vm._e()],1),_c('b-list-group-item',{staticClass:"p-0 rounded-0",attrs:{"variant":day.active ? 'primary' : 'secondary'}},[(day.active)?_c('flat-pickr',{staticClass:"form-control form-control-sm",staticStyle:{"display":"block !important"},attrs:{"config":_vm.timeConfig},on:{"on-close":function($event){return _vm.validateTime(index)}},model:{value:(day.timeTo),callback:function ($$v) {_vm.$set(day, "timeTo", $$v)},expression:"day.timeTo"}}):_vm._e()],1)],1)],1)}),1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }