/* jshint esversion: 2015 */
import axios from "axios";

export default {
    createSaltoInstallation(salto) {
        return window.axios.post("SaltoInstallation", salto);
    },

    getSaltoInstallation() {
        return window.axios.get('/SaltoInstallation');
    },

    getSaltoInstallationById(saltoInstallationId) {
        return window.axios.get("SaltoInstallation/" + saltoInstallationId);
    },

    patchSaltoInstallation(patch) {
        return window.axios.put("SaltoInstallation/" + patch.SaltoInstallationId, patch);
    },

    deleteSaltoInstallation(saltoInstallationId) {
        return window.axios.delete("SaltoInstallation/" + saltoInstallationId);
    },

    createSaltoOnlineDevice(device) {
        return window.axios.post("SaltoOnline", device);
    },

    getSaltoOnlineDevices() {
        return window.axios.get('/SaltoOnline');
    },

    getSaltoRelays(saltoInstallationId) {
        return window.axios.get(`/salto/relays/${saltoInstallationId}`);
    },

    getSaltoOnlineDevicesById(deviceId) {
        return window.axios.get("SaltoOnline/" + deviceId);
    },

    getAllSaltoCompanies(){
        return window.axios.get('/SaltoInstallation/companies');
    },

    getAllSaltoLocations(){
        return window.axios.get('/SaltoInstallation/locations');
    },

};
