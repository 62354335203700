/* jshint esversion: 2015 */
import axios from "axios";

export default {
    getSuperAdmin(){
        return window.axios.get('user/superadmin');
    },

    getAllUsers() {
        return window.axios.get('admin/SuperAdmin/users');
    },

    getAllRegisteredUsers(){
        return window.axios.get('admin/SuperAdmin/user');
    },

    getAllDevices() {
        return window.axios.get('admin/SuperAdmin/devices');
    },

    postAdminDevice(device) {
        return window.axios.post("admin/SuperAdmin/device", device);
    },

    patchAdminDevice(patch) {
        return window.axios.put("admin/SuperAdmin/device/" + patch.Id, patch);
    },

    deleteAdminDevice(deviceId) {
        return window.axios.delete("admin/SuperAdmin/device/" + deviceId);
    },

    patchAdminDeviceCode(deviceId, doorcode) {
        return window.axios.put("admin/SuperAdmin/device/" + deviceId + "/doorcode/" + doorcode);
    },

    postAdminKey(payload) {
        return window.axios.post("admin/SuperAdmin/key", payload);
    },

    getAllKeys() {
        return window.axios.get('admin/SuperAdmin/keys');
    },

    patchAdminKey(patch) {
        return window.axios.put("admin/SuperAdmin/key/" + patch.Id, patch);
    },

    deleteAdminKey(keyId) {
        return window.axios.delete("admin/SuperAdmin/key/" + keyId);
    },

    addAdminKeyDevices(keyId, payload) {
        return window.axios.post(`admin/SuperAdmin/key/${keyId}/device`, {
            DeviceIds: payload
        });
    },

    postAdminCompany(company) {
        return window.axios.post("admin/SuperAdmin/company", company);
    },

    getAllAdminCompanies() {
        return window.axios.get('admin/SuperAdmin/companies');
    },

    patchAdminCompany(companyId, patch) {
        return window.axios.put(`admin/SuperAdmin/company/${companyId}`, patch);
    },

    deleteAdminCompany(companyId) {
        return window.axios.delete(`admin/SuperAdmin/company/${companyId}`);
    },

    postAdminLocation(location) {
        return window.axios.post(`admin/SuperAdmin/location`, location);
    },

    getAllLocations() {
        return window.axios.get('admin/SuperAdmin/locations');
    },

    getAllUsersLocations(){
        return window.axios.get('admin/SuperAdmin/user/locations');
    },

    patchAdminLocation(locationId, patch) {
        return window.axios.put(`admin/SuperAdmin/location/${locationId}`, patch);
    },

    deleteAdminLocation(locationId) {
        return window.axios.delete(`admin/SuperAdmin/location/${locationId}`);
    },

    deleteLocationUserAdmin(locationId, userId) {
        return window.axios.delete(`admin/SuperAdmin/location/${locationId}/user/${userId}`);
    },

    deleteCompanyUserAdmin(payload) {
        return window.axios.delete(`admin/SuperAdmin/company/${payload.Company.Id}/user/${payload.User.Id}`);
    },

    getAllStaff() {
        return window.axios.get('admin/SuperAdmin/staff');
    },

    /* getAllAdminActivities() {
        return window.axios.get('admin/SuperAdmin/activity');
    }, */

    getAllAdminActivities(logOptions) {
        return window.axios.get(`admin/SuperAdmin/activity`, {
            params: logOptions,
        });
    },

    getAllAdminActivitiesPg(logOptions) {
        return window.axios.get(`admin/SuperAdmin/activity/pg`, {
            params: logOptions,
        });
    },

    getExportAllAdminActivities(logOptions) {
        return window.axios.get(`admin/SuperAdmin/activity/export`, {
            params: logOptions,
        });
    },

    postCompanyRoleAdminInvite(invite) {
        return window.axios.post(`admin/SuperAdmin/staff/invite`, invite);
    },

    getCompanyAdminInvites(companyId) {
        return window.axios.get(`admin/SuperAdmin/staff/${companyId}/invite`);
    },

    sendMemberListAdminInvite(invitesList) {
        return window.axios.post("admin/SuperAdmin/staff/invite/list", { Invites: invitesList });
    },

    getAllKeyCompanies(){
        return window.axios.get('admin/SuperAdmin/key/companies');
    },

    getAllKeyLocations(){
        return window.axios.get('admin/SuperAdmin/key/locations');
    },

    getAllKeyDevices(){
        return window.axios.get('admin/SuperAdmin/key/devices');
    },

    getCompanyStaff() {
        return window.axios.get('admin/SuperAdmin/staff/companies');
    },

    getUniqueKey() {
        return window.axios.get('admin/SuperAdmin/key/device');
    },

    getDisconnectedDoors(locationId, keyId) {
        return window.axios.get('admin/SuperAdmin/location/'+locationId+'/key/'+keyId+'/devices');
    },

    patchTransferRights(patch) {
        return window.axios.put(`admin/SuperAdmin/company/`+patch.companyId+`/transferrights`, patch);
    },

    addMultipleDevices(keyId, patch){
        return window.axios.post('admin/SuperAdmin/key/'+keyId+'/connectdevices', {
            DeviceId: patch
        });
    },

    disconnectMultipleDevices(keyId, patch){
        return window.axios.put('admin/SuperAdmin/key/'+keyId+'/disconnectdevices', {
            DeviceId: patch
        });
    },

    diconnectUser(patch){
        return window.axios.put('admin/SuperAdmin/DeleteUser', patch);
    },

    disconnectMultipleUsers(locationId, patch){
        return window.axios.put('admin/SuperAdmin/deleteuserlist/'+locationId, {
            UserId: patch
        });
    },
};
