import LocationController from "@/api/location";

import {
    LOCATION_REQUEST,
    LOCATION_ERROR,
    LOCATION_SUCCESS,
    LOCATIONS_GET_COMPANY_LOCATIONS,
    LOCATIONS_GET_ALL_LOCATIONS,
    LOCATIONS_GET_ALL_LOC,
    LOCATIONS_CLEAR,
    LOCATIONS_FILL,
    LOCATIONS_COMPANY_FILL,
    LOCATION_SET_ACTIVE,
    LOCATION_CLEAR_ACTIVE,
    LOCATION_GET,
    LOCATION_DELETE,
    LOCATION_USER_DELETE,
    LOCATION_PATCH,
    LOCATION_CREATE,
    LOCATION_STATE_RESET,
    LOCATION_GET_USERS,
    LOCATION_USERS_FILL,
} from "@/store/types/locations";

import {
    USER_GET_ROLES
} from "@/store/types/user";

import {
    STATUS_LOCATION_REQUEST,
    STATUS_LOCATION_SUCCESS,
    STATUS_LOCATION_ERROR,
} from "@/store/types/statuses";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const getDefaultState = () => {
    return {
        locations: [],
        companyLocations: [],
        status: "",
        activeLocation: null,
        activeLocationUsers: [],
        requestErrors: "",
    };
};

const state = getDefaultState();

const getters = {
    getCompanyLocations: (state) => state.companyLocations,
    getLocationsList: (state) => state.locations,
    getActiveLocation: (state) => state.activeLocation,
    getActiveLocationUsers: (state) => state.activeLocationUsers,
    getLocationRequestErrors: (state) => state.requestErrors,
    isLocationRequestOn: (state) => state.status == STATUS_LOCATION_REQUEST,
    getLocationCompanyId: (state) => (id) => {
        let location = state.locations.find((item) => item.Id == id);
        return location ? location.CompanyId : false;
    },
};

const actions = {
    [LOCATIONS_GET_COMPANY_LOCATIONS]: ({
        commit,
        dispatch
    }, companyId) => {
        commit(LOCATION_REQUEST);
        return LocationController.getCompanyLocations(companyId)
            .then((response) => {
                commit(LOCATION_SUCCESS);
                commit(LOCATIONS_COMPANY_FILL, response.data);
            })
            .catch((error) => {
                dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATIONS_GET_ALL_LOCATIONS]: ({
        commit,
        dispatch
    }) => {
        commit(LOCATION_REQUEST);
        return LocationController.getAllLocations()
            .then((response) => {
                commit(LOCATION_SUCCESS);
                commit(
                    LOCATIONS_FILL,
                    response.data
                    .map((item) => {
                        return {
                            ...item.Location,
                            ...{
                                Role: item.Role,
                                IsOwner:
                                    !item.Role &&
                                    item.Location.OwnerId == item.User.Id,
                            },
                        };
                    })
                    .filter(
                        item => item.CompanyId && item.Role && item.Role?.Name != 'LocationOwner'
                    )
                );
            })
            .catch((error) => {
                dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATIONS_GET_ALL_LOC]: ({
        commit,
        dispatch
    }) => {
        commit(LOCATION_REQUEST);
        return LocationController.getAllCompanyLocations()
            .then((response) => {
                commit(LOCATION_SUCCESS);
                commit(LOCATIONS_COMPANY_FILL, response.data);
            })
            .catch((error) => {
                dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATION_GET]: ({
        commit,
        dispatch
    }, locationId) => {
        commit(LOCATION_REQUEST);
        return LocationController.getLocation(locationId)
            .then((response) => {
                commit(LOCATION_SUCCESS);
                commit(LOCATION_SET_ACTIVE, response.data);
                return {
                    ...response.data,
                    ...{
                        __type: "Location",
                    },
                };
            })
            .catch((error) => {
                dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATION_CREATE]: ({
        commit,
        dispatch
    }, location) => {
        commit(LOCATION_REQUEST);
        LocationController.postLocation(location)
            .then((response) => {
                commit(LOCATION_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Location created",
                    title: "Success",
                    variant: "success",
                });
                dispatch(USER_GET_ROLES);
                dispatch(LOCATIONS_GET_ALL_LOCATIONS);
                dispatch(LOCATIONS_GET_COMPANY_LOCATIONS, location.CompanyId);
            })
            .catch((error) => {
                dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATION_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(LOCATION_REQUEST);
        return LocationController.patchLocation(patch.Id, patch)
            .then(() => {
                commit(LOCATION_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Location updated",
                    title: "Success",
                    variant: "success",
                });
                dispatch(LOCATIONS_GET_ALL_LOCATIONS);
                dispatch(LOCATIONS_GET_COMPANY_LOCATIONS,patch.CompanyId)
                dispatch(LOCATION_GET, patch.Id);
            })
            .catch((error) => {
                dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATION_DELETE]: ({
        commit,
        dispatch
    }, location) => {
        commit(LOCATION_REQUEST);
        return LocationController.deleteLocation(location.Id)
            .then(() => {
                commit(LOCATION_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Location deleted",
                    title: "Success",
                    variant: "success",
                });
                dispatch(LOCATIONS_GET_ALL_LOCATIONS);
            })
            .catch((error) => {
                dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATION_USER_DELETE]: ({
        commit,
        dispatch
    }, payload) => {
        commit(LOCATION_REQUEST);
        return LocationController.deleteLocationUser(payload)
            .then(() => {
                commit(LOCATION_SUCCESS);
                if(payload.silent !== true) {
                    commit(SHOW_NOTIFY, {
                        text: "User removed",
                        title: "Success",
                        variant: "success",
                    });
                }
            })
            .catch((error) => {
                // dispatch(LOCATION_ERROR, error);
            });
    },

    [LOCATION_ERROR]: ({
        commit
    }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger",
        });
        commit(LOCATION_ERROR, error);
    },


    [LOCATION_GET_USERS]: ({
        commit,
        dispatch
    }, locationId) => {
        return LocationController.getLocationUsers(locationId)
            .then((response) => {
                commit(LOCATION_USERS_FILL, response.data);
            })
            .catch((error) => {
                //console.log(error);
            });
    },
};

const mutations = {
    [LOCATION_REQUEST]: (state) => {
        state.status = STATUS_LOCATION_REQUEST;
    },
    [LOCATION_SUCCESS]: (state) => {
        state.status = STATUS_LOCATION_SUCCESS;
        state.requestErrors = "";
    },
    [LOCATION_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_LOCATION_ERROR;
    },
    [LOCATIONS_FILL]: (state, locations) => {
        let mapped = locations.map((item) => {
            return {
                ...item,
                ...{
                    __type: "Location",
                },
            };
        });
        state.locations = mapped;
    },
    [LOCATIONS_COMPANY_FILL]: (state, locations) => {
        let mapped = locations.map((item) => {
            return {
                ...item,
                ...{
                    __type: "Location",
                },
            };
        });
        state.companyLocations = mapped;
    },
    [LOCATIONS_CLEAR]: (state) => {
        state.locations = [];
    },
    [LOCATION_SET_ACTIVE]: (state, location) => {
        state.activeLocation = {
            ...location,
            ...{
                __type: "Location",
            },
        };
    },
    [LOCATION_CLEAR_ACTIVE]: (state) => {
        state.activeLocation = null;
    },
    [LOCATION_STATE_RESET]: (state) => {
        state = {
            ...getDefaultState(),
        };
    },
    [LOCATION_USERS_FILL]: (state, users) => {
        state.activeLocationUsers = users;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
