export default {
    logout() {
        return window.axios({
            url: `auth/logout`,
            method: "POST"
        });
    },
    register(payload) {
        return window.axios({
            url: "auth/register",
            data: payload,
            method: "POST"
        });
    },
    registerUser(payload) {
        return window.axios({
            url: "auth/registercheck",
            data: payload,
            method: "POST"
        });
    },
    login(payload) {
        return window.axios({
            url: "auth/login-by-phone",
            data: payload,
            method: "POST"
        });
    },
    refresh(payload) {
        return window.axios({
            url: "auth/refresh",
            data: payload,
            method: "POST"
        });
    },
    requestCode(payload) {
        return window.axios({
            url: "auth/request-code",
            data: payload,
            method: "POST"
        });
    },
    submitCode(payload) {
        return window.axios({
            url: "auth/submit-login",
            data: payload,
            method: "POST"
        });
    },
    resetPassword(payload) {
        return window.axios({
            url: "auth/submit-reset",
            data: payload,
            method: "POST"
        });
    }
};
