import {
    AbilityBuilder,
    Ability
} from "@casl/ability";
import {
    definedRoles,
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_LOCATION_MANAGER,
    ROLE_KEY_MANAGER,
    // ROLE_SUPER_ADMIN,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY
} from "./roles";

const {
    can,
    cannot,
    rules
} = new AbilityBuilder();

const typeDetector = objectToDetect => {
    return typeof objectToDetect === "string" ?
        objectToDetect :
        objectToDetect.__type;
};

export const defaultAbility = new Ability([], {
    detectSubjectType: typeDetector
});

const buildOwnerRole = role => {
    can(["company-manage", "edit", "delete", "staff-manage", "locations-list", "locations-add"], "Company", {
        Id: role.Company.Id
    });
    can(["manage", "edit", "delete"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewActivity"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewUsers", "manageUsers"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["createKeys", "manageKey", "shareKey", "viewKeys"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["createDoors", "manageDoors"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewStaff"], "Company", {
        Id: role.Company.Id
    });
};

const buildSuperAdminRole = role => {
    can(["company-manage", "edit", "delete", "staff-manage", "locations-list", "locations-add"], "Company", {
        Id: role.Company.Id
    });
    can(["manage", "edit", "delete"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewActivity"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewUsers", "manageUsers"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["createKeys", "manageKey", "shareKey", "viewKeys"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["createDoors", "manageDoors"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewStaff"], "Company", {
        Id: role.Company.Id
    });
};

const buildCompanyManagerRole = role => {
    can(["company-manage", "edit", "staff-manage", "locations-list", "locations-add"], "Company", {
        Id: role.Company.Id
    });
    can(["manage", "edit", "delete"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewActivity"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewUsers", "manageUsers"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["createKeys", "manageKey", "shareKey", "viewKeys"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["createDoors", "manageDoors", "viewDoors"], "Location", {
        CompanyId: role.Company.Id
    });
    can(["viewStaff", "admin"], "Company", {
        Id: role.Company.Id
    });
};

const buildLocationManagerRole = role => {
    // can(["manage", "edit"], "Location", {
    //     Id: role.Location.Id
    // });
    can(["viewActivity", "viewDoors"], "Location", {
        Id: role.Location.Id
    });
    can(["viewUsers", "manageUsers"], "Location", {
        Id: role.Location.Id
    });
    can(["createKeys", "manageKey", "shareKey", "viewKeys"], "Location", {
        Id: role.Location.Id
    });
    can(["createDoors", "manageDoors"], "Location", {
        Id: role.Location.Id
    });
    can(["viewStaff", "location-staff-manage"], "Location", {
        Id: role.Location.Id
    });
    can(["locations-list"], "Company", {
        Id: role.Company.Id
    });
};

const buildInvitationAbilities = role => {
    can(["invite", "cancel"], "Role", {
        level: {
            $gt: definedRoles[role.Name].level
        }
    });
};

const buildDeviceInstallerRole = role => {
    can(["viewDoors"], "Location", {
        Id: role.Location.Id
    });
    can(["createKeys"], "Location", {
        Id: role.Location.Id
    });
    can(["createDoors", "manageDoors"], "Location", {
        Id: role.Location.Id
    });    
    /* can(["viewUsers"], "Location", {
        CompanyId: role.Company.Id
    }); */
    // cannot(["viewUsers", "viewActivity"], "Option");
};

const buildKeyManagerRole = role => {
    can(["createKeys", "manageKey", "shareKey", "viewDoors", "viewUsers", "viewKeys"], "Location", {
        Id: role.Location.Id
    });
    can(["locations-list"], "Company", {
        Id: role.Company.Id
    });
    can(["location-staff-manage"], "Location", {
        Id: role.Location.Id
    });
    can(["viewUsers", "manageUsers"], "Location", {
        Id: role.Location.Id
    });
};

export const defineAbilityFor = userRoles => {
    if (userRoles.length) {
        userRoles.forEach(role => {
            switch (role.Name) {
                case ROLE_COMPANY_OWNER:
                    buildOwnerRole(role);
                    break;
                case ROLE_COMPANY_MANAGER:
                    buildCompanyManagerRole(role);
                    break;
                case ROLE_LOCATION_MANAGER:
                    buildLocationManagerRole(role);
                    break;
                case ROLE_DEVICE_INSTALLER:
                    buildDeviceInstallerRole(role);
                    break;
                case ROLE_KEY_MANAGER:
                    buildKeyManagerRole(role);
                    break;
                /* case ROLE_SUPER_ADMIN:
                    buildSuperAdminRole(role); */
            }
            buildInvitationAbilities(role);
        });
    }

    return rules;
};

// export const defineAbilityFor = userRoles =>
//     new Ability(
//         defineRulesFor(userRoles), {
//             detectSubjectType: typeDetector
//         }
//     );
