<template>
    <div>
        <transition :name="transition" :mode="mode">
            <slot></slot>
        </transition>
        <div class="overlay-top"></div>
        <div class="overlay-right"></div>
        <div class="overlay-bottom"></div>
        <div class="overlay-left"></div>
    </div>
</template>

<script>
export default {
    name: "vue-page-transition",
    props: ["name"],
    data() {
        return {
            transition: "fade",
            mode: "out-in",
        };
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            this.transition = to.meta.transition
                ? to.meta.transition
                : this.$props.name;

            next();
        });
    },
};
</script>

<style>
:root {
    --overlay-bg: #1867c0;
    --transition-duration: 0.35s;
}
</style>

<style lang="scss" scoped>
// fade
@import "../styles/transitions/fade/fade";
</style>
