<template>
    <div class="avatar-cropper">
        <div class="avatar-cropper-overlay" v-if="dataUrl">
            <div class="avatar-cropper-container">
                <div class="avatar-cropper-image-container">
                    <img
                        :src="dataUrl"
                        @load.stop="createCropper"
                        alt
                        ref="img"
                    />
                </div>
                <div class="avatar-cropper-footer">
                   <!--  v-text="labels.cancel" -->
                    <button
                        @click.stop.prevent="destroy"
                        class="avatar-cropper-btn"
                        id="cancelBttn"
                    >
                        {{ $t("buttons.cancel") }}
                    </button>
                   <!--  v-text="labels.submit" -->
                    <button
                        @click.stop.prevent="submit"
                        class="avatar-cropper-btn"
                        id="submitBttn"
                        :disabled="!submitDisable"
                    >
                        {{ $t("buttons.submit") }}
                    </button>
                </div>
                <div v-if="imageSizeError">
                    <!-- <b-alert show variant="light">
                        <b-icon
                            icon="exclamation-circle"
                            variant="danger"
                        ></b-icon>
                        {{ $t("errors.image_size") }}
                    </b-alert> -->
                        <table style="border-collapse: separate; border-spacing: 0 25px">
                        <tr>
                            <td style="padding-right: 14px; padding-left: 35px; vertical-align: top">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                <!-- <img id="infoIcon" style="width: 21px; height: 21px; line-height: 1.5" :src="'/images/info_grey_24x24dp.png'" /> -->
                            </td>
                            <td
                                style="
                                    text-align: left !important;
                                    padding-right: 30px;
                                    line-height: 1 !important;
                                    color: #e63857;
                                "
                            >{{ $t("errors.image_size") }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <input
            :accept="mimes"
            class="avatar-cropper-img-input"
            ref="input"
            type="file"
        />
    </div>
</template>

<script>
//https://github.com/overtrue/vue-avatar-cropper/tree/master/src
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

export default {
    props: {
        trigger: {
            type: [String, Element],
            required: true,
        },
        uploadHandler: {
            type: Function,
        },
        uploadUrl: {
            type: String,
            default: `${process.env.VUE_APP_API_URL}/file/image`,
        },
        requestMethod: {
            type: String,
            default: "POST",
        },
        uploadHeaders: {
            type: Object,
            default() {
                return {
                    Authorization: `Bearer ${this.$cookies.get(
                        "Authorization"
                    )}`,
                };
            },
        },
        uploadFormName: {
            type: String,
            default: "Body",
        },
        uploadFormData: {
            type: Object,
            default() {
                return { Type: 2 };
            },
        },
        cropperOptions: {
            type: Object,
            default() {
                return {
                    aspectRatio: 1,
                    autoCropArea: 1,
                    dragMode: "move",
                    viewMode: 0,
                    movable: true,
                    zoomable: true,
                };
            },
        },
        outputOptions: {
            type: Object,
        },
        outputMime: {
            type: String,
            default: null,
        },
        outputQuality: {
            type: Number,
            default: 0.9,
        },
        mimes: {
            type: String,
            default:
                "image/png, image/gif, image/jpeg, image/bmp, image/x-icon",
        },
        labels: {
            type: Object,
            default() {
                return {
                    submit: this.$t("buttons.submit"),
                    cancel: this.$t("buttons.cancel"),
                };
            },
        },
        withCredentials: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            cropper: undefined,
            dataUrl: undefined,
            filename: undefined,
            imageSizeError: false,
            submitDisable: true,
        };
    },
    methods: {
        destroy() {
            this.$emit("destroy");
            this.cropper.destroy();
            this.$refs.input.value = "";
            this.dataUrl = undefined;
            this.imageSizeError = false;
            this.submitDisable = true;
        },
        submit() {
            this.$emit("submit");
            if (this.uploadUrl) {
                this.uploadImage();
            } else if (this.uploadHandler) {
                this.uploadHandler(this.cropper);
            } else {
                this.$emit("error", "No upload handler found.", "user");
            }
            this.destroy();
        },
        pickImage(e) {
            this.$refs.input.click();
            e.preventDefault();
            e.stopPropagation();
        },
        createCropper() {
            this.cropper = new Cropper(this.$refs.img, this.cropperOptions);
        },
        uploadImage() {
            this.cropper.getCroppedCanvas(this.outputOptions).toBlob(
                (blob) => {
                    let form = new FormData();
                    let xhr = new XMLHttpRequest();
                    let data = Object.assign({}, this.uploadFormData);

                    xhr.withCredentials = this.withCredentials;

                    for (let key in data) {
                        form.append(key, data[key]);
                    }

                    if(blob.size > 29 * 1024 * 1024){
                        let text = this.$t("notify.blob_error")
                        this.$bvToast.toast(text, {
                            title: this.$t("notify.error"),
                            variant: " __danger",
                            autoHideDelay: 2000,
                        });
                        return;
                    }
                    form.append(this.uploadFormName, blob, this.filename);

                    this.$emit("uploading", form, xhr);

                    xhr.open(this.requestMethod, this.uploadUrl, true);

                    for (let header in this.uploadHeaders) {
                        xhr.setRequestHeader(
                            header,
                            this.uploadHeaders[header]
                        );
                    }

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            let response = "";
                            try {
                                response = JSON.parse(xhr.responseText);
                            } catch (err) {
                                response = xhr.responseText;
                            }
                            this.$emit("completed", response, form, xhr);

                            if ([200, 201, 204].indexOf(xhr.status) > -1) {
                                this.$emit("uploaded", response, form, xhr);
                            } else {
                                this.$emit(
                                    "error",
                                    "Image upload fail.",
                                    "upload",
                                    xhr
                                );
                            }
                        }
                    };
                    xhr.send(form);
                },
                this.outputMime,
                this.outputQuality
            );
        },
    },
    mounted() {
        // listen for click event on trigger
        let trigger =
            typeof this.trigger == "object"
                ? this.trigger
                : document.querySelector(this.trigger);
        if (!trigger) {
            this.$emit("error", "No avatar make trigger found.", "user");
        } else {
            trigger.addEventListener("click", this.pickImage);
        }

        // listen for input file changes
        let fileInput = this.$refs.input;
        fileInput.addEventListener("change", () => {
            if (fileInput.files != null && fileInput.files[0] != null) {
                if (fileInput.files[0].size > 7 * 1024 * 1024) {
                    this.imageSizeError = true;
                    this.submitDisable = false;
                }

                let reader = new FileReader();
                reader.onload = (e) => {
                    this.dataUrl = e.target.result;
                };

                reader.readAsDataURL(fileInput.files[0]);
                this.filename = fileInput.files[0].name || "unknown";
                this.mimeType = this.mimeType || fileInput.files[0].type;
                this.$emit("changed", fileInput.files[0], reader);
            }
        });
    },
};
</script>

<style lang="scss">
.__danger .toast .toast-header {
    color: #e63857 !important;
    // background-color: #fff !important;
}
.avatar-cropper {
    .avatar-cropper-overlay {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;
    }

    .avatar-cropper-img-input {
        display: none;
    }

    .avatar-cropper-close {
        float: right;
        padding: 20px;
        font-size: 3rem;
        color: #fff;
        font-weight: 100;
        text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
    }

    .avatar-cropper-mark {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.1);
    }

    .avatar-cropper-container {
        background: #fff;
        z-index: 999;
        box-shadow: 1px 1px 5px rgba(100, 100, 100, 0.14);

        .avatar-cropper-image-container {
            position: relative;
            max-width: 400px;
            height: 300px;
        }
        img {
            max-width: 100%;
            height: 100%;
        }

        .avatar-cropper-footer {
            display: flex;
            align-items: stretch;
            align-content: stretch;
            justify-content: space-between;

            .avatar-cropper-btn {
                width: 50%;
                padding: 15px 0;
                cursor: pointer;
                border: none;
                background: transparent;
                outline: none;
                &:hover {
                    background-color: #3d3c3b;
                    color: #fff;
                }
            }
        }
    }
}
</style>
