/* jshint esversion: 2015 */
export default {
    postCompany(company) {
        return window.axios.post("b2b/company", company);
    },
    getCompany(companyId) {
        return window.axios.get(`b2b/company/${companyId}`);
    },
    getCompanies() {
        return window.axios.get("b2b/user/company");
    },
    getAllCompanies() {
        return window.axios.get("b2b/company/allCompany");
    },
    patchCompany(companyId, patch) {
        return window.axios.put(`b2b/company/${companyId}`, patch);
    },
    deleteCompany(companyId) {
        return window.axios.delete(`b2b/company/${companyId}`);
    },
    deleteCompanyUser(payload) {
        if (payload.silent === true)
            return window.axios.delete(`b2b/company/${payload.CompanyId}/user/${payload.Id}?silent=true`);
        else
            return window.axios.delete(`b2b/company/${payload.CompanyId}/user/${payload.Id}`);
    },
    getCompanyEmployees(companyId) {
        return window.axios.get(`/b2b/company/${companyId}/member`);
    },
    getAllCompanyEmployees() {
        return window.axios.get(`/b2b/company/member`);
    },
    getCompanyInvites(companyId) {
        return window.axios.get(`/b2b/company/${companyId}/invite`);
    },

};
