export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_ERROR_CLEAR = 'AUTH_ERROR_CLEAR'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REGISTER = 'AUTH_REGISTER'
export const REGISTER_USER = 'REGISTER_USER'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_REFRESH = 'AUTH_REFRESH'
export const AUTH_REQUEST_CODE = 'AUTH_REQUEST_CODE'
export const AUTH_SUBMIT_CODE = 'AUTH_SUBMIT_CODE'
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD'
