import AdminController from "../../api/admin";

import {    
    ADMIN_REQUEST,
    ADMIN_SUCCESS,
    ADMIN_ERROR,
    CHECK_ADMIN_USER,
    ADMIN_USERS_GET,    
    ADMIN_STAFF_GET,
    ADMIN_ACTIVITY_GET,
    ADMIN_ACTIVITY_FILL,
    ADMIN_USERS_FILL,   
    ADMIN_STAFF_FILL,
    ACL_INVITE_TO_COMPANY_ROLE_ADMIN,
    ADMIN_COMPANY_GET_INVITES,
    ADMIN_COMPANY_FILL_INVITES,
    ADMIN_KEY_CREATE,
    ADMIN_KEYS_GET,
    ADMIN_KEYS_FILL,    
    ADMIN_KEY_PATCH,
    ADMIN_KEY_DELETE,
    ADMIN_KEY_DEVICES_ADD,
    ADMIN_MEMBER_LIST_TO_KEY_INVITE,
    ADMIN_DEVICE_CREATE,
    ADMIN_DEVICES_GET,
    ADMIN_DEVICE_PATCH,
    ADMIN_DEVICE_CODE_PATCH,
    ADMIN_DEVICE_DELETE,
    ADMIN_DEVICE_CODE_ERROR,
    ADMIN_DEVICE_CODE_SUCCESS,
    ADMIN_DEVICES_FILL,
    ADMIN_COMPANIES_GET,
    ADMIN_COMPANY_CREATE,
    ADMIN_COMPANY_PATCH,
    ADMIN_COMPANY_DELETE,
    ADMIN_COMPANIES_FILL,
    ADMIN_LOCATIONS_GET,
    ADMIN_LOCATION_PATCH,
    ADMIN_LOCATION_DELETE,
    ADMIN_LOCATIONS_FILL, 
    ADMIN_LOCATION_CREATE,   
    ADMIN_LOCATION_USER_DELETE,
    ADMIN_COMPANY_USER_DELETE,
    ADMIN_KEY_COMPANIES_GET,
    ADMIN_KEY_LOCATIONS_GET,
    ADMIN_KEY_DEVICES_GET,
    ADMIN_COMPANY_STAFF_GET,
    ADMIN_KEY_COMPANY_FILL,
    ADMIN_KEY_LOCATION_FILL,
    ADMIN_KEY_DEVICE_FILL,
    ADMIN_USER_LOCATIONS_GET,
    ADMIN_USER_LOCATION_FILL,
    REGISTERED_USERS_GET,
    REGISTERED_USERS_FILL,
    ADMIN_COMPANY_STAFF_FILL,
    ADMIN_UNIQUE_KEY_GET,
    ADMIN_UNIQUE_KEY_FILL,
    ADMIN_DISCONNECTED_DEVICES,
    ADMIN_TRANSFER_RIGHTS,
    ADMIN_CONNECT_MULTIPLE_DEVICES,
    ADMIN_DISCONNECT_MULTIPLE_DEVICES,
    ADMIN_DISCONNECT_USER,
    ADMIN_MULTPLE_USER_DELETE,
} from "../types/admin";

import {
    STATUS_ADMIN_REQUEST,
    STATUS_ADMIN_SUCCESS,
    STATUS_ADMIN_ERROR,
    STATUS_ADMIN_DEVICE_CODE_SUCCESS,
    STATUS_ADMIN_DEVICE_CODE_ERROR,
} from "../types/statuses";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const state = {    
    adminUsers:[],
    registeredUsers: [],
    adminDevices: [],
    adminKeys: [],
    adminCompanies: [],
    adminLocations: [],
    adminStaff: [],
    adminActivity: [],
    keyCompanies: [],
    keyLocations: [],
    keyDevices: [],
    companyStaff: [],
    userLocations: [],
    uniqueKey: [],
    status: "",
    requestErrors: ""
};

const getters = {
    getAllUsers: (state) => state.adminUsers,
    getAllRegisteredUsers: (state) => state.registeredUsers,
    getAllDevices: (state) => state.adminDevices, 
    getAllKeys: (state) => state.adminKeys, 
    getAllAdminCompanies: (state) => state.adminCompanies,
    getAllLocations: (state) => state.adminLocations,
    getAllStaff: (state) => state.adminStaff,
    getAllActivity: (state) => state.adminActivity,
    getAdminRequestErrors: state => state.requestErrors,   
    getCompanyAdminInvites: state => state.invites,
    getSuperAdmin: state => state.admin,
    getAllKeyCompanies: (state) => state.keyCompanies,
    getAllKeyLocations: (state) => state.keyLocations,
    getAllKeyDevices: (state) => state.keyDevices,
    getCompanyStaff: (state) => state.companyStaff,
    getUniqueKey: (state) => state.uniqueKey,
    getAllUsersLocations: (state) => state.userLocations,
    getDisconnectedDoors: (state) => state.adminDevices, 
    isAdminRequestOn: state => state.status == STATUS_ADMIN_REQUEST
};

const actions = {   
    [CHECK_ADMIN_USER]: ({
        commit,
        dispatch
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getSuperAdmin()
    },

    [ADMIN_USERS_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllUsers()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_USERS_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [REGISTERED_USERS_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllRegisteredUsers()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(REGISTERED_USERS_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_DEVICES_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllDevices()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_DEVICES_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },  

    [ADMIN_DEVICE_CREATE]: ({
        commit,
        dispatch
    }, device) => {
        commit(ADMIN_REQUEST);
        return AdminController.postAdminDevice(device)
            .then((response) => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door created",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_DEVICES_GET);
                return response.data;
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_DEVICE_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(ADMIN_REQUEST);
        return AdminController.patchAdminDevice(patch)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_DEVICES_GET);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_DEVICE_DELETE]: ({
        commit,
        dispatch
    }, device) => {
        commit(ADMIN_REQUEST);
        return AdminController.deleteAdminDevice(device.Id)
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door deleted",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_DEVICES_GET);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_DEVICE_CODE_PATCH]: ({
        commit,
        dispatch
    }, {deviceId, doorcode}) => {
        commit(ADMIN_REQUEST);
        return AdminController.patchAdminDeviceCode(deviceId, doorcode)
            .then(() => {
                commit(ADMIN_SUCCESS);
            })
            .catch(error => {
                dispatch(ADMIN_DEVICE_CODE_ERROR, error);
            });
    },
    
    [ADMIN_KEYS_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllKeys()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_KEYS_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_KEY_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(ADMIN_REQUEST);
        return AdminController.patchAdminKey(patch)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Key updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_KEYS_GET);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_KEY_DELETE]: ({
        commit,
        dispatch
    }, keyId) => {
        commit(ADMIN_REQUEST);
        return AdminController.deleteAdminKey(keyId)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Key deleted",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_KEYS_GET);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_COMPANIES_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllAdminCompanies()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_COMPANIES_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_COMPANY_CREATE]: ({
        commit,
        dispatch
    }, company) => {
        commit(ADMIN_REQUEST);
        AdminController.postAdminCompany(company)
            .then((response) => {
                commit(SHOW_NOTIFY, {
                    text: "Company created",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_COMPANIES_GET);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_COMPANY_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(ADMIN_REQUEST);
        return AdminController.patchAdminCompany(patch.Id, patch)
            .then(response => {
                commit(SHOW_NOTIFY, {
                    text: "Company updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_COMPANIES_GET)
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_COMPANY_DELETE]: ({
        commit,
        dispatch
    }, companyId) => {
        commit(ADMIN_REQUEST);
        return AdminController.deleteAdminCompany(companyId)
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Company deleted",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_COMPANIES_GET)
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_LOCATIONS_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllLocations()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_LOCATIONS_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_LOCATION_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(ADMIN_REQUEST);
        return AdminController.patchAdminLocation(patch.Id, patch)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Location updated",
                    title: "Success",
                    variant: "success",
                });
                dispatch(ADMIN_LOCATIONS_GET);                
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_LOCATION_DELETE]: ({
        commit,
        dispatch
    }, location) => {
        commit(ADMIN_REQUEST);
        return AdminController.deleteAdminLocation(location.Id)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Location deleted",
                    title: "Success",
                    variant: "success",
                });
                dispatch(ADMIN_LOCATIONS_GET);
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_STAFF_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllStaff()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_STAFF_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_ACTIVITY_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllAdminActivities()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_ACTIVITY_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ACL_INVITE_TO_COMPANY_ROLE_ADMIN]: ({ commit, dispatch }, invite) => {
        commit(ADMIN_REQUEST);
        return AdminController.postCompanyRoleAdminInvite(invite)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite sent",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_STAFF_GET);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_COMPANY_GET_INVITES]: ({
        commit,
        dispatch
    }, companyId) => {
        commit(ADMIN_REQUEST);
        return AdminController.getCompanyAdminInvites(companyId)
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_COMPANY_FILL_INVITES, response.data);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },    

    [ADMIN_MEMBER_LIST_TO_KEY_INVITE]: (
        { commit, dispatch },
        { invitesList, locationId }
    ) => {
        commit(ADMIN_REQUEST);
        return AdminController.sendMemberListAdminInvite(invitesList)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite sent",
                    title: "Success",
                    variant: "success",
                });                
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_KEY_CREATE]: ({
        commit,
        dispatch
    }, key) => {
        commit(ADMIN_REQUEST);
        return AdminController.postAdminKey(key)
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Key created",
                    title: "Success",
                    variant: "success"
                });
                dispatch(ADMIN_KEYS_GET);
                return response;
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_KEY_DEVICES_ADD]: ({
        commit,
        dispatch
    }, payload) => {
        commit(ADMIN_REQUEST);
        return AdminController.addAdminKeyDevices(payload.keyId, payload.devicesIds)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door added to key",
                    title: "Success",
                    variant: "success"
                });
                // dispatch(ADMIN_KEYS_GET, payload.locationId);
                // dispatch(KEY_DEVICES_GET, payload.keyId);
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_LOCATION_CREATE]: ({
        commit,
        dispatch
    }, location) => {
        commit(ADMIN_REQUEST);
        AdminController.postAdminLocation(location)
            .then((response) => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Location created",
                    title: "Success",
                    variant: "success",
                });
                dispatch(ADMIN_LOCATIONS_GET);
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_LOCATION_USER_DELETE]: ({
        commit,
        dispatch
    }, {
        locationId,
        userId
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.deleteLocationUserAdmin(locationId, userId)
            .then((response) => {      
                    commit(ADMIN_SUCCESS);
                    commit(SHOW_NOTIFY, {
                        text: "User removed",
                        title: "Success",
                        variant: "success",
                    });        
                /* if(response.data.error == "Danalock authentication fail."){
                    commit(SHOW_NOTIFY, {
                        text: "Danalock authentication fail",
                        title: "Error",
                        variant: "danger"
                    });
                }else{
                    commit(ADMIN_SUCCESS);
                    * commit(SHOW_NOTIFY, {
                        text: "User removed",
                        title: "Success",
                        variant: "success",
                    }); *
                } */                
                dispatch(ADMIN_USERS_GET)
                dispatch(ADMIN_STAFF_GET)
            })
            .catch((error) => {
                // dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_COMPANY_USER_DELETE]: ({
        commit,
        dispatch
    }, payload) => {
        commit(ADMIN_REQUEST);
        return AdminController.deleteCompanyUserAdmin(payload)
            .then((response) => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "User removed",
                    title: "Success",
                    variant: "success",
                }); 
                /* if(response.data.error == "Danalock authentication fail."){
                    commit(SHOW_NOTIFY, {
                        text: "Danalock authentication fail",
                        title: "Error",
                        variant: "danger"
                    });
                }else{
                    commit(ADMIN_SUCCESS);
                    /* commit(SHOW_NOTIFY, {
                        text: "User removed",
                        title: "Success",
                        variant: "success",
                    }); *
                }  */
                if('norefresh' in payload && payload.norefresh){
                    return;
                }  
                dispatch(ADMIN_USERS_GET)
                dispatch(ADMIN_STAFF_GET)              
            })
            .catch(error => {
                // dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_KEY_COMPANIES_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllKeyCompanies()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_KEY_COMPANY_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_KEY_LOCATIONS_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllKeyLocations()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_KEY_LOCATION_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },
    
    [ADMIN_USER_LOCATIONS_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllUsersLocations()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_USER_LOCATION_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_KEY_DEVICES_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getAllKeyDevices()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_KEY_DEVICE_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },
    
    [ADMIN_COMPANY_STAFF_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getCompanyStaff()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_COMPANY_STAFF_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },
    
    [ADMIN_UNIQUE_KEY_GET]: ({
        commit,
        dispatch        
    }) => {
        commit(ADMIN_REQUEST);
        return AdminController.getUniqueKey()
            .then(response => {
                commit(ADMIN_SUCCESS);
                commit(ADMIN_UNIQUE_KEY_FILL, response.data);               
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_DISCONNECTED_DEVICES]: ({
        commit,
        dispatch        
    }, {locationId, keyId}) => {
        commit(ADMIN_REQUEST);
        return AdminController.getDisconnectedDoors(locationId, keyId)
            .then(response => {
                commit(ADMIN_SUCCESS);
                // commit(ADMIN_DEVICES_FILL, response.data); 
                // dispatch(ADMIN_STAFF_GET);   
                return response.data;           
            })
            .catch(error => {
                dispatch(ADMIN_ERROR, error);
            });
    },
    
    [ADMIN_TRANSFER_RIGHTS]: ({
        commit,
        dispatch
    }, patch) => {
        commit(ADMIN_REQUEST);
        return AdminController.patchTransferRights(patch)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Rights transfered",
                    title: "Success",
                    variant: "success",
                });
                dispatch(ADMIN_STAFF_GET);                
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_CONNECT_MULTIPLE_DEVICES]: ({
        commit,
        dispatch
    }, {keyId, patch}) => {
        commit(ADMIN_REQUEST);
        return AdminController.addMultipleDevices(keyId,patch)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Devices connected",
                    title: "Success",
                    variant: "success",
                });
                dispatch(ADMIN_KEYS_GET);                
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_DISCONNECT_MULTIPLE_DEVICES]: ({
        commit,
        dispatch
    }, {keyId, patch}) => {
        commit(ADMIN_REQUEST);
        return AdminController.disconnectMultipleDevices(keyId,patch)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Devices disconnected",
                    title: "Success",
                    variant: "success",
                });
                dispatch(ADMIN_KEYS_GET);                
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },
    
    [ADMIN_DISCONNECT_USER]: ({
        commit,
        dispatch
    }, patch) => {
        commit(ADMIN_REQUEST);
        return AdminController.diconnectUser(patch)
            .then(() => {
                commit(ADMIN_SUCCESS);

                if(patch.CompanyId != null){
                    commit(SHOW_NOTIFY, {
                        text: "User disconnected from company",
                        title: "Success",
                        variant: "success",
                    });
                }else if(patch.LocationId != null){
                    commit(SHOW_NOTIFY, {
                        text: "User disconnected from location",
                        title: "Success",
                        variant: "success",
                    });
                }else if(patch.DeviceId != null){
                    commit(SHOW_NOTIFY, {
                        text: "User disconnected from device",
                        title: "Success",
                        variant: "success",
                    });
                }else if(patch.KeyId != null){
                    commit(SHOW_NOTIFY, {
                        text: "User disconnected from key",
                        title: "Success",
                        variant: "success",
                    });
                }else if(patch.systemFlag){
                    commit(SHOW_NOTIFY, {
                        text: "User disconnected from system",
                        title: "Success",
                        variant: "success",
                    });
                }                
                dispatch(ADMIN_USERS_GET);                
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },
    
    [ADMIN_MULTPLE_USER_DELETE]: ({
        commit,
        dispatch
    }, {locationId, patch} ) => {
        commit(ADMIN_REQUEST);        
        return AdminController.disconnectMultipleUsers(locationId, patch)
            .then(() => {
                commit(ADMIN_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Users removed",
                    title: "Success",
                    variant: "success",
                });
                dispatch(ADMIN_USERS_GET);                
            })
            .catch((error) => {
                dispatch(ADMIN_ERROR, error);
            });
    },

    [ADMIN_ERROR]: ({
        commit
    }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger"
        });
        commit(ADMIN_ERROR, error);
    }
};

const mutations = {
    [ADMIN_REQUEST]: state => {
        state.status = STATUS_ADMIN_REQUEST;
    },    
    [ADMIN_SUCCESS]: state => {
        state.status = STATUS_ADMIN_SUCCESS;
        state.requestErrors = "";
    },
    [ADMIN_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_ADMIN_ERROR;
    },
    [ADMIN_DEVICE_CODE_SUCCESS]: state => {
        state.status = STATUS_ADMIN_DEVICE_CODE_SUCCESS;
        state.requestErrors = "";
    },
    [ADMIN_DEVICE_CODE_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_ADMIN_DEVICE_CODE_ERROR;
    },
    [ADMIN_USERS_FILL]: (state, adminUsers) => {
        state.adminUsers = adminUsers;
    },
    [REGISTERED_USERS_FILL]: (state, registeredUsers) => {
        state.registeredUsers = registeredUsers;
    },    
    [ADMIN_DEVICES_FILL]: (state, adminDevices) => {
        state.adminDevices = adminDevices;
    },
    [ADMIN_KEYS_FILL]: (state, adminKeys) => {
        state.adminKeys = adminKeys;
    },
    [ADMIN_COMPANIES_FILL]: (state, adminCompanies) => {
        state.adminCompanies = adminCompanies;
    },
    [ADMIN_COMPANY_STAFF_FILL]: (state, companyStaff) => {
        state.companyStaff = companyStaff;
    },
    [ADMIN_LOCATIONS_FILL]: (state, adminLocations) => {
        state.adminLocations = adminLocations;
    },
    [ADMIN_STAFF_FILL]: (state, adminStaff) => {
        state.adminStaff = adminStaff;
    },
    [ADMIN_ACTIVITY_FILL]: (state, adminActivity) => {
        state.adminActivity = adminActivity;
    },
    [ADMIN_COMPANY_FILL_INVITES]: (state, invites) => {
        let filteredInvites = invites.filter(item => item.Status == 0);
        state.invites = filteredInvites;
    },
    [ADMIN_KEY_COMPANY_FILL]: (state, keyCompanies) => {
        state.keyCompanies = keyCompanies;
    },
    [ADMIN_KEY_LOCATION_FILL]: (state, keyLocations) => {
        state.keyLocations = keyLocations;
    },
    [ADMIN_KEY_DEVICE_FILL]: (state, keyDevices) => {
        state.keyDevices = keyDevices;
    },
    [ADMIN_USER_LOCATION_FILL]: (state, userLocations) => {
        state.userLocations = userLocations;
    },
    [ADMIN_UNIQUE_KEY_FILL]: (state, uniqueKey) => {
        state.uniqueKey = uniqueKey;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
