export const STATUS_AUTH_SUCCESS = "auth_success";
export const STATUS_AUTH_REQUEST = "auth_request";
export const STATUS_AUTH_ERROR = "auth_error";

export const STATUS_USER_SUCCESS = "user_success";
export const STATUS_USER_REQUEST = "user_request";
export const STATUS_USER_ERROR = "user_error";

export const STATUS_COMPANY_SUCCESS = "company_success";
export const STATUS_COMPANY_REQUEST = "company_request";
export const STATUS_COMPANY_ERROR = "company_error";

export const STATUS_LOCATION_REQUEST = "location_request";
export const STATUS_LOCATION_SUCCESS = "location_success";
export const STATUS_LOCATION_ERROR = "location_error";

export const STATUS_DEVICE_REQUEST = "device_request";
export const STATUS_DEVICE_SUCCESS = "device_success";
export const STATUS_DEVICE_ERROR = "device_error";

export const STATUS_DEVICE_CODE_REQUEST = "device_code_request";
export const STATUS_DEVICE_CODE_SUCCESS = "device_code_success";
export const STATUS_DEVICE_CODE_ERROR = "device_code_error";

export const STATUS_MEMBER_REQUEST = "member_request";
export const STATUS_MEMBER_SUCCESS = "member_success";
export const STATUS_MEMBER_ERROR = "member_error";

export const STATUS_KEY_REQUEST = "key_request";
export const STATUS_KEY_SUCCESS = "key_request";
export const STATUS_KEY_ERROR = "key_request";

export const STATUS_ACTIVITY_SUCCESS = "activity_success";
export const STATUS_ACTIVITY_REQUEST = "activity_request";
export const STATUS_ACTIVITY_ERROR = "activity_error";

export const STATUS_ACL_REQUEST = "acl_success";
export const STATUS_ACL_SUCCESS = "acl_success";
export const STATUS_ACL_ERROR = "acl_success";

export const STATUS_INTERCOM_REQUEST = "intercom_success";
export const STATUS_INTERCOM_SUCCESS = "intercom_success";
export const STATUS_INTERCOM_ERROR = "intercom_success";

export const STATUS_SALTO_REQUEST ="STATUS_SALTO_REQUEST";
export const STATUS_SALTO_SUCCESS = "STATUS_SALTO_SUCCESS";
export const STATUS_SALTO_ERROR = "STATUS_SALTO_ERROR";

export const STATUS_ADMIN_REQUEST = "STATUS_ADMIN_REQUEST";
export const STATUS_ADMIN_SUCCESS = "STATUS_ADMIN_SUCCESS";
export const STATUS_ADMIN_ERROR = "STATUS_ADMIN_ERROR";
export const STATUS_ADMIN_DEVICE_CODE_SUCCESS = "STATUS_ADMIN_DEVICE_CODE_SUCCESS";
export const STATUS_ADMIN_DEVICE_CODE_ERROR = "STATUS_ADMIN_DEVICE_CODE_ERROR";
