<template>
    <div>
        <b-form-group
            :label="$t('keys.sharing_options')"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
        >
            <b-form-radio-group
                id="keyType"
                class="w-100"
                v-model="shareOptions.keyCalendarType"
                @input="shareTypeChanged"
                size="sm"
                :options="keyTypesOptions"
                buttons
                button-variant="outline-primary"
                name="keyType-default"
            ></b-form-radio-group>
        </b-form-group>
        <b-form-group
            :label="$t('keys.sharing_ability')"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
        >
            <b-form-radio-group
                id="RoleType"
                class="w-100"
                v-model="shareOptions.mayShare"
                @input="updateOptions()"
                size="sm"
                :options="keySharingTypesOptions"
                buttons
                :disabled="shareOptions.keyCalendarType !== ROLE_KEY_PERMANENT"
                button-variant="outline-primary"
                v-b-popover.hover.focus.bottom="{
                    content: $t('keys.sharing_warning_text'),
                    disabled:
                        shareOptions.keyCalendarType == ROLE_KEY_PERMANENT,
                }"
                name="RoleType-default"
            ></b-form-radio-group>
        </b-form-group>
        <b-form-group
            :label="$t('keys.time_settings')"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            v-show="shareOptions.keyCalendarType != ROLE_KEY_PERMANENT"
        >
            <b-card aria-hidden="true" bg-variant="light" class="text-center">
                <b-form-group>
                    <b-form-checkbox
                        v-model="showDays"
                        name="check-button"
                        switch
                        >{{ $t("keys.type_recurring") }}</b-form-checkbox
                    >
                </b-form-group>
                <b-form-group
                    :label="$t('keys.access_from')"
                    label-cols-sm="2"
                    label-align-sm="right"
                    label-size="sm"
                >
                    <b-input-group>
                        <b-row class="w-100">
                            <b-col md="6" sm="12">
                                <b-form-datepicker
                                    id="accessStart"
                                    style="width: auto !important;"
                                    value-as-date
                                    size="sm"
                                    v-model="shareOptions.dateFrom"
                                    ref="dateFromPicker"
                                    :min="minDate"
                                    @input="checkDateValidity"
                                    :locale="$i18n.locale"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                    }"
                                ></b-form-datepicker>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-timepicker
                                    size="sm"
                                    v-model="shareOptions.timeFrom"
                                    @input="updateOptions()"
                                ></b-form-timepicker>
                            </b-col>
                        </b-row>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    :label="$t('keys.access_to')"
                    label-cols-sm="2"
                    label-align-sm="right"
                    label-size="sm"
                >
                    <b-input-group>
                        <b-row class="w-100">
                            <b-col md="6" sm="12">
                               <!--  :min="maxDate" -->
                                <b-form-datepicker
                                    id="accessEnd"
                                    style="width: auto !important;"
                                    size="sm"
                                    v-model="shareOptions.dateTo"
                                    @input="updateOptions()"
                                    value-as-date
                                    :min="minDate"
                                    :locale="$i18n.locale"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                    }"
                                ></b-form-datepicker>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-timepicker
                                    size="sm"
                                    v-model="shareOptions.timeTo"
                                    @input="updateOptions()"
                                ></b-form-timepicker>
                            </b-col>
                        </b-row>
                    </b-input-group>
                </b-form-group>

                <b-row
                    class="d-flex h2 p-2 justify-content-center"
                    v-if="
                        showDays &&
                        shareOptions.keyCalendarType == ROLE_KEY_TEMPORARY
                    "
                >
                    <b-list-group horizontal class="w-100">
                        <b-list-group-item
                            v-for="(day, index) in shareOptions.weekDays"
                            v-bind:key="day.DayNumber"
                            class="p-0 w-1-of-7"
                            >
                            <!-- <b-button
                                squared
                                :variant="day.active ? 'primary' : 'secondary'"
                                @click="
                                    day.active = !day.active;
                                    updateOptions();
                                "
                                @input="updateOptions()"
                                class="mx-1 btn-md px-0 w-100"
                                >{{ day.DayName }}</b-button> -->
                                <b-button
                                squared
                                :variant="day.active ? 'primary' : 'secondary'"
                                @click="
                                    day.active = !day.active;
                                    updateOptions();
                                "
                                @input="updateOptions()"
                                class="mx-1 btn-md px-0 w-100"
                                >{{ dayName(day.DayNumber) }}</b-button>
                            <b-list-group class="mx-1 px-0 w-100" flush>
                                <b-list-group-item
                                    @click="updateOptions"
                                    class="p-0 rounded-0"
                                    :variant="
                                        day.active ? 'primary' : 'secondary'
                                    "
                                    ><flat-pickr
                                        v-model="day.timeFrom"
                                        @on-close="validateTime(index)"
                                        :config="timeConfig"
                                        style="display: block !important;"
                                        v-if="day.active"
                                        class="form-control form-control-sm"
                                    ></flat-pickr
                                ></b-list-group-item>
                                <b-list-group-item
                                    class="p-0 rounded-0"
                                    :variant="
                                        day.active ? 'primary' : 'secondary'
                                    "
                                    ><flat-pickr
                                        v-model="day.timeTo"
                                        @on-close="validateTime(index)"
                                        :config="timeConfig"
                                        style="display: block !important;"
                                        v-if="day.active"
                                        class="form-control form-control-sm"
                                    ></flat-pickr
                                ></b-list-group-item>
                            </b-list-group>
                        </b-list-group-item>
                    </b-list-group>
                </b-row>
            </b-card>
        </b-form-group>
    </div>
</template>

<script>
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import flatPickr from "vue-flatpickr-component";

import {
    definedRoles,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
} from "@/acl/roles";

import { getWeekDays } from "@/handlers/enums";

export default {
    props: ["value"],
    data() {
        return {
            shareOptions: {},
            showDays: false,
            ROLE_KEY_PERMANENT: definedRoles[ROLE_KEY_PERMANENT].Id,
            ROLE_KEY_TEMPORARY: definedRoles[ROLE_KEY_TEMPORARY].Id,
            timeConfig: {
                locale: this.$i18n.locale == "sv" ? "sv" : "default",
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
            },
        };
    },
    computed: {
        keyTypesOptions() {
            return [
                {
                    text: this.$t("keys.type_permanent"),
                    value: this.ROLE_KEY_PERMANENT,
                },
                {
                    text: this.$t("keys.type_temporary"),
                    value: this.ROLE_KEY_TEMPORARY,
                },
            ];
        },
        keySharingTypesOptions() {
            return [
                {
                    text: this.$t("keys.share_allowed"),
                    value: true,
                },
                {
                    text: this.$t("keys.share_disallowed"),
                    value: false,
                },
            ];
        },
        minDate() {
            const now = new Date();
            const today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );
            return new Date(today);
        },
        maxDate() {
            const tomorrow = new Date(
                this.shareOptions.dateFrom.getFullYear(),
                this.shareOptions.dateFrom.getMonth(),
                this.shareOptions.dateFrom.getDate() + 1
            );

            return new Date(tomorrow);
        },
    },
    created() {
        this.shareOptions = this.value;
        this.showDays = this.value.recurrent;
    },
    methods: {
        dayName(number){
            let dayName;
            if(number == 0){
                dayName = this.$t("days.M");
            }else if(number == 1){
                dayName = this.$t("days.T");
            }else if(number == 2){
                dayName = this.$t("days.W");
            }else if(number == 3){
                dayName = this.$t("days.Th");
            }else if(number == 4){
                dayName = this.$t("days.F");
            }else if(number == 5){
                dayName = this.$t("days.S");
            }else if(number == 6){
                dayName = this.$t("days.Su");
            }
            return dayName;
        },
        shareTypeChanged(shareType) {
            if (shareType == this.ROLE_KEY_TEMPORARY) {
                this.shareOptions.mayShare = false;
            }
        },
        checkDateValidity(date) {
            /*if (this.shareOptions.dateFrom > this.shareOptions.dateTo) {
                this.shareOptions.dateTo = new Date(
                    this.shareOptions.dateFrom.getTime() + 24 * 60 * 60 * 1000
                );
            }
            this.updateOptions();*/
        },
        updateOptions() {
            this.$emit("input", this.shareOptions);
        },
        buildCalendarPayload() {
            let dailyAcceses =
                this.shareOptions.keyCalendarType == this.ROLE_KEY_TEMPORARY &&
                this.showDays
                    ? this.shareOptions.weekDays
                          .filter((item) => item.active)
                          .map((item) => {
                              let mmtMidnight = this.$moment().startOf("day");
                              return {
                                  DayNumber: item.DayNumber,
                                  Start: this.$moment(item.timeFrom, "hh:mm")
                                      .local()
                                      .diff(mmtMidnight, "minutes"),
                                  End: this.$moment(item.timeTo, "hh:mm")
                                      .local()
                                      .diff(mmtMidnight, "minutes"),
                              };
                          })
                    : [];

            let payload = {
                AccessIntervals: [
                    {
                        StartDate: this.$moment(
                            this.$moment(this.shareOptions.dateFrom).format(
                                "YYYY-MM-DD"
                            ) +
                                "T" +
                                this.shareOptions.timeFrom
                        )
                            .utc()
                            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                        EndDate: this.$moment(
                            this.$moment(this.shareOptions.dateTo).format(
                                "YYYY-MM-DD"
                            ) +
                                "T" +
                                this.shareOptions.timeTo
                        )
                            .utc()
                            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                        DailyAccesses: dailyAcceses,
                    },
                ],
            };
            return payload;
        },
        validateTime(day) {
            let timeFrom = this.$moment(
                this.shareOptions.weekDays[day].timeFrom,
                "hh:mm"
            );
            let timeTo = this.$moment(
                this.shareOptions.weekDays[day].timeTo,
                "hh:mm"
            );
            if (timeFrom > timeTo) {
                this.$nextTick().then(() => {
                    this.$set(this.shareOptions.weekDays[day], "timeTo", this.shareOptions.weekDays[day].timeFrom);
                    this.updateOptions();
                });

            }
        },
    },
    components: {
        flatPickr,
    },
};
</script>
<style scoped>
.custom-switch {
    float: left;
}
.w-1-of-7 {
    width: calc(100% / 7);
}
.list-group-item .flatpickr-input {
    border-radius: 0 !important;
}
.list-group-item.list-group-item-secondary .flatpickr-input {
    background-color: #6e84a3;
}

.shareOptions.dateFrom .shareOptions.dateTo #accessEnd__dialog_ #accessStart__dialog_{
    width: auto !important;
}

.dropdown-menu{
    width: auto !important;
    left: -5px !important;
}
</style>
