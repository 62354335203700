<template>
    <b-modal
        :id="type"
        :title="$t('users.profile')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <b-form>
                        <b-form-group
                            :label="$t('users.first_name')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <b-form-input
                                v-model="$v.user.FirstName.$model"
                                :state="validateState('FirstName')"
                                id="userFirstName"
                                :placeholder="$t('users.first_name')"
                            />
                            <b-form-invalid-feedback
                                id="userFirstName-feedback"
                            >
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('users.last_name')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <b-form-input
                                v-model="$v.user.LastName.$model"
                                :state="validateState('LastName')"
                                id="userLastName"
                                :placeholder="$t('users.last_name')"
                            />
                            <b-form-invalid-feedback id="userLastName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            label="Email"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <b-form-input
                                v-model="$v.user.Email.$model"
                                :state="validateState('Email')"
                                id="userEmail"
                                placeholder="Email"
                                disabled
                            />
                            <b-form-invalid-feedback id="userEmail-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_email")
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            :label="$t('users.phone')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <vue-phone-number-input
                                v-model="$v.user.PhoneNumber.$model"
                                id="userPhone"
                                :translations="$t('phoneInput')"
                                :error="!validPhone && submitted"
                                @update="validatePhone"
                                required
                                disabled
                                fetch-country
                                type="text"
                            />
                            <span id="userPhone-feedback"  v-if="!validPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_required") }}
                            </span>
                        </b-form-group>
                        <b-form-group
                            :label="$t('users.avatar')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <avatar-cropper-wrapped
                                @imageUploaded="imageUploaded"
                                :imageType="2"
                                :imageUrl="getUser.MediumImageUrl"
                            />
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                        <!-- v-if="!isMobile" -->
                        <table style="border-collapse: separate; border-spacing: 0 25px; margin-left: auto; margin-right: auto;">
                            <tr>
                                <td style="padding-right: 14px; padding-left: 35px; vertical-align: top">
                                    <!-- <b-icon icon="exclamation-circle" variant="danger"></b-icon> -->
                                    <img id="infoIcon" style="width: 21px; height: 21px; line-height: 1.5" :src="'/images/info_grey_24x24dp.png'" />
                                </td>                            
                                <td
                                    style="
                                        text-align: left !important;
                                        padding-right: 30px;
                                    "
                                >
                                    <small class="text-muted" style="font-size: -0.1875rem !important;">
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_1") }}</p>
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_2") }}</p>
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_3") }}</p>
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_4") }}</p>
                                    </small>
                                </td>
                            </tr>                
                        </table>                        
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col>
                    <b-button class="input-field" @click.prevent.stop="updateProfile">{{
                        $t("users.update")
                    }}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_PATCH } from "@/store/types/user";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { M_PROFILE_FORM } from "@/components/modals/types";

import VuePhoneNumberInput from "vue-phone-number-input";
import AvatarCropperWrapped from "@/components/common/AvatarCropper/AvatarCropperWrapped";

import { validationMixin } from "vuelidate";

import {
    required,
    email,
    minLength,
    maxLength,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data: function () {
        return {
            type: M_PROFILE_FORM,
            user: {},
            validPhone: false,
            fullPhone: null,
            submitted: false,
            showAvatar: true,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight 
        };
    },
    validations: {
        user: {
            FirstName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
            LastName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
            PhoneNumber: {
                required,
            },
            Email: {
                required,
                email,
            },
        },
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    created() {
        this.setData();
    },
    methods: {
        setData() {
            this.user = { ...this.getUser };
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.user[name];
            return $dirty && this.submitted ? !$error : null;
        },
        updateProfile() {
            this.submitted = true;
            this.$v.user.$touch();
            if (this.$v.user.$anyError) {
                return;
            }
            let payload = {
                ...this.user,
                ...{ PhoneNumber: this.fullPhone.formattedNumber },
            };
            this.$store
                .dispatch(USER_PATCH, payload)
                .then(() => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        imageUploaded(response, form, xhr) {
            let payload = {
                ...this.user,
                ...{ ImageId: response.Id },
            };
            this.$store
                .dispatch(USER_PATCH, payload)
                .then(() => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
            window.location.reload();
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 700){
                this.isMobile = true
            }else{
                this.isMobile = false
            }
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
        AvatarCropperWrapped,
    },
};
</script>
