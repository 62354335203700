import DeviceController from "../../api/device";

import {
    DEVICE_REQUEST,
    DEVICE_CODE_REQUEST,
    DEVICE_ERROR,
    DEVICE_CODE_ERROR,
    DEVICE_SUCCESS,
    DEVICE_CODE_SUCCESS,
    DEVICES_GET,
    DEVICES_CLEAR,
    DEVICES_FILL,
    DEVICE_SET_ACTIVE,
    DEVICE_GET,
    DEVICE_CODE_GET,
    DEVICE_DELETE,
    DEVICE_PATCH,
    DEVICE_CREATE,
    DEVICE_CODE_PATCH,
    DEVICE_KEYS_GET,
    DEVICE_KEYS_FILL,
    DEVICE_USERS_GET,
    DEVICE_PERMANENT_USERS_GET,
    DEVICE_USERS_FILL,
    DEVICE_USERS_CLEAR,
    DEVICE_CONFIGURED,
    MX_DEVICE_INFO,
    MX_DEVICE_INFO_SET,
    MX_DEVICE_INFO_CLEAR,
    SALTO_DEVICE_INFO,
    SALTO_DEVICE_INFO_CLEAR,
    SALTO_DEVICE_INFO_SET
} from "../types/devices";

import {
    STATUS_DEVICE_REQUEST,
    STATUS_DEVICE_SUCCESS,
    STATUS_DEVICE_ERROR,
    STATUS_DEVICE_CODE_REQUEST,
    STATUS_DEVICE_CODE_SUCCESS,
    STATUS_DEVICE_CODE_ERROR
} from "../types/statuses";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const state = {
    activeDevice: null,
    mxDeviceInfo: null,
    saltoDeviceInfo: null,
    devices: [],
    deviceKeys: [],
    deviceInvites: [],
    deviceUsers: [],
    status: "",
    requestErrors: ""
};

const getters = {
    getActiveDevice: state => state.activeDevice,
    getMxDeviceInfo: state => state.mxDeviceInfo,
    getSaltoDeviceInfo: state => state.saltoDeviceInfo,
    getLocationDevices: state => state.devices,
    getDeviceKeys: state => state.deviceKeys,
    getDeviceUsers: state => state.deviceUsers,
    getDevicePermanentUsers:state => state.getDevicePermanentUsers,
    getDeviceRequestErrors: state => state.requestErrors,
    getConfiguredDevices: state => state.devices,
    isDeviceRequestOn: state => state.status == STATUS_DEVICE_REQUEST
};

const actions = {
    [DEVICES_GET]: ({
        commit,
        dispatch
    }, locationId) => {
        commit(DEVICE_REQUEST);
        return DeviceController.getDevices(locationId)
            .then(response => {
                commit(DEVICE_SUCCESS);
                commit(DEVICES_FILL, response.data);
            })
            .catch(error => {
                dispatch(DEVICE_ERROR, error);
            });
    },

    [DEVICE_GET]: ({
        commit,
        dispatch
    }, deviceId) => {
        commit(DEVICE_REQUEST);
        return DeviceController.getDevice(deviceId)
            .then(response => {
                commit(DEVICE_SUCCESS);
                commit(DEVICE_SET_ACTIVE, response.data);
            })
            .catch(error => {
                dispatch(DEVICE_ERROR, error);
            });
    },

    [MX_DEVICE_INFO]: ({
        commit,
        dispatch
    }, deviceId) => {
        commit(MX_DEVICE_INFO_CLEAR);
        return DeviceController.getMXDeviceInfo(deviceId)
            .then(response => {
                commit(MX_DEVICE_INFO_SET, response.data);
            })
            .catch(error => {
                commit(MX_DEVICE_INFO_CLEAR);
            });
    },

    [SALTO_DEVICE_INFO]: ({
        commit,
        dispatch
    }, deviceId) => {
        commit(SALTO_DEVICE_INFO_CLEAR);
        return DeviceController.getSaltoDeviceInfo(deviceId)
            .then(response => {
                commit(SALTO_DEVICE_INFO_SET, response.data);
            })
            .catch(error => {
                commit(SALTO_DEVICE_INFO_CLEAR);
            });
    },

    [DEVICE_KEYS_GET]: async({
        commit,
        dispatch
    }, deviceId) => {
        commit(DEVICE_REQUEST);
        return await DeviceController.getDeviceKeys(deviceId)
            .then(response => {
                commit(DEVICE_SUCCESS);
                commit(DEVICE_KEYS_FILL, response.data);
            })
            .catch(error => {
                // dispatch(DEVICE_ERROR, error);
            });
    },

    [DEVICE_USERS_GET]: ({
        commit,
        dispatch
    }, deviceId) => {
        commit(DEVICE_REQUEST);
        return DeviceController.getDeviceUsers(deviceId)
            .then(response => {
                const xdata = response.data.sort((i1, i2) => {
                    return (i1.User.FirstName + " " + i1.User.LastName).toUpperCase() > (i2.User.FirstName + " " + i2.User.LastName).toUpperCase() ? 1 : -1;
                });
                commit(DEVICE_SUCCESS);
                commit(DEVICE_USERS_FILL, xdata);
            })
            .catch(error => {
                // dispatch(DEVICE_ERROR, error);
            });
    },

    [DEVICE_PERMANENT_USERS_GET]: ({
        commit,
        dispatch
    }, deviceId) => {
        commit(DEVICE_REQUEST);
        return DeviceController.getDevicePermanentUsers(deviceId)
            .then(response => {
                commit(DEVICE_SUCCESS);
                commit(DEVICE_USERS_FILL, response.data);
            })
            .catch(error => {
                dispatch(DEVICE_ERROR, error);
            });
    },

    [DEVICE_CREATE]: ({
        commit,
        dispatch
    }, device) => {
        commit(DEVICE_REQUEST);
        return DeviceController.postDevice(device)
            .then((response) => {
                commit(DEVICE_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door created",
                    title: "Success",
                    variant: "success"
                });
                dispatch(DEVICES_GET, device.LocationId);
                return response.data;
            })
            .catch(error => {
                dispatch(DEVICE_ERROR, error);
            });
    },

    [DEVICE_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(DEVICE_REQUEST);
        return DeviceController.patchDevice(patch)
            .then(() => {
                commit(DEVICE_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(DEVICES_GET, patch.LocationId);
            })
            .catch(error => {
                dispatch(DEVICE_ERROR, error);
            });
    },
    [DEVICE_CODE_PATCH]: ({
        commit,
        dispatch
    }, {deviceId, doorcode}) => {
        commit(DEVICE_REQUEST);
        return DeviceController.patchDeviceCode(deviceId, doorcode)
            .then(() => {
                commit(DEVICE_SUCCESS);
                /* commit(SHOW_NOTIFY, {
                    text: "Door code updated",
                    title: "Success",
                    variant: "success"
                }); */
                // dispatch(DEVICE_CODE_GET,deviceId);
            })
            .catch(error => {
                dispatch(DEVICE_CODE_ERROR, error);
            });
    },
    [DEVICE_CODE_GET]: ({
        commit,
        dispatch
    }, deviceId) => {
        commit(DEVICE_REQUEST);
        return DeviceController.getDeviceCode(deviceId)
            .then(response => {
                commit(DEVICE_CODE_SUCCESS);
                return response.data
            })
            .catch(error => {
                dispatch(DEVICE_CODE_ERROR, error);
            });
    },
    [DEVICE_DELETE]: ({
        commit,
        dispatch
    }, device) => {
        commit(DEVICE_REQUEST);
        return DeviceController.deleteDevice(device.Id)
            .then(response => {
                commit(DEVICE_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door deleted",
                    title: "Success",
                    variant: "success"
                });
                dispatch(DEVICES_GET, device.LocationId);
            })
            .catch(error => {
                dispatch(DEVICE_ERROR, error);
            });
    },

    [DEVICE_CONFIGURED]: ({
        commit,
        dispatch
    }, saltoInstallationId) => {
        commit(DEVICE_REQUEST);
        return DeviceController.getConfiguredDevices(saltoInstallationId)
            .then(response => {
                commit(DEVICE_SUCCESS);
                commit(DEVICES_FILL, response.data);
            })
            .catch(error => {
                dispatch(DEVICE_ERROR, error);
            });
    },

    [DEVICE_ERROR]: ({
        commit
    }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger"
        });
        commit(DEVICE_ERROR, error);
    }
};

const mutations = {
    [DEVICE_REQUEST]: state => {
        state.status = STATUS_DEVICE_REQUEST;
    },
    [DEVICE_CODE_REQUEST]: state => {
        state.status = STATUS_DEVICE_CODE_REQUEST;
    },
    [DEVICE_SUCCESS]: state => {
        state.status = STATUS_DEVICE_SUCCESS;
        state.requestErrors = "";
    },
    [DEVICE_CODE_SUCCESS]: state => {
        state.status = STATUS_DEVICE_CODE_SUCCESS;
        state.requestErrors = "";
    },
    [DEVICE_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_DEVICE_ERROR;
    },
    [DEVICE_CODE_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_DEVICE_CODE_ERROR;
    },
    [DEVICES_FILL]: (state, devices) => {
        state.devices = devices;
    },
    [DEVICE_KEYS_FILL]: (state, keys) => {
        state.deviceKeys = keys;
    },
    [DEVICE_USERS_FILL]: (state, users) => {
        state.deviceUsers = users;
    },
    [DEVICE_USERS_CLEAR]: (state) => {
        state.deviceUsers = [];
    },
    [DEVICES_CLEAR]: state => {
        state.devices = [];
    },
    [DEVICE_SET_ACTIVE]: (state, device) => {
        state.activeDevice = device;
    },
    [MX_DEVICE_INFO_CLEAR]: (state) => {
        state.mxDeviceInfo = null;
    },
    [MX_DEVICE_INFO_SET]: (state, mxInfo) => {
        state.mxDeviceInfo = mxInfo;
    },
    [SALTO_DEVICE_INFO_CLEAR]: (state) => {
        state.saltoDeviceInfo = null;
    },
    [SALTO_DEVICE_INFO_SET]: (state, saltoInfo) => {
        state.saltoDeviceInfo = saltoInfo;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
