/* jshint esversion: 2015 */
import axios from "axios";

export default {
    getLocationApartments(locationId) {
        return new Promise((resolve) => {
            resolve({
                data: [
                    {
                        Id: 1,
                        Name: "Ap 001",
                        Tenants: [
                            {
                                Id: 1,
                                Name: "John Doe",
                                UserId: 3,
                            },
                        ],
                        Staircases: [],
                    },
                    {
                        Id: 2,
                        Name: "Ap 002",
                        Tenants: [
                            {
                                Id: 2,
                                Name: "Jane Doe",
                                UserId: 5,
                            },
                            {
                                Id: 23,
                                Name: "James Doe",
                                UserId: 7,
                            },
                        ],
                        Staircases: [],
                    },
                    {
                        Id: 3,
                        Name: "Ap 003",
                        Tenants: [],
                        Staircases: [],
                    },
                    {
                        Id: 4,
                        Name: "Ap 004",
                        Tenants: [],
                        Staircases: [],
                    },
                    {
                        Id: 5,
                        Name: "Ap 005",
                        Tenants: [],
                        Staircases: [],
                    },
                    {
                        Id: 10,
                        Name: "Ap 010",
                        Tenants: [],
                        Staircases: [],
                    },
                ],
            });
        });

        // return axios.get(`intercom/${locationId}/apartments`);
    },
};
