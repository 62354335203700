export default {
    getLocationActivity(locationId, logOptions) {
        return window.axios.get(`location/${locationId}/activity`, {
            params: logOptions,
        });
        /* return window.axios.get(`location/${locationId}/activities`, {
            params: logOptions,
        }); */
    },
    getLocationActivityPg(locationId, logOptions) {
        return window.axios.get(`location/${locationId}/activity/pg`, {
            params: logOptions,
            responseType: 'blob'
        });
    },
    getExportLocationActivity(locationId, logOptions) {
        return window.axios.get(`location/${locationId}/activity/export`, {
            params: logOptions,
            responseType: 'blob'
        });
    },

    getKeyActivity(keyId, logOptions) {
        return window.axios.get(`key/${keyId}/activity`, {
            params: logOptions,
        });
    },

    getKeyActivityPg(keyId, logOptions) {
        return window.axios.get(`key/${keyId}/activity/pg`, {
            params: logOptions,
            responseType: 'blob'
        });
    },

    getDeviceActivity(deviceId, logOptions) {
        return window.axios.get(`device/${deviceId}/activity`, {
            params: logOptions,
        });
    },

    getDeviceActivityPg(deviceId, logOptions) {
        return window.axios.get(`device/${deviceId}/activity/pg`, {
            params: logOptions,
            responseType: 'blob'
        });
    },

    getExportActivityStart(logOptions) {
        return window.axios.get(`reports/generate/activity`, {
            params: logOptions
        });
    },

    getExportActivityStatus(reportId) {
        return window.axios.get(`reports/${reportId}/status`);
    },
};
