import ActvityController from "../../api/activity";

import {
    ACTIVITY_REQUEST,
    ACTIVITY_SUCCESS,
    ACTIVITY_ERROR,
    ACTIVITY_LOCATION_GET
} from "../types/activity";

import {
    STATUS_ACTIVITY_SUCCESS,
    STATUS_ACTIVITY_ERROR,
    STATUS_ACTIVITY_REQUEST
} from "../types/statuses";

const getDefaultState = () => {
    return {
        locationActivity: null,
        status: "",
        requestErrors: ""
    };
};

const state = getDefaultState();

const getters = {
    getLocationActivity: state => state.locationActivity,
    getActivityRequestErrors: state => state.requestErrors,
    isActivityRequestOn: state => state.status == STATUS_ACTIVITY_REQUEST
};

const actions = {
    [ACTIVITY_LOCATION_GET]: ({ commit, dispatch }, locationId) => {
        commit(ACTIVITY_REQUEST);
        return ActvityController.getLocationActivity(locationId)
            .then(response => {
                //console.log('ACTIVITY_LOCATION_GET mut', response.data);
                commit(ACTIVITY_SUCCESS);
                commit(ACTIVITY_LOCATION_GET, response.data);
            })
            .catch(error => {
                dispatch(ACTIVITY_ERROR, error);
            });
    },
    [ACTIVITY_ERROR]: ({ commit }, error) => {
        commit(ACTIVITY_ERROR, error);
    }
};

const mutations = {
    [ACTIVITY_REQUEST]: state => {
        state.status = STATUS_ACTIVITY_REQUEST;
    },
    [ACTIVITY_SUCCESS]: state => {
        state.status = STATUS_ACTIVITY_SUCCESS;
        state.requestErrors = "";
    },
    [ACTIVITY_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_ACTIVITY_ERROR;
    },
    [ACTIVITY_LOCATION_GET]: (state, locationActivity) => {
        //console.log('ACTIVITY_LOCATION_GET', locationActivity);
        state.locationActivity = locationActivity;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
