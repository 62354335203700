var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getActiveLocation)?_c('b-navbar-nav',{staticClass:"navbar-nav ml-2"},[(_vm.getActiveLocation.CompanyId != null)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'location',"to":{
          name: 'location',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.overview")))]):(_vm.getActiveLocation.CompanyId == null)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'private-location',"to":{
            name: 'private-location',
            params: {
                locationId: _vm.getActiveLocation.Id,
            },
          }}},[_vm._v(_vm._s(_vm.$t("menu.overview")))]):_vm._e(),(!_vm.admin && !_vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'members',"disabled":!_vm.$can('viewUsers', _vm.getActiveLocation),"to":{
          name: 'members',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.users")))]):(!_vm.admin && _vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'members',"to":{
          name: 'members',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.users")))]):_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'members',"to":{
          name: 'members',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.users")))]),(!_vm.admin && !_vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":['doors', 'door'].includes(_vm.$route.name),"to":{
          name: 'doors',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.doors")))]):(!_vm.admin && _vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":['doors', 'door'].includes(_vm.$route.name),"to":{
          name: 'doors',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.doors")))]):_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":['doors', 'door'].includes(_vm.$route.name),"to":{
          name: 'doors',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.doors")))]),(!_vm.admin && !_vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":['keys', 'key'].includes(_vm.$route.name),"disabled":!_vm.$can('viewKeys', _vm.getActiveLocation),"to":{
          name: 'keys',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.keys")))]):(!_vm.admin && _vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":['keys', 'key'].includes(_vm.$route.name),"to":{
          name: 'keys',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.keys")))]):_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":['keys', 'key'].includes(_vm.$route.name),"to":{
          name: 'keys',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.keys")))]),(!_vm.admin && !_vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'activity',"disabled":_vm.disableFlag,"to":{
          name: 'activity',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.activity")))]):(!_vm.admin && _vm.roleFlag)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'activity',"disabled":_vm.disableFlag,"to":{
          name: 'activity',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.activity")))]):_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'activity',"disabled":!_vm.$can('viewActivity', _vm.getActiveLocation) && _vm.disableFlag,"to":{
          name: 'activity',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.activity")))]),(_vm.admin)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'admin',"to":{ name: 'admin' }}},[_vm._v(_vm._s(_vm.$t("admin.title")))]):_vm._e(),_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name === 'pass',"to":{
          name: 'pass',
          params: {
            locationId: _vm.getActiveLocation.Id,
            companyId: _vm.getActiveLocation.CompanyId,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.pass")))]),(_vm.showUserRoles)?_c('small',{staticClass:"text-muted mr-2"},[_c('br'),_vm._v(_vm._s(_vm.getActiveUserRole || "")+" "),_c('br'),_vm._v(_vm._s(_vm.getActiveUserRoles)+" ")]):_vm._e()],1):_c('b-navbar-nav',{staticClass:"mr-lg-auto ml-2"},[_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'dashboard',"to":{
          name: 'dashboard',
        }}},[_vm._v(_vm._s(_vm.$t("menu.dashboard")))]),_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'alldoors',"to":{ name: 'alldoors', }}},[_vm._v(_vm._s(_vm.$t("menu.all_doors")))]),_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'allkeys',"to":{ name: 'allkeys', }}},[_vm._v(_vm._s(_vm.$t("menu.all_keys")))]),(_vm.getActiveCompany)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'company-staff',"to":{
          name: 'company-staff',
          params: {
            companyId: _vm.getActiveCompany.Id,
          },
        }}},[_vm._v(_vm._s(_vm.$t("menu.staff")))]):_vm._e(),(_vm.admin)?_c('b-nav-item',{staticClass:"text-capitalize",attrs:{"active":_vm.$route.name == 'admin',"to":{ name: 'admin' }}},[_vm._v(_vm._s(_vm.$t("admin.title")))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }