import Vue from "vue";
import UserController from "@/api/user";
import AclController from "@/api/acl";
import {
    definedRoles
} from "@/acl/roles";

import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_ERROR,
    USER_GET,
    USER_GET_KEYS,
    USER_GET_ROLES,
    USER_GET_DEVICES,
    USER_ACCEPT_INVITE,
    USER_DECLINE_INVITE,
    USER_FILL_INVITES,
    USER_FILL_KEYS,
    USER_FILL_DEVICES,
    USER_FILL_ROLES,
    USER_FILL,
    USER_CLEAR,
    USER_PATCH,
    USER_CHECK_EXIST,
    USER_ROLE_SET_ACTIVE,
    USER_ROLE_CLEAR_ACTIVE, USER_ROLES_LIST_SET_ACTIVE
} from "../types/user";

import {
    STATUS_USER_REQUEST,
    STATUS_USER_SUCCESS,
    STATUS_USER_ERROR
} from "../types/statuses";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const state = {
    user: null,
    loggedIn: false,
    keys: [],
    devices: [],
    roles: [],
    activeUserRole: "",
    activeUserRoles: [],
    requestErrors: ""
};

const getters = {
    getUser: state => state.user,
    getUserKeys: state => state.keys,
    getUserDevices: state => state.devices,
    getUserRoles: state => state.roles,
    getActiveUserRole: state => state.activeUserRole,
    getActiveUserRoles: state => state.activeUserRoles,
    isLoggedIn: state => state.loggedIn,
    getUserRequestErrors: state => state.requestErrors
};

const actions = {
    [USER_CHECK_EXIST]: ({
        commit,
        dispatch
    }, phoneNumber) => {
        return UserController.checkUserExists(phoneNumber)
    },

    [USER_GET]: ({
        commit,
        dispatch
    }) => {
        commit(USER_REQUEST);
        return UserController.getUser()
            .then(response => {
                commit(USER_SUCCESS);
                commit(USER_FILL, response.data);
                return response.data
            })
            .catch(error => {
                dispatch(USER_ERROR, error);
            });
    },

    [USER_GET_KEYS]: ({
        commit,
        dispatch
    }) => {
        commit(USER_REQUEST);
        return UserController.getKeysList()
            .then(response => {
                commit(USER_SUCCESS);
                commit(USER_FILL_KEYS, response.data.filter(item => item.Key.CompanyId));
            })
            .catch(error => {
                dispatch(USER_ERROR, error);
            });
    },

    [USER_GET_DEVICES]: ({
        commit,
        dispatch
    }) => {
        commit(USER_REQUEST);
        return UserController.getDevicesList()
            .then(response => {
                commit(USER_SUCCESS);
                commit(USER_FILL_DEVICES, response.data.filter(item => item.Device.CompanyId));
            })
            .catch(error => {
                dispatch(USER_ERROR, error);
            });
    },

    [USER_GET_ROLES]: ({
        commit,
        dispatch
    }) => {
        commit(USER_REQUEST);
        return AclController.getUserRoles()
            .then(response => {
                commit(USER_SUCCESS);
                let filteredRoles = response.data
                    .filter(item => item.Name in definedRoles)
                    .map(item => {
                        item["Id"] = item.Type;
                        return item;
                    });
                commit(USER_FILL_ROLES, filteredRoles);
            })
            .catch(error => {
                dispatch(USER_ERROR, error);
            });
    },

    [USER_ACCEPT_INVITE]: ({
        commit,
        dispatch
    }, inviteId) => {
        commit(USER_REQUEST);
        return UserController.acceptInvite(inviteId)
            .then(() => {
                commit(USER_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite Accepted",
                    title: "Success",
                    variant: "success"
                });
            })
            .catch(error => {
                dispatch(USER_ERROR, error);
            });
    },

    [USER_DECLINE_INVITE]: ({
        commit,
        dispatch
    }, inviteId) => {
        commit(USER_REQUEST);
        return AclController.deleteInvite(inviteId)
            .then(() => {
                commit(USER_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Invite Cancelled",
                    title: "Success",
                    variant: "success"
                });
            })
            .catch(error => {
                dispatch(USER_ERROR, error);
            });
    },

    [USER_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(USER_REQUEST);
        return UserController.patchUser(patch)
            .then(response => {
                commit(USER_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Profile updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(USER_GET);
                commit(USER_FILL, response.data.Value);
                return response.data.Value
            })
            .catch(error => {
                dispatch(USER_ERROR, error);
            });
    },

    [USER_ERROR]: ({
        commit
    }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger"
        });
        commit(USER_ERROR, error);
    }
};

const mutations = {
    [USER_REQUEST]: state => {
        state.status = STATUS_USER_REQUEST;
    },
    [USER_SUCCESS]: state => {
        state.status = STATUS_USER_SUCCESS;
        state.requestErrors = "";
    },
    [USER_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_USER_ERROR;
    },
    [USER_FILL]: (state, user) => {
        Vue.set(state, "user", user);
    },
    [USER_FILL_INVITES]: (state, invites) => {
        let filtered = invites.filter(item => item.Status == 0);
        Vue.set(state, "invites", filtered);
    },
    [USER_FILL_KEYS]: (state, keys) => {
        Vue.set(state, "keys", keys);
    },
    [USER_FILL_DEVICES]: (state, devices) => {
        Vue.set(state, "devices", devices);
    },
    [USER_FILL_ROLES]: (state, roles) => {
        Vue.set(state, "roles", roles);
    },
    [USER_CLEAR]: state => {
        Vue.set(state, "user", null);
    },
    [USER_ROLE_SET_ACTIVE]: (state, role) => {
        state.activeUserRole = role;
    },
    [USER_ROLES_LIST_SET_ACTIVE]: (state, roles) => {
        state.activeUserRoles = roles;
    },
    [USER_ROLE_CLEAR_ACTIVE]: state => {
        state.activeUserRole = "";
        state.activeUserRoles = [];
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
