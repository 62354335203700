<template>
    <div class="img-wrapper text-center">
        <b-overlay :show="showLoader" variant="light" :opacity="0.6">
            <img
                v-show="showDefaultImage"
                :src="imageUrl"
                class="img-responsive my-2"
            />
        </b-overlay>
        <div class="img-overlay">
            <b-button
                v-show="showDefaultImage && !showLoader"
                size="sm"
                class="input-field"
                variant="primary"
                id="selectFile"
                >{{ $t("common.image_select") }}</b-button
            >
        </div>
        <avatar-cropper
            @uploaded="handleUploaded"
            @changed="handleChanged"
            @destroy="handleCancel"
            @uploading="handleUploading"
            @completed="handleCompleted"
            trigger="#selectFile"
            :uploadFormData="{ Type: imageType }"
        />
    </div>
</template>
<script>
import AvatarCropper from "@/components/common/AvatarCropper/AvatarCropper";
export default {
    props: {
        imageUrl: {
            type: [String, Element],
            default: "/images/person-big_MyZesec_140dp.png",
        },
        imageType: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showDefaultImage: true,
            showLoader: false,
        };
    },

    methods: {
        handleChanged(item) {
            this.showDefaultImage = false;
            this.$emit("ImageChanged");
        },
        handleCancel() {
            this.showDefaultImage = true;
            this.$emit("imageCancel");
        },
        handleUploading() {
            this.showDefaultImage = true;
            this.showLoader = true;
        },
        handleUploaded(response, form, xhr) {
            this.$emit("imageUploaded", response, form, xhr);
        },
        handleCompleted(response, form, xhr) {
            this.showLoader = false;
        },
    },
    components: {
        AvatarCropper,
    },
};
</script>
<style>
.img-wrapper {
    position: relative;
}
.img-responsive {
    max-width: 50%;
}
.img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.6;
}
.img-overlay:hover {
    opacity: 1;
}
.img-overlay:before {
    content: " ";
    display: block;
    height: 5%;
}
.avatar-cropper .avatar-cropper-mark {
    display: none;
}
.avatar-cropper .avatar-cropper-overlay {
    position: relative !important;
}
</style>
