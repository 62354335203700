export const KEY_SUCCESS = "KEY_SUCCESS";
export const KEY_ERROR = "KEY_ERROR";
export const KEY_REQUEST = "KEY_REQUEST";

export const KEYS_GET = "KEYS_GET";
export const KEYS_FILL = "KEYS_FILL";
export const KEYS_CLEAR = "KEYS_CLEAR";

export const KEY_SET_ACTIVE = "KEY_SET_ACTIVE";
export const KEY_CLEAR_ACTIVE = "KEY_CLEAR_ACTIVE";
export const KEY_GET = "KEY_GET";
export const KEY_CREATE = "KEY_CREATE";
export const KEY_PATCH = "KEY_PATCH";
export const KEY_DELETE = "KEY_DELETE";

export const KEY_DEVICES_GET = "KEY_DEVICES_GET";
export const KEY_DEVICES_FILL = "KEY_DEVICES_FILL";
export const KEY_DEVICES_CLEAR = "KEY_DEVICES_CLEAR";

export const KEY_DEVICE_ADD = "KEY_DEVICE_ADD";
export const KEY_DEVICES_ADD = "KEY_DEVICES_ADD";
export const KEY_DEVICE_REMOVE = "KEY_DEVICE_REMOVE";
export const KEY_USER_REMOVE = "KEY_USER_REMOVE";
export const KEY_USER_MEMBER_REMOVE = "KEY_USER_MEMBER_REMOVE";

export const KEY_INVITES_GET = "KEY_INVITES_GET";
export const KEY_INVITES_FILL = "KEY_INVITES_FILL";
export const KEY_INVITES_CLEAR = "KEY_INVITES_CLEAR";

export const KEY_USERS_GET = "KEY_USERS_GET";
export const KEY_USERS_FILL = "KEY_USERS_FILL";
export const KEY_USERS_CLEAR = "KEY_USERS_CLEAR";
