import SaltoInstallationController from "../../api/salto";

import {
    SALTO_REQUEST,
    SALTO_SUCCESS,
    SALTO_ERROR,
    SALTO_INSTALLATION_GET,
    SALTO_INSTALLATION_FILL,
    SALTO_ONLINE_DEVICE_GET,
    SALTO_ONLINE_FILL,
    SALTO_RELAYS_FILL,
    SALTO_RELAYS_GET,
    SALTO_ONLINE_DEVICE_CREATE,
    SALTO_INSTALLATION_SET_ACTIVE,
    SALTO_ONLINE_SET_ACTIVE,
    SALTO_INSTALLATION_BY_ID_GET,
    SALTO_ONLINE_DEVICE_BY_ID_GET,
    SALTO_INSTALLATION_CREATE,
    SALTO_INSTALLATION_PATCH,
    SALTO_INSTALLATION_DELETE,
    SALTO_INSTALLATION_COMPANIES_GET,
    SALTO_INSTALLATION_LOCATIONS_GET,
    SALTO_INSTALLATION_COMPANY_FILL,
    SALTO_INSTALLATION_LOCATION_FILL,
} from "../types/salto";

import {
    STATUS_SALTO_REQUEST,
    STATUS_SALTO_SUCCESS,
    STATUS_SALTO_ERROR,
} from "../types/statuses";

import {
    SHOW_NOTIFY
} from "@/store/types/global";

const state = {
    saltoInstallation:[],
    saltoOnlineDevices: [],
    saltoRelays: [],
    salto: [],
    saltoCompanies: [],
    saltoLocations: [],
    status: "",
    requestErrors: ""
};

const getters = {
    getSaltoInstallation: (state) => state.saltoInstallation,
    getSaltoOnlineDevices: state => state.saltoOnlineDevices,
    getSaltoRelaysList: state => state.saltoRelays,
    getAllSaltoCompanies: (state) => state.saltoCompanies,
    getAllSaltoLocations: (state) => state.saltoLocations,
    getSaltoRequestErrors: state => state.requestErrors,
    getSaltoActiveInstallation: state => state.activeSaltoInstallation,
    isSaltoRequestOn: state => state.status == STATUS_SALTO_REQUEST
};

const actions = {
    [SALTO_INSTALLATION_GET]: ({
        commit,
        dispatch
    }) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.getSaltoInstallation()
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SALTO_INSTALLATION_FILL, response.data);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_ONLINE_DEVICE_GET]: ({
        commit,
        dispatch
    }) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.getSaltoOnlineDevices()
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SALTO_ONLINE_FILL, response.data);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_ONLINE_DEVICE_CREATE]: ({
        commit,
        dispatch
    }, device) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.createSaltoOnlineDevice(device)
            .then((response) => {
                commit(SALTO_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Door created",
                    title: "Success",
                    variant: "success"
                });
                // dispatch(DEVICES_GET, device.LocationId);
                return response.data;
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_RELAYS_GET]: ({
        commit,
        dispatch
    }, saltoInstallationId) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.getSaltoRelays(saltoInstallationId)
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SALTO_RELAYS_FILL, response.data);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_INSTALLATION_BY_ID_GET]: ({
        commit,
        dispatch
    }, saltoInstallationId) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.getSaltoInstallationById(saltoInstallationId)
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SALTO_INSTALLATION_SET_ACTIVE, response.data);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_ONLINE_DEVICE_BY_ID_GET]: ({
        commit,
        dispatch
    }, deviceId) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.getSaltoOnlineDevicesById(deviceId)
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SALTO_ONLINE_SET_ACTIVE, response.data);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_INSTALLATION_CREATE]: ({
        commit,
        dispatch
    }, salto) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.createSaltoInstallation(salto)
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Salto Installation created",
                    title: "Success",
                    variant: "success"
                });
                dispatch(SALTO_INSTALLATION_GET);
                // return response.data;
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_INSTALLATION_PATCH]: ({
        commit,
        dispatch
    }, patch) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.patchSaltoInstallation(patch)
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Salto Installation updated",
                    title: "Success",
                    variant: "success"
                });
                dispatch(SALTO_INSTALLATION_GET);
                // return response.data;
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_INSTALLATION_DELETE]: ({
        commit,
        dispatch
    }, saltoInstallationId) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.deleteSaltoInstallation(saltoInstallationId)
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SHOW_NOTIFY, {
                    text: "Salto Installation deleted",
                    title: "Success",
                    variant: "success"
                });
                dispatch(SALTO_INSTALLATION_GET);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_INSTALLATION_COMPANIES_GET]: ({
        commit,
        dispatch
    }) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.getAllSaltoCompanies()
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SALTO_INSTALLATION_COMPANY_FILL, response.data);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_INSTALLATION_LOCATIONS_GET]: ({
        commit,
        dispatch
    }) => {
        commit(SALTO_REQUEST);
        return SaltoInstallationController.getAllSaltoLocations()
            .then(response => {
                commit(SALTO_SUCCESS);
                commit(SALTO_INSTALLATION_LOCATION_FILL, response.data);
            })
            .catch(error => {
                dispatch(SALTO_ERROR, error);
            });
    },

    [SALTO_ERROR]: ({
        commit
    }, error) => {
        commit(SHOW_NOTIFY, {
            text: error.response.data.error,
            title: "Error",
            variant: "danger"
        });
        commit(SALTO_ERROR, error);
    }
};

const mutations = {
    [SALTO_REQUEST]: state => {
        state.status = STATUS_SALTO_REQUEST;
    },
    [SALTO_SUCCESS]: state => {
        state.status = STATUS_SALTO_SUCCESS;
        state.requestErrors = "";
    },
    [SALTO_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_SALTO_ERROR;
    },
    [SALTO_INSTALLATION_FILL]: (state, saltoInstallation) => {
        state.saltoInstallation = saltoInstallation;
    },
    [SALTO_ONLINE_FILL]: (state, saltoOnlineDevices) => {
        state.saltoOnlineDevices = saltoOnlineDevices;
    },
    [SALTO_RELAYS_FILL]: (state, saltoRelays) => {
        state.saltoRelays = saltoRelays;
    },
    [SALTO_INSTALLATION_SET_ACTIVE]: (state, salto) => {
        state.activeSaltoInstallation = salto;
    },
    [SALTO_ONLINE_SET_ACTIVE]: (state, salto) => {
        state.activeSaltoOnline = salto;
    },
    [SALTO_INSTALLATION_COMPANY_FILL]: (state, saltoCompanies) => {
        state.saltoCompanies = saltoCompanies;
    },
    [SALTO_INSTALLATION_LOCATION_FILL]: (state, saltoLocations) => {
        state.saltoLocations = saltoLocations;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
