import PassportController from "../../api/passport"

import {
    SHOW_NOTIFY
} from "@/store/types/global";

import {
    ACTION_GET_PASSPORT,
    ACTION_GET_ALL_PASSPORTS,
    ACTION_ACTIVATE_PASSPORT,
    ACTION_DEACTIVATE_PASSPORT,
    SET_ACTIVE_PASSPORT,
    SET_INITIAL_ACTIVE_PASSPORT,
    SET_ALL_PASSPORTS,
    SET_ACTIVE_PASSPORT_LAYOUT,
    SET_ACTIVE_PASSPORT_USERS,
    SET_ACTIVE_PASSPORT_DOORS,
    SET_ACTIVE_PASSPORT_VALIDITY,
    SET_ACTIVE_PASSPORT_NAME,
    SET_ACTIVE_PASSPORT_IMAGE,
    SET_ACTIVE_PASSPORT_IMAGE_SMALL,
    SET_ACTIVE_PASSPORT_IMAGE_MEDIUM,
    SET_ACTIVE_PASSPORT_IMAGE_LARGE,
    SET_ACTIVE_PASSPORT_ID,
    SET_ACTIVE_STEP,
    SET_PASS_MODE,
    SET_SENDING,
} from "@/store/types/passport";

const initialPassport = {
    CompanyId: null,
    DeactivatedAt: null,
    Devices: [],
    Id: null,
    ImageId: null,
    LargeImageUrl: "",
    LayoutParameters: null,
    LocationId: null,
    MediumImageUrl: "",
    Name: "",
    SmallImageUrl: "",
    Users: [],
    ValidityTimeMinute: '',
};

const state = {
    passports: [],
    activePassport: initialPassport,
    activeStep: 'name',
    passMode: 'create',
    sending: false,
};

const getters = {
    getPassports: state => state.passports,
    getActiveStep: state => state.activeStep,
    getActivePassport: state => state.activePassport,
    getActivePassportName: state => state.activePassport?.Name ?? '',
    getActivePassportValidity: state => state.activePassport?.ValidityTimeMinute ?? '',
    getActivePassportDoors: state => state.activePassport?.Devices ?? [],
    getActivePassportUsers: state => state.activePassport?.Users ?? [],
    getPassMode: state => state.passMode,
    getSending: state => state.sending,
    getActivePassportImage: state => state.activePassport?.ImageId ?? '',
    getActivePassportImageSmall: state => state.activePassport?.SmallImageUrl ?? '',
    getActivePassportImageMedium: state => state.activePassport?.MediumImageUrl ?? '',
    getActivePassportImageLarge: state => state.activePassport?.LargeImageUrl ?? '',
    getActivePassportLayout: state => state.activePassport?.LayoutParameters ?? '',
}

const actions = {
    [ACTION_GET_PASSPORT]: ({ commit }, passportId) => {
        return PassportController.getPassport(passportId)
            .then(response => {
                state.requestErrors = "";
                commit(SET_ACTIVE_PASSPORT, response.data);
            })
            .catch(error => {
                commit(SHOW_NOTIFY, {
                    text: error.response.data.error,
                    title: "Error",
                    variant: "danger"
                });
            });
    },
    [ACTION_GET_ALL_PASSPORTS]: ({ commit }, locationId) => {
        return PassportController.getAllLocationPassports(locationId)
            .then(response => {
                state.requestErrors = "";
                commit(SET_ALL_PASSPORTS, response.data);
            })
            .catch(error => {
                commit(SHOW_NOTIFY, {
                    text: error.response.data.error,
                    title: "Error",
                    variant: "danger"
                });
            });
    },
    [ACTION_ACTIVATE_PASSPORT]: ({ commit, dispatch }, payload) => {
        return PassportController.activatePassport(payload.passport)
            .then(() => {
                state.requestErrors = "";
                dispatch(ACTION_GET_ALL_PASSPORTS, payload.location);
            })
            .catch(error => {
                commit(SHOW_NOTIFY, {
                    text: error.response.data.error,
                    title: "Error",
                    variant: "danger"
                });
            });
    },
    [ACTION_DEACTIVATE_PASSPORT]: ({ commit, dispatch }, payload) => {
        return PassportController.deactivatePassport(payload.passport)
            .then(() => {
                state.requestErrors = "";
                dispatch(ACTION_GET_ALL_PASSPORTS, payload.location);
            })
            .catch(error => {
                commit(SHOW_NOTIFY, {
                    text: error.response.data.error,
                    title: "Error",
                    variant: "danger"
                });
            });
    },
};

const mutations = {
    [SET_ALL_PASSPORTS]: (state, passports) => state.passports = passports,
    [SET_ACTIVE_STEP]: (state, step) => state.activeStep = step,
    [SET_INITIAL_ACTIVE_PASSPORT]: (state) => state.activePassport = { ...initialPassport },
    [SET_ACTIVE_PASSPORT]: (state, passport) => state.activePassport = passport,
    [SET_ACTIVE_PASSPORT_NAME]: (state, name) => state.activePassport.Name = name,
    [SET_ACTIVE_PASSPORT_ID]: (state, id) => state.activePassport.Id = id,
    [SET_ACTIVE_PASSPORT_VALIDITY]: (state, validity) => state.activePassport.ValidityTimeMinute = validity,
    [SET_ACTIVE_PASSPORT_DOORS]: (state, doors) => state.activePassport.Devices = doors,
    [SET_ACTIVE_PASSPORT_USERS]: (state, users) => state.activePassport.Users = users,
    [SET_ACTIVE_PASSPORT_LAYOUT]: (state, layout) => state.activePassport.LayoutParameters = layout,
    [SET_ACTIVE_PASSPORT_IMAGE_SMALL]: (state, image) => state.activePassport.SmallImageUrl = image,
    [SET_ACTIVE_PASSPORT_IMAGE_MEDIUM]: (state, image) => state.activePassport.MediumImageUrl = image,
    [SET_ACTIVE_PASSPORT_IMAGE_LARGE]: (state, image) => state.activePassport.LargeImageUrl = image,
    [SET_ACTIVE_PASSPORT_IMAGE]: (state, imageId) => {
        state.activePassport.ImageId = imageId;
    },
    [SET_PASS_MODE]: (state, mode) => {
        state.passMode = mode;
    },
    [SET_SENDING]: (state, value) => {
        state.sending = value;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
