import IntercomController from "@/api/intercom";

import {
    INTERCOM_SUCCESS,
    INTERCOM_ERROR,
    INTERCOM_REQUEST,
    INTERCOM_GET_LOCATION_APARTMENTS,
    INTERCOM_LOCATION_APARTMENTS_FILL,
} from "../types/intercom";

import {
    STATUS_INTERCOM_REQUEST,
    STATUS_INTERCOM_SUCCESS,
    STATUS_INTERCOM_ERROR,
} from "../types/statuses";

const getDefaultState = () => {
    return {
        locationApartments: [],
        status: "",
    };
};

const state = getDefaultState();

const getters = {
    isIntercomRequestOn: (state) => state.status == STATUS_INTERCOM_REQUEST,
    getLocationApartments: (state) => (id) => state.locationApartments[id],
};

const actions = {
    [INTERCOM_GET_LOCATION_APARTMENTS]: ({ commit, dispatch }, locationId) => {
        commit(INTERCOM_REQUEST);
        return IntercomController.getLocationApartments(locationId)
            .then((response) => {
                commit(INTERCOM_SUCCESS);
                commit(INTERCOM_LOCATION_APARTMENTS_FILL, {
                    apartments: response.data,
                    locationId,
                });
            })
            .catch((error) => {
                dispatch(INTERCOM_ERROR, error);
            });
    },
};

const mutations = {
    [INTERCOM_REQUEST]: (state) => {
        state.status = STATUS_INTERCOM_REQUEST;
    },
    [INTERCOM_SUCCESS]: (state) => {
        state.status = STATUS_INTERCOM_SUCCESS;
        state.requestErrors = "";
    },
    [INTERCOM_ERROR]: (state, error) => {
        state.requestErrors = error.response.data.error;
        state.status = STATUS_INTERCOM_ERROR;
    },

    [INTERCOM_LOCATION_APARTMENTS_FILL]: (
        state,
        { apartments, locationId }
    ) => {
        let mapped = apartments.map((item) => {
            return {
                ...item,
                ...{
                    __type: "iApartment",
                },
            };
        });
        state.locationApartments[locationId] = mapped;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
