<template>
    <b-breadcrumb class="pl-2">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <b-icon
                icon="globe"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>            
            {{
                !getActiveCompany && !getActiveLocation && this.getBreadcrumbs.length == 0
                    ? $t("menu.all")
                    : "" 
            }}            
        </b-breadcrumb-item>    
        <!-- :active="!$can('locations-list', getActiveCompany)" -->         
        <b-breadcrumb-item
            v-if="getActiveCompany && status"
            :to="{
                name: 'company',
                params: {
                    companyId: this.getActiveCompany.Id,
                },
            }"            
        >
            <b-icon
                icon="building"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            {{ this.getActiveCompany.Name }}
        </b-breadcrumb-item>
        <b-breadcrumb-item
            v-else-if="getActiveCompany && !status && !roleFlag"
            :active="!$can('locations-list', getActiveCompany)"
            :to="{
                name: 'company',
                params: {
                    companyId: this.getActiveCompany.Id,
                },
            }"            
        >
            <b-icon
                icon="building"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            {{ this.getActiveCompany.Name}}
        </b-breadcrumb-item>
        <b-breadcrumb-item
            v-else-if="getActiveCompany && !status && roleFlag"
            :to="{
                name: 'company',
                params: {
                    companyId: this.getActiveCompany.Id,
                },
            }"            
        >
            <b-icon
                icon="building"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            {{ this.getActiveCompany.Name }}
        </b-breadcrumb-item>
        <b-breadcrumb-item
            v-if="getActiveCompany && getActiveLocation"            
            :to="{
                name: 'location',
                params: {
                    locationId: this.getActiveLocation.Id,
                    companyId: this.getActiveCompany.Id,
                },
            }"
        >
            <b-icon
                icon="geo-alt-fill"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            {{ this.getActiveLocation.Name }}
        </b-breadcrumb-item>
        <b-breadcrumb-item
            v-if="getActiveLocation && (getActiveLocation.CompanyId == null) && ($route.name != 'admin')"
            :to="{
                name: 'private-location',
                params: {
                    locationId: this.getActiveLocation.Id,
                }, 
            }"
        >
            <b-icon
                icon="geo-alt-fill"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            {{ this.getActiveLocation.Name }}
        </b-breadcrumb-item>        
        <b-breadcrumb-item
            v-for="(bc, index) in this.getBreadcrumbs"
            :key="index"
            :active="bc.active"
            :to="!bc.active ? bc.to : ''"
            >{{ $t(bc.name) }}
        </b-breadcrumb-item>
        <!--  && !getActiveCompany && !getActiveLocation && !getUserDevices && !getUserKeys && (this.getBreadcrumbs.length != 0) -->
        <b-breadcrumb-item
            v-if="status && $route.name == 'admin' && !getActiveCompany && !getActiveLocation" 
            :to="{ name: 'admin' }"             
        >
            <b-icon
                v-if="this.activeTabVal == 0"
                icon="building"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            <b-icon
                v-if="this.activeTabVal == 1"
                icon="geo-alt-fill"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            <b-icon
                v-if="this.activeTabVal == 2"
                icon="people-fill"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            <b-icon
                v-if="this.activeTabVal == 3"
                icon="door-closed"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            <b-icon
                v-if="this.activeTabVal == 4"
                icon="key-fill"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            <b-icon
                v-if="this.activeTabVal == 5"
                icon="people-fill"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            <b-icon
                v-if="this.activeTabVal == 6"
                icon="door-closed"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            <b-icon
                v-if="this.activeTabVal == 7"
                icon="card-list"
                scale="1"
                shift-v="1"
                aria-hidden="true"
            ></b-icon>
            {{ this.activeTabTitleVal }}
        </b-breadcrumb-item>        
    </b-breadcrumb>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/main.js"
import { USER_GET_KEYS, USER_GET_DEVICES } from "@/store/types/user";
import { CHECK_ADMIN_USER } from "@/store/types/admin";
export default {
    data() {
        return {            
            activeTabVal: this.$session.get("activeTabView"),
            activeTabTitleVal: this.$session.get("activeTextView"),
            status: false,
            roleFlag: true,
            userRole: this.$session.get("userRole"),
            // userRole: "",
        };
    },
    computed: {
        ...mapGetters([
            "getBreadcrumbs",
            "getActiveCompany",
            "getActiveLocation",
            "getUserDevices",
            "getUserKeys",
            "getSuperAdmin",
        ]),
    },
    created(){
        EventBus.$on("tabVal", activeTabVal =>{
            this.activeTabVal = activeTabVal
        })        
        EventBus.$on("tabName", activeTabTitleVal =>{
            this.activeTabTitleVal = activeTabTitleVal
        })
        this.getAdmin();
        // this.userRole = this.$session.get("userRole")
        EventBus.$on("userRole", userRole =>{
            this.userRole = userRole
        })
        if( this.userRole == "CompanyOwner" || this.userRole == "CompanyManager" || this.userRole == "LocationManager" || this.userRole == "KeyManager"){
            this.roleFlag = true
        }/* else{
            this.roleFlag = false
        } */
        this.$store.dispatch(USER_GET_DEVICES);
        this.$store.dispatch(USER_GET_KEYS);
    },
    methods:{
        getAdmin(){            
            this.$store.dispatch(CHECK_ADMIN_USER).then((response) => {
                if (response.data.status) {
                    this.status = response.data.status
                }  
            });
        }
    }
};
</script>
