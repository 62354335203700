export const ACTIVITY_TYPE_LOCK = 1;
export const ACTIVITY_TYPE_INVITE = 2;

export const ACTIVITY_LOG_LOCATION = 1;
export const ACTIVITY_LOG_LOCATION_PG = 101;
export const ACTIVITY_LOG_KEY = 2;
export const ACTIVITY_LOG_KEY_PG = 102;
export const ACTIVITY_LOG_DEVICE = 3;
export const ACTIVITY_LOG_DEVICE_PG = 103;
export const ACTIVITY_LOG_COMPANY = 4;

export const ACTIVITY_LOG_ADMIN = 5;
export const ACTIVITY_LOG_ADMIN_PG = 102;
export const ACTIVITY_LOG_EXPORT = 6;

export const ACTIVITY_TYPE_ROLE_SET = 0;
export const ACTIVITY_TYPE_DEVICE_CREATED = 10;
export const ACTIVITY_TYPE_DEVICE_UPDATED = 11;
export const ACTIVITY_TYPE_DEVICE_DELETED = 12;
export const ACTIVITY_TYPE_KEY_CREATED = 20;
export const ACTIVITY_TYPE_KEY_DELETED = 21;
export const ACTIVITY_TYPE_KEY_UPDATED = 22;
export const ACTIVITY_TYPE_USER_REMOVED_FROM_KEY= 23;
export const ACTIVITY_TYPE_KEY_ACCESS_UPDATED= 27;
export const ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION = 33;
export const ACTIVITY_TYPE_LOCATION_CREATED = 30;
export const ACTIVITY_TYPE_LOCATION_UPDATED = 31;
export const ACTIVITY_TYPE_LOCATION_DELETED = 32;
export const ACTIVITY_TYPE_INVITE_CREATED = 40;
export const ACTIVITY_TYPE_INVITE_ACCEPTED = 41;
export const ACTIVITY_TYPE_ASSIGNED_ROLE_CO = 63;
export const ACTIVITY_TYPE_ASSIGNED_ROLE_CM = 64;
export const ACTIVITY_TYPE_ASSIGNED_ROLE_LM = 34;
export const ACTIVITY_TYPE_ASSIGNED_ROLE_KM = 35;
export const ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER = 36;
export const ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED = 24;
export const ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED = 25;
export const ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED = 26;
export const ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED = 50;
export const ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED = 51;
export const ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED = 52;

export const ACTIVITY_TYPE_PASSPORT_CREATED = 80;
export const ACTIVITY_TYPE_PASSPORT_UPDATED = 81;
export const ACTIVITY_TYPE_PASSPORT_DELETED = 82;
export const ACTIVITY_TYPE_PASSPORT_USER_ADDED = 83;
export const ACTIVITY_TYPE_PASSPORT_USER_DELETED = 84;
export const ACTIVITY_TYPE_PASSPORT_ACTIVATED = 85;
export const ACTIVITY_TYPE_PASSPORT_DEACTIVATED = 86;
export const ACTIVITY_TYPE_PASSPORT_VISITOR_CREATED = 87;
export const ACTIVITY_TYPE_PASSPORT_VISITOR_UPDATED = 88;
export const ACTIVITY_TYPE_PASSPORT_VISITOR_DELETED = 89;

/* export const ACTIVITY_TYPE_DEVICE_LOCKED_USER = 13;
export const ACTIVITY_TYPE_DEVICE_UNLOCKED_USER = 14; */
export const ACTIVITY_TYPE_DEVICE_LOCKED = 13;
export const ACTIVITY_TYPE_DEVICE_UNLOCKED = 14;

export const ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS = 15;
export const ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL = 16;

// export const ACTIVITY_TYPE_DEVICE_LOCKED = 17;

export const ACTIVITY_TYPE_DEVICE_LOCK_FAIL = 17;
export const ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL = 18;

export const ACTIVITY_TYPE_COMPANY_CREATED = 60;
export const ACTIVITY_TYPE_COMPANY_UPDATED = 61;
export const ACTIVITY_TYPE_COMPANY_DELETED= 62;
export const ACTIVITY_TYPE_REMOVED_STAFF = 66;
export const ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER = 65;
export const ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER = 37;
export const ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER = 38;
export const ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER = 39;

export const ACTIVITY_TYPE_TRANSFER_RIGHTS = 67;
export const ACTIVITY_TYPE_CONNECT_DEVICES = 28;
export const ACTIVITY_TYPE_DISCONNECT_DEVICES = 19;

/* export const ACTIVITY_USER_DELETED_FROM_COMPANY = 68;
export const ACTIVITY_USER_DELETED_FROM_KEY = 69;
export const ACTIVITY_USER_DELETED_FROM_LOCATION = 70;
export const ACTIVITY_USER_DELETED_FROM_DEVICE = 71; */

export const ACTIVITY_USER_DELETED_FROM_KEY = 68;
export const ACTIVITY_USER_DELETED_FROM_DEVICE = 69;
export const ACTIVITY_USER_DELETED_FROM_LOCATION = 70;
export const ACTIVITY_USER_DELETED_FROM_COMPANY = 71;
export const ACTIVITY_USER_DELETED_FROM_SYSTEM = 72;

//Helpers
export const activityEventTypes = () => [
    ACTIVITY_TYPE_DEVICE_LOCKED,
    ACTIVITY_TYPE_DEVICE_UNLOCKED,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
    ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,
    // ACTIVITY_TYPE_DEVICE_LOCKED_USER,
    // ACTIVITY_TYPE_DEVICE_UNLOCKED_USER,
];
export const activityAdminTypes = () => [
    ACTIVITY_TYPE_ROLE_SET,
    ACTIVITY_TYPE_DEVICE_CREATED,
    ACTIVITY_TYPE_DEVICE_UPDATED,
    ACTIVITY_TYPE_DEVICE_DELETED,
    ACTIVITY_TYPE_KEY_CREATED,
    ACTIVITY_TYPE_KEY_DELETED,
    ACTIVITY_TYPE_KEY_UPDATED,
    ACTIVITY_TYPE_LOCATION_CREATED,
    ACTIVITY_TYPE_LOCATION_UPDATED,
    ACTIVITY_TYPE_LOCATION_DELETED,
    ACTIVITY_TYPE_INVITE_CREATED,
    ACTIVITY_TYPE_ASSIGNED_ROLE_LM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_KM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER,
    ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
    ACTIVITY_TYPE_KEY_ACCESS_UPDATED,
    ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
    ACTIVITY_TYPE_INVITE_ACCEPTED,
    ACTIVITY_TYPE_COMPANY_CREATED,
    ACTIVITY_TYPE_COMPANY_UPDATED,
    ACTIVITY_TYPE_COMPANY_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED,
    ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
    ACTIVITY_TYPE_REMOVED_STAFF,
    ACTIVITY_TYPE_TRANSFER_RIGHTS,
    ACTIVITY_TYPE_CONNECT_DEVICES,
    ACTIVITY_TYPE_DISCONNECT_DEVICES,
    ACTIVITY_USER_DELETED_FROM_COMPANY,
    ACTIVITY_USER_DELETED_FROM_KEY,
    ACTIVITY_USER_DELETED_FROM_LOCATION,
    ACTIVITY_USER_DELETED_FROM_DEVICE,
    ACTIVITY_USER_DELETED_FROM_SYSTEM,
];
export const activityAllTypes = () => [
    ACTIVITY_TYPE_ROLE_SET,
    ACTIVITY_TYPE_DEVICE_CREATED,
    ACTIVITY_TYPE_DEVICE_UPDATED,
    ACTIVITY_TYPE_DEVICE_DELETED,
    ACTIVITY_TYPE_KEY_CREATED,
    ACTIVITY_TYPE_KEY_DELETED,
    ACTIVITY_TYPE_KEY_UPDATED,
    ACTIVITY_TYPE_LOCATION_CREATED,
    ACTIVITY_TYPE_LOCATION_UPDATED,
    ACTIVITY_TYPE_LOCATION_DELETED,
    ACTIVITY_TYPE_INVITE_CREATED,
    ACTIVITY_TYPE_ASSIGNED_ROLE_CO,
    ACTIVITY_TYPE_ASSIGNED_ROLE_CM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_LM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_KM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER,
    ACTIVITY_TYPE_INVITE_ACCEPTED,
    ACTIVITY_TYPE_DEVICE_LOCKED,
    ACTIVITY_TYPE_DEVICE_UNLOCKED,
    // ACTIVITY_TYPE_DEVICE_LOCKED_USER,
    // ACTIVITY_TYPE_DEVICE_UNLOCKED_USER,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
    ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,
    ACTIVITY_TYPE_COMPANY_CREATED,
    ACTIVITY_TYPE_COMPANY_UPDATED,
    ACTIVITY_TYPE_COMPANY_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED,
    ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
    ACTIVITY_TYPE_KEY_ACCESS_UPDATED,
    ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
    ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
    ACTIVITY_TYPE_REMOVED_STAFF,
    ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
    ACTIVITY_TYPE_TRANSFER_RIGHTS,
    ACTIVITY_TYPE_CONNECT_DEVICES,
    ACTIVITY_TYPE_DISCONNECT_DEVICES,
    ACTIVITY_USER_DELETED_FROM_COMPANY,
    ACTIVITY_USER_DELETED_FROM_KEY,
    ACTIVITY_USER_DELETED_FROM_LOCATION,
    ACTIVITY_USER_DELETED_FROM_DEVICE,
    ACTIVITY_USER_DELETED_FROM_SYSTEM,
];

// Images
export const IMAGE_TYPE_COMPANY = 0
export const IMAGE_TYPE_LOCATION = 1
export const IMAGE_TYPE_USER = 2
export const IMAGE_TYPE_DOOR = 3

export const EXPORT_STATE_NONE = 0;
export const EXPORT_STATE_WORKING = 1;
export const EXPORT_STATE_ERROR = 2;
export const EXPORT_STATE_DONE = 3;
