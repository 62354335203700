/* jshint esversion: 2015 */
import axios from "axios";

export default {
    readNotifications() {
        return window.axios.post("notification/read/all");
    },
    getNotifications() {
        return window.axios.get("notification");
    },
    saveNotificationsSettings(settings) {
        return window.axios.post("notification/user/settings", {
            Settings: settings
        });
    }
}
