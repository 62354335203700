export const LOCATION_STATE_RESET = "LOCATION_STATE_RESET";

export const LOCATION_SUCCESS = "LOCATION_SUCCESS";
export const LOCATION_ERROR = "LOCATION_ERROR";
export const LOCATION_REQUEST = "LOCATION_REQUEST";

export const LOCATIONS_GET_COMPANY_LOCATIONS = "LOCATIONS_GET_COMPANY_LOCATIONS";
export const LOCATIONS_GET_ALL_LOCATIONS = "LOCATIONS_GET_ALL_LOCATIONS";
export const LOCATIONS_GET_ALL_LOC = "LOCATIONS_GET_ALL_LOC";
export const LOCATIONS_FILL = "LOCATIONS_FILL";
export const LOCATIONS_COMPANY_FILL = "LOCATIONS_COMPANY_FILL";
export const LOCATIONS_CLEAR = "LOCATIONS_CLEAR";

export const LOCATION_SET_ACTIVE = "LOCATION_SET_ACTIVE";
export const LOCATION_CLEAR_ACTIVE = "LOCATION_CLEAR_ACTIVE";
export const LOCATION_GET = "LOCATION_GET";
export const LOCATION_CREATE = "LOCATION_CREATE";
export const LOCATION_PATCH = "LOCATION_PATCH";
export const LOCATION_DELETE = "LOCATION_DELETE";
export const LOCATION_USER_DELETE = "LOCATION_USER_DELETE";

export const LOCATION_GET_USERS = "LOCATION_GET_USERS";
export const LOCATION_USERS_FILL = "LOCATION_USERS_FILL";
