<template>
    <b-modal
        :id="type"
        :title="$t('keys.resend_invite')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid v-if="access">
            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button variant="primary" disabled
                            ><b-row class="px-2"
                                >
                            <!-- <img class="mr-2" :src="'/images/Key_MyZesec_64dp.png'" style="width: 19px; height: 19px;"/> -->
                            <i class="fi flaticon-key mr-2"></i>{{ $t("keys.key") }}:</b-row>
                            <b-row class="px-2">
                                {{ $t("keys.selected") }}:
                                <strong>{{ access.Key.Name }}</strong></b-row
                            >
                        </b-button>
                    </b-card-header>
                </b-card>

                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" role="tab" class="p-1">
                        <b-button block variant="primary" disabled
                            ><b-row class="px-2"
                                >
                                <i class="fi flaticon-user mr-2"></i>{{ $t("keys.share_to") }}:</b-row
                            >
                            <b-row class="px-2">
                                {{ $t("keys.selected_peoples") }}:
                                <strong>{{
                                    access.User.FirstName +
                                    " " +
                                    access.User.LastName
                                }}</strong></b-row
                            ></b-button
                        >
                    </b-card-header>
                </b-card>

                <b-card no-body class="mb-1" style="overflow-y: auto !important;">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-3 variant="primary"
                            ><b-row class="px-2"
                                ><i class="fe fe-settings mr-2"></i
                                >{{ $t("keys.sharing_options") }}:</b-row
                            >
                            <b-row class="px-2"
                                ><strong>
                                    {{
                                        shareOptions.mayShare
                                            ? $t("keys.share_allowed")
                                            : $t("keys.share_disallowed")
                                    }},
                                    {{
                                        shareOptions.keyCalendarType == 6
                                            ? $t("keys.type_permanent")
                                            : $t("keys.type_temporary")
                                    }}</strong
                                ></b-row
                            >
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        id="accordion-3"
                        accordion="my-accordion"
                        role="tabpanel"
                        visible
                    >
                        <b-card-body class="px-2">
                            <b-form>
                                <share-options
                                    v-model="shareOptions"
                                    ref="shareOptionsWidget"
                                />
                            </b-form>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            <b-row>
                <b-col class="mt-2">
                    <b-button
                        class="input-field"
                        v-if="!saving"
                        @click.prevent.stop="updateAccess"
                        >{{ $t("keys.resend_invite_button") }}</b-button
                    >
                    <b-button class="input-field" disabled v-else>
                        <b-spinner small></b-spinner>
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { MEMBER_KEYS_GET, MEMBER_TO_KEY_REINVITE } from "@/store/types/members";
import {
    DEVICE_USERS_GET,
} from "@/store/types/devices";
import {
    KEY_USERS_GET,
} from "@/store/types/keys";
import {
    ROLE_KEY_PERMANENT,
} from "@/acl/roles";

import { getWeekDays } from "@/handlers/enums";

import { M_MEMBER_REINVITE_FORM } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

import ShareOptions from "@/components/key/share/ShareOptions";

export default {
    data() {
        return {
            type: M_MEMBER_REINVITE_FORM,
            inviteMode: 0,
            invite: {},
            shareOptions: {},
            saving: false,
            access: null,
        };
    },
    props: ["locationId"],
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getLocationKeys"]),
    },
    created() {},
    methods: {
        updateAccess() {
            this.saving = true;
            const AccessIntervals = this.$refs.shareOptionsWidget.buildCalendarPayload()
                .AccessIntervals;
            let invitePayload = {
                MayShare: this.shareOptions.mayShare,
                RoleType: this.shareOptions.keyCalendarType,
                AccessIntervals,
            };
            this.$store
                .dispatch(MEMBER_TO_KEY_REINVITE, {
                    inviteId: this.access.Id,
                    invite: invitePayload,
                })
                .then(() => {
                    this.$store.dispatch(MEMBER_KEYS_GET, {
                        locationId: this.access.Key.LocationId,
                        userId: this.access.UserId,
                    });
                    if(this.$route.params.doorId){
                        this.$store.dispatch(DEVICE_USERS_GET, this.$route.params.doorId);
                    }
                    if(this.$route.params.keyId){
                        this.$store.dispatch(KEY_USERS_GET, this.$route.params.keyId);
                    }
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {
                    this.saving = false;
                });
                this.saving = false;
                // window.location.reload();
        },

        normalizeDate(date) {
            if ((typeof date === 'string' || date instanceof String) && date.length > 9 && date.includes('T') && !date.includes('+') && !date.includes('Z') && date[-1] != 'Z')
                date += 'Z'
            return date
        },

        setData() {
            let recurrent = false;
            let weekDays = null;
            let startDate = new Date();
            let endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

            if (this.getModalData.access.Role.Name == ROLE_KEY_PERMANENT) {
                weekDays = getWeekDays();
                startDate = new Date();
                endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            } else {
                if(this.getModalData.access.Calendar != null){
                    const dailyAccesses = this.getModalData.access.Calendar
                        .AccessIntervals[0].DailyAccesses;

                    if (dailyAccesses.length > 0) {
                        recurrent = true;
                    }

                    weekDays = getWeekDays().map((day) => {
                        const access = dailyAccesses.find(
                            (item) => item.DayNumber == day.DayNumber
                        );

                        if (access) {
                            day.active = true;
                            day.timeTo = this.$moment()
                                .startOf("day")
                                .add(access.End, "minutes")
                                .format("HH:mm");
                            day.timeFrom = this.$moment()
                                .startOf("day")
                                .add(access.Start, "minutes")
                                .format("HH:mm");
                        } else {
                            day.active = false;
                        }
                        return day;
                    });
                    startDate = new Date(
                        this.normalizeDate(this.getModalData.access.Calendar.AccessIntervals[0].StartDate)
                    );
                    endDate = new Date(
                        this.normalizeDate(this.getModalData.access.Calendar.AccessIntervals[0].EndDate)
                    );
                }
            }

            this.shareOptions = {
                keyCalendarType: this.getModalData.access.RoleId,
                dateFrom: startDate,
                timeFrom: this.$moment(startDate).format("HH:mm"),
                dateTo: endDate,
                timeTo: this.$moment(endDate).format("HH:mm"),
                weekDays: weekDays,
                mayShare: this.getModalData.access.MayShare,
                recurrent: recurrent,
            };
            this.access = this.getModalData.access;
            this.hidden = false;
        },
        onClose() {
            this.inviteMode = 0;
            this.listUsers = [];
            this.keyMembersList = [];
            this.selectedMembersIds = [];
            this.shareOptions.keyCalendarType = 6;
            this.shareOptions.weekDays = getWeekDays();
            this.hidden = true;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
        ShareOptions,
    },
};
</script>
<style>
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
</style>
