<template>
    <b-modal
        :id="type"
        :title="$t('common.image_select')"
        size="lg"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        hide-footer
    >
        <b-container fluid>
            <b-row v-if="type == getModalType">
                <b-col cols="12">
                    <avatar-cropper-wrapped
                        @imageUploaded="imageUploaded"
                        :imageType="this.getModalData.image.type"
                        :imageUrl="this.getModalData.image.url"
                    />
                </b-col>
            <!-- </b-row>
            <b-row v-if="type == getModalType"> v-if="!isMobile" -->
                <b-col cols="12">
                 <table style="border-collapse: separate; border-spacing: 0 25px; margin-left: auto; margin-right: auto;">
                        <tr>
                            <td style="padding-right: 14px; padding-left: 35px; vertical-align: top">
                                <!-- <b-icon icon="exclamation-circle" variant="danger"></b-icon> -->
                                <img id="infoIcon" style="width: 21px; height: 21px; line-height: 1.5" :src="'/images/info_grey_24x24dp.png'" />
                            </td>
                            <td
                                style="
                                    text-align: left !important;
                                    padding-right: 30px;
                                "
                            >
                                <small class="text-muted" style="font-size: -0.1875rem !important;">
                                    <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_1") }}</p>
                                    <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_2") }}</p>
                                    <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_3") }}</p>
                                    <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_4") }}</p>
                                </small>
                            </td>
                        </tr>
                    </table>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { M_IMAGE_UPLOAD } from "@/components/modals/types";

import AvatarCropperWrapped from "@/components/common/AvatarCropper/AvatarCropperWrapped";

export default {
    data: function () {
        return {
            type: M_IMAGE_UPLOAD,
            showAvatar: true,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },
    created(){
        window.addEventListener("resize", this.onResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        imageUploaded(response, form, xhr) {
            this.$emit("imageUploaded", response);
            this.$bvModal.hide(this.type);
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            this.isMobile = this.width < 700;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
    },
    components: {
        AvatarCropperWrapped,
    },
};
</script>
