export const SALTO_SUCCESS = "SALTO_SUCCESS";
export const SALTO_ERROR = "SALTO_ERROR";
export const SALTO_REQUEST = "SALTO_REQUEST";

export const SALTO_INSTALLATION_FILL = "SALTO_INSTALLATION_FILL";
export const SALTO_ONLINE_FILL = "SALTO_ONLINE_FILL";

export const SALTO_RELAYS_FILL = "SALTO_RELAYS_FILL";
export const SALTO_RELAYS_GET = "SALTO_RELAYS_GET";

export const SALTO_INSTALLATION_GET = "SALTO_INSTALLATION_GET";
export const SALTO_ONLINE_DEVICE_GET = "SALTO_ONLINE_DEVICE_GET";

export const SALTO_ONLINE_DEVICE_CREATE = "SALTO_ONLINE_DEVICE_CREATE";

export const SALTO_INSTALLATION_SET_ACTIVE = "SALTO_INSTALLATION_SET_ACTIVE";
export const SALTO_ONLINE_SET_ACTIVE = "SALTO_ONLINE_SET_ACTIVE";

export const SALTO_INSTALLATION_BY_ID_GET = "SALTO_INSTALLATION_BY_ID_GET";
export const SALTO_ONLINE_DEVICE_BY_ID_GET = "SALTO_ONLINE_DEVICE_BY_ID_GET";

export const SALTO_INSTALLATION_CREATE = "SALTO_INSTALLATION_CREATE";
export const SALTO_INSTALLATION_PATCH = "SALTO_INSTALLATION_PATCH";

export const SALTO_INSTALLATION_DELETE = "SALTO_INSTALLATION_DELETE";

export const SALTO_INSTALLATION_COMPANIES_GET = "SALTO_INSTALLATION_COMPANIES_GET";
export const SALTO_INSTALLATION_LOCATIONS_GET = "SALTO_INSTALLATION_LOCATIONS_GET";
export const SALTO_INSTALLATION_COMPANY_FILL = "SALTO_INSTALLATION_COMPANY_FILL";
export const SALTO_INSTALLATION_LOCATION_FILL = "SALTO_INSTALLATION_LOCATION_FILL";
