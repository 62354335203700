export const M_PROFILE_FORM = "profileModal";

export const M_COMPANY_DELETE = "deleteCompanyModal";
export const M_COMPANY_FORM = "createCompanyModal";

export const M_COMPANY_ADMIN_DELETE = "deleteCompanyAdminModal";
export const M_COMPANY_ADMIN_FORM = "createCompanyAdminModal";

export const M_LOCATION_DELETE = "deleteLocationModal";
export const M_LOCATION_FORM = "createLocationModal";

export const M_LOCATION_ADMIN_DELETE = "deleteLocationAdminModal";
export const M_LOCATION_FORM_ADMIN = "createLocationAdminModal";

export const M_DEVICE_FORM = "createDeviceModal";
export const M_DEVICE_DELETE = "deleteDeviceModal";

export const M_DEVICE_ADMIN_DELETE = "deleteDeviceAdminModal";
export const M_DEVICE_ADMIN_FORM = "createDeviceAdminModal";

export const M_SALTO_FORM = "createSaltoInstallationModal";
export const M_SALTO_INSTALLATION_DELETE = "deleteSaltoInstallationModal";

export const M_KEY_FORM = "createKeyModal";
export const M_KEY_DELETE = "deleteKeyModal";
export const M_KEY_DEVICES = "manageKeyDevicesModal";

export const M_KEY_FORM_ADMIN = "createKeyAdminModal"
export const M_KEY_ADMIN_DELETE = "deleteKeyAdminModal";

export const M_MEMBER_FORM = "createMemberModal";
export const M_MEMBER_INVITE_FORM = "inviteMemberModal";
export const M_MEMBER_INVITE_ADMIN_FORM = "inviteMemberAdminModal";
export const M_MEMBER_REINVITE_FORM = "reinviteMemberModal";
export const M_MEMBER_INVITE_CANCEL = "cancelInviteMemberModal";
export const M_MEMBER_INVITE_CANCEL_ADMIN = "cancelInviteMemberAdminModal";
export const M_DELETE_KEY_USER = "deleteKeyUserModal";
export const M_DELETE_KEY_USER_MEMBER  = "deleteKeyUserMemberModal";
export const M_DELETE_KEY_USER_ADMIN = "deleteKeyUserAdminModal";
export const M_DELETE_DOOR_USER = "deleteDoorUserModal";
export const M_LOCATION_USER_DELETE = "deleteLocationUserModal";
export const M_ADMIN_LOCATION_USER_DELETE = "deleteLocationUserAdminModal";
export const M_COMPANY_INVITE_FORM = "inviteCompanyEmployeeModal";
export const M_COMPANY_LOCATION_INVITE_FORM = "inviteLocationEmployeeModal";
export const M_COMPANY_LOCATION_INVITE_ADMIN_FORM = "inviteLocationEmployeeAdminModal";
export const M_COMPANY_USER_DELETE = "deleteUserModal";
export const M_ADMIN_COMPANY_USER_DELETE = "deleteUserAdminModal";
export const M_COMPANY_CHANGE_ROLE_FORM = "changeCompanyEmployeeRoleModal";
export const M_COMPANY_CHANGE_ROLE_ADMIN_FORM = "changeCompanyEmployeeRoleAdminModal";
export const M_LOCATION_CHANGE_ROLE_FORM = "changeLocationEmployeeRoleModal";
export const M_LOCATION_CHANGE_ROLE_ADMIN_FORM = "changeLocationEmployeeRoleAdminModal";
export const M_COMPANY_INVITE_ADMIN_FORM = "inviteCompanyEmployeeAccordionAdminModal";
export const M_ADMIN_USER_ACCESS_DELETE = "userAccessDeleteModal";

export const M_IMAGE_UPLOAD = "imageUploadModal";
export const M_DEVICE_QR = "deviceQRModal";

export const M_ADD_DEVICE = "addDoorModal";
export const M_DISCONNECT_DEVICE = "disconnectDoorModal";
export const M_CHANGE_OWNER = "changeOwnerModal";

export const M_ADMIN_LOCATION_MULTIPLE_USER_DELETE = "deleteLocationMultipleUserAdminModal";

export const M_DANALOCK_LOGIN = "danalockLoginModal";

export const M_PASS_FORM = "createPassModal";
