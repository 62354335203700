export const ROLE_COMPANY_OWNER = "CompanyOwner";
export const ROLE_COMPANY_MANAGER = "CompanyManager";
export const ROLE_LOCATION_MANAGER = "LocationManager";
export const ROLE_DEVICE_INSTALLER = "Installer";
export const ROLE_KEY_MANAGER = "KeyManager";
// export const ROLE_SUPER_ADMIN = "SuperAdmin";

export const ROLE_KEY_PERMANENT = "Permanent";
export const ROLE_KEY_TEMPORARY = "Temporary";

export const definedRoles = {
    [ROLE_COMPANY_OWNER]: {
        value: ROLE_COMPANY_OWNER,
        text: "Company Owner",
        Id: 0,
        level: 0,
        __type: "Role"
    },
    [ROLE_COMPANY_MANAGER]: {
        value: ROLE_COMPANY_MANAGER,
        text: "Company Manager",
        Id: 1,
        level: 10,
        __type: "Role"
    },
    [ROLE_LOCATION_MANAGER]: {
        value: ROLE_LOCATION_MANAGER,
        text: "Location Manager",
        Id: 2,
        level: 20,
        __type: "Role"
    },
    [ROLE_KEY_MANAGER]: {
        value: ROLE_KEY_MANAGER,
        text: "KeyManager",
        Id: 8,
        level: 25,
        __type: "Role"
    },
    [ROLE_DEVICE_INSTALLER]: {
        value: ROLE_DEVICE_INSTALLER,
        text: "Installer",
        Id: 5,
        level: 30,
        __type: "Role"
    },
    [ROLE_KEY_PERMANENT]: {
        value: ROLE_KEY_PERMANENT,
        text: "Permanent",
        Id: 6,
        level: 40,
        __type: "Role"
    },
    [ROLE_KEY_TEMPORARY]: {
        value: ROLE_KEY_TEMPORARY,
        text: "Temporary",
        Id: 7,
        level: 45,
        __type: "Role"
    },
    /* [ROLE_SUPER_ADMIN]: {
        value: ROLE_SUPER_ADMIN,
        text: "SuperAdmin",
        Id: 9,
        level: 50,
        __type: "Role"
    } */
};

export const definedKeyRoles = {
    [ROLE_KEY_PERMANENT]: {
        value: ROLE_KEY_PERMANENT,
        text: "Permanent",
        Id: 6,
        level: 40,
        __type: "Role"
    },
    [ROLE_KEY_TEMPORARY]: {
        value: ROLE_KEY_TEMPORARY,
        text: "Temporary",
        Id: 7,
        level: 45,
        __type: "Role"
    }
};

export const definedRolesArray = () => Object.values(definedRoles);
export const definedKeyRolesArray = () => Object.values(definedKeyRoles);
export const definedKeyRolesIdsArray = () =>
    Object.values(definedKeyRoles).map(item => item.Id);

export function maxRoleName(roleArray) {
    if (roleArray?.length > 0) {
        let filteredRoles = roleArray.filter(item => definedRoles[item])
        if (filteredRoles.length == 0)
            return ""

        let oMax = filteredRoles.reduce((max, curr) => definedRoles[max]?.level < definedRoles[curr]?.level ? max : curr)
        return oMax ?? ""
    } else {
        return ""
    }
}

export function rolePriorityByName(name) {
    let role = null;
    if (definedRoles[name])
        role = definedRoles[name]
    return role?.level ?? 1000
}
