import Vue from "vue";
import {
    defaultLocale
} from '@/i18n'

import {
    SET_BREADCRUMBS,
    SET_MODAL_DATA,
    CLEAR_MODAL_DATA,
    INIT_COMPLETED,
    INIT_STARTED,
    SHOW_NOTIFY,
    SET_LANGUAGE
} from "../types/global";

const state = {
    initProcessing: true,
    breadcrumbs: [],
    modalData: null,
    modalType: "",
    notify: null,
    language: localStorage.getItem("lang") || defaultLocale
};

const getters = {
    getBreadcrumbs: state => state.breadcrumbs,
    getModalData: state => state.modalData,
    getModalType: state => state.modalType,
    getInitProcessing: state => state.initProcessing,
    getNotify: state => state.notify,
    getLanguage: state => state.language
};

const actions = {};

const mutations = {
    [SET_BREADCRUMBS]: (state, breadcrumbs) => {
        state.breadcrumbs = breadcrumbs;
    },
    [SET_MODAL_DATA]: (state, modalData) => {
        Vue.set(state, "modalData", modalData.data);
        state.modalType = modalData.type;
    },
    [CLEAR_MODAL_DATA]: state => {
        Vue.set(state, "modalData", null);
        state.modalType = "";
    },
    [INIT_COMPLETED]: state => {
        state.initProcessing = false;
        setTimeout(() => {
            document.body.classList.remove(
                "bg-no-y-of"
            )
        }, 200);

    },
    [INIT_STARTED]: state => {
        state.initProcessing = true;
        document.body.classList.add(
            "bg-no-y-of"
        )
    },
    [SHOW_NOTIFY]: (state, notify) => {
        Vue.set(state, "notify", notify);
    },
    [SET_LANGUAGE]: (state, language) => {
        state.language = language;
        localStorage.setItem('lang', language);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

