<template>
    <div class="dropdown-menu dropdown-menu-right dropdown-menu-card">
        <div class="card-header">
            <h5 class="card-header-title">{{ $t("notifications.title") }}</h5>
            <router-link
                class="small"
                :to="{
                    name: 'notifications',
                }"
            >
                {{ $t("notifications.all") }}
            </router-link>
        </div>
        <!-- / .card-header -->
        <div class="card-body">
            <!-- List group -->
            <div
                class="list-group list-group-flush list-group-activity my-n3"
                v-if="notifications.length"
            >
                <a
                    class="list-group-item text-reset"
                    v-for="notification in notifications"
                    v-bind:key="notification.Id"
                >
               <!--  style="display: -webkit-box;" -->
                    <div class="row">
                        <div class="col-auto">
                            <b-avatar
                                variant="light"
                                icon="bell"
                                class="avatar avatar-sm pt-2"
                            ></b-avatar>
                        </div>                        
                        <div class="col ml-n2">
                            <!-- Content -->
                            <div class="small">
                                {{ notifictionText(notification.Text) }}
                            </div>

                            <!-- Time -->
                            <small class="text-muted">{{
                                $moment
                                    .utc(notification.CreatedAt)
                                    .local()
                                    .fromNow()
                            }}</small>
                        </div>
                    </div>
                    <!-- / .row -->
                </a>
            </div>
            <div v-else>{{ $t("notifications.no_notifications") }}</div>
            <!-- <div v-else>
                <empty-list :title="$t('notifications.no_notifications')"/>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    props: ["notifications"],
    methods: {
        notifictionText(text){
            if(text.includes("has sent you a digital key in Zesec!")){
                text = text.replace("has sent you a digital key in Zesec!",this.$t("notifications.digital"));
            }
            if(text.includes("has removed a digital key from you in Zesec!")){
                text = text.replace("has removed a digital key from you in Zesec!",this.$t("notifications.remove_digital"));
            }
            return text;
        }
    }
};

</script>
<style   scoped>
.dropdown-menu-card {
    max-width: 350px;
}
</style>