<template>
    <b-modal
        :id="type"
        :title="$t('companies.company')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="onShow"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col id="company" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                       <!--  label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm" -->
                        <b-form-group :label="$t('companies.name')">
                            <b-form-input
                                v-model="$v.company.Name.$model"
                                :state="validateState('Name')"
                                id="companyName"
                                :placeholder="$t('companies.name')"
                            />
                            <b-form-invalid-feedback id="companyName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-50",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('companies.number')">
                            <b-form-input
                                v-model="$v.company.OrganizationNumber.$model"
                                id="companyOrg"
                                :state="validateState('OrganizationNumber')"
                                :placeholder="$t('companies.number')"
                            />
                            <b-form-invalid-feedback id="companyOrg-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_number")
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('companies.phone')">
                            <vue-phone-number-input
                                v-model="$v.company.PhoneNumber.$model"
                                :translations="$t('phoneInput')"
                                id="companyPhone"
                                :error="!validPhone && submitted"
                                @update="validatePhone"
                                required
                                :default-country-code="company.countryCode"
                                :fetch-country="createMode"
                                type="text"
                            />
                            <span id="companyPhone-feedback" v-if="!validPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_phone_number") }}
                            </span>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col id="address" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <p :label="$t('companies.address')"
                            label-align-sm="left"
                            style="margin-left: -45px !important;">{{ $t('companies.address') }}</p>
                        <b-form-group>
                            <b-form-group :label="$t('companies.street')">
                                <b-form-input
                                    v-model="$v.company.Street.$model"
                                    id="companyStreet"
                                    :state="validateState('Street')"
                                    :placeholder="$t('companies.street')"
                                    type="text"
                                />
                                <b-form-invalid-feedback
                                    id="companyStreet-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "2-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('companies.zip')">
                                <b-form-input
                                    v-model="$v.company.PostalCode.$model"
                                    id="companyPostal"
                                    :state="validateState('PostalCode')"
                                    :placeholder="$t('companies.zip')"
                                    type="text"
                                />
                                <b-form-invalid-feedback id="companyPostal-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_zip")
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('companies.town')">
                                <b-form-input
                                    v-model="$v.company.Town.$model"
                                    id="companyTown"
                                    :state="validateState('Town')"
                                    :placeholder="$t('companies.town')"
                                />
                                <b-form-invalid-feedback id="companyTown-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "2-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('companies.country')">
                                <b-form-input
                                    v-model="$v.company.Country.$model"
                                    id="companyCountry"
                                    :state="validateState('Country')"
                                    :placeholder="$t('companies.country')"
                                    type="text"
                                />
                                <b-form-invalid-feedback id="companyCountry-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "2-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col id="type" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <p :label="$t('companies.type')"
                            label-align-sm="left"
                            style="margin-left: -45px !important;">{{ $t('companies.type') }}</p>
                        <b-form-group>
                            <b-form-group :label="$t('companies.type_select')">
                                <b-input-group>
                                    <b-form-select
                                        v-model="$v.company.Type.$model"
                                        id="companyType"
                                        :state="validateState('Type')"
                                        :options="companyTypesOptions"
                                        :disabled="!createMode"
                                    ></b-form-select>
                                </b-input-group>
                                <b-form-invalid-feedback
                                    id="companyType-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("companies.type_select_default")
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col id="type" cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <p :label="$t('companies.api_keys')"
                           label-align-sm="left"
                           style="margin-left: -45px !important;">{{ $t('companies.api_keys') }}</p>
                        <b-form-group>
                            <b-form-group :label="$t('companies.inlet_online_api_key')">
                                <b-form-input
                                    v-model="$v.company.InletOnlineApiKey.$model"
                                    id="companyInletOnlineApiKey"
                                    :state="validateState('InletOnlineApiKey')"
                                    :placeholder="$t('companies.inlet_online_api_key')"
                                    type="text"
                                />
                                <b-form-invalid-feedback id="companyInletOnlineApiKey-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_no_req_length_range", {
                                            range: "0-40",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-form-group>
                    </b-form>
                </b-col>


                <b-col style="padding-left: 54px !important;">
                    <b-button
                        class="input-field"
                        @click.prevent.stop="postCompany"
                        v-if="createMode"
                        >{{ $t("companies.create") }}</b-button
                    >
                    <b-button class="input-field" @click.prevent.stop="editCompany" v-else>{{
                        $t("companies.update")
                    }}</b-button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { companyTypes } from "@/handlers/enums";
import {
    COMPANY_CREATE,
    COMPANY_PATCH,
    COMPANIES_GET,
} from "@/store/types/companies";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { M_COMPANY_FORM } from "@/components/modals/types";
import { validationMixin } from "vuelidate";
import VuePhoneNumberInput from "vue-phone-number-input";
import {
    required,
    minLength,
    numeric,
    maxLength,
} from "vuelidate/lib/validators";

import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_COMPANY_FORM,
            validPhone: false,
            fullPhone: null,
            createMode: true,
            company: {},
            submitted: false,
        };
    },
    validations: {
        company: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            OrganizationNumber: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(20),
            },
            PhoneNumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
            Street: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            PostalCode: {
                required,
                numeric,
                minLength: minLength(4),
                maxLength: maxLength(5),
            },
            Town: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            Country: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            InletOnlineApiKey: {
                required: false,
                minLength: minLength(0),
                maxLength: maxLength(40),
            },
            Type: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
        companyTemplate() {
            return {
                OrganizationNumber: "",
                Name: "",
                Street: "",
                Town: "",
                PostalCode: "",
                PhoneNumber: "",
                Country: "",
                InletOnlineApiKey: "",
                Type: null,
            };
        },
        companyTypesOptions() {
            return [
                { value: null, text: this.$t("companies.type_select_default") },
                ...companyTypes
                    .map((item) => {
                        return {
                            value: item.value,
                            text: this.$t(item.key),
                            disabled: item.disabled,
                        };
                    })
                    .filter((item) => !item.disabled),
            ];
        },
    },
    created() {
        this.setData();
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.company[name];
            return $dirty && this.submitted ? !$error : null;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        postCompany: function () {
            this.submitted = true;
            this.$v.company.$touch();
            if (this.$v.company.$anyError) {
                return;
            }

            let payload = {
                ...this.company,
                ...{ PhoneNumber: this.fullPhone.formattedNumber },
            };
            this.$store
                .dispatch(COMPANY_CREATE, payload)
                .then(() => {
                    this.$store.dispatch(COMPANIES_GET);
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        editCompany() {
            this.submitted = true;
            this.$v.company.$touch();
            if (this.$v.company.$anyError) {
                return;
            }

            let payload = {
                ...this.company,
                ...{ PhoneNumber: this.fullPhone.formattedNumber },
            };
            this.$store
                .dispatch(COMPANY_PATCH, payload)
                .then(() => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        setData() {
            if (this.getModalData !== null) {
                this.company = { ...this.getModalData.company };
                this.createMode = false;
                this.company.countryCode = parsePhoneNumberFromString(
                    this.company.PhoneNumber
                ).country;
                this.company.PhoneNumber = parsePhoneNumberFromString(
                    this.company.PhoneNumber
                ).nationalNumber;
            } else {
                this.company = { ...this.companyTemplate };
            }
            this.$v.company.$reset();
        },
        onShow() {
            this.setData();
        },
        onClose() {
            this.$v.company.$reset();
            this.createMode = true;
            this.company = { ...this.companyTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
            this.submitted = false;
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
    },
};
</script>

<style>
#card-title {
    font-size: 1em;
}
</style>
