export const ACTION_GET_ALL_PASSPORTS = "ACTION_GET_ALL_PASSPORTS";
export const ACTION_GET_PASSPORT = "ACTION_GET_PASSPORT";
export const ACTION_ACTIVATE_PASSPORT = "ACTION_ACTIVATE_PASSPORT";
export const ACTION_DEACTIVATE_PASSPORT = "ACTION_DEACTIVATE_PASSPORT";

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_ACTIVE_PASSPORT = "SET_ACTIVE_PASSPORT";
export const SET_INITIAL_ACTIVE_PASSPORT = "SET_INITIAL_ACTIVE_PASSPORT";
export const SET_ACTIVE_PASSPORT_NAME = "SET_ACTIVE_PASSPORT_NAME";
export const SET_ACTIVE_PASSPORT_VALIDITY = "SET_ACTIVE_PASSPORT_VALIDITY";
export const SET_ACTIVE_PASSPORT_DOORS = "SET_ACTIVE_PASSPORT_DOORS";
export const SET_ACTIVE_PASSPORT_USERS = "SET_ACTIVE_PASSPORT_USERS";
export const SET_ACTIVE_PASSPORT_LAYOUT = "SET_ACTIVE_PASSPORT_LAYOUT";
export const SET_ACTIVE_PASSPORT_IMAGE = "SET_ACTIVE_PASSPORT_IMAGE";
export const SET_ACTIVE_PASSPORT_IMAGE_SMALL = "SET_ACTIVE_PASSPORT_IMAGE_SMALL";
export const SET_ACTIVE_PASSPORT_IMAGE_MEDIUM = "SET_ACTIVE_PASSPORT_IMAGE_MEDIUM";
export const SET_ACTIVE_PASSPORT_IMAGE_LARGE = "SET_ACTIVE_PASSPORT_IMAGE_LARGE";
export const SET_ACTIVE_PASSPORT_ID = "SET_ACTIVE_PASSPORT_ID";
export const SET_ALL_PASSPORTS = "SET_ALL_PASSPORTS";
export const SET_PASS_MODE = "SET_PASS_MODE";
export const SET_SENDING = "SET_SENDING";

export const ERROR_NAME = "ERROR_NAME";
export const ERROR_NAME_LENGTH = "ERROR_NAME_LENGTH";
export const ERROR_VALIDITY = "ERROR_VALIDITY";
export const ERROR_DOORS = "ERROR_DOORS";
export const ERROR_IMAGE_TITLE = "ERROR_IMAGE_TITLE";
