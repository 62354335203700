var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-vertical fixed-left navbar-expand-md navbar-light"},[_c('div',{staticClass:"container-fluid"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'developers' }}},[_vm._v(" Authentication ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                        name: 'developers',
                        params: { page: 'accesses' },
                    }}},[_vm._v(" Key Accesses ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'developers', params: { page: 'keys' } }}},[_vm._v(" User keys ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'developers', params: { page: 'share' } }}},[_vm._v(" Share key ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'developers', params: { page: 'revoke' } }}},[_vm._v(" Revoke key ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                        name: 'developers',
                        params: { page: 'update-key' },
                    }}},[_vm._v(" Update key "),_c('span',{staticClass:"badge badge-secondary ml-auto"},[_vm._v("new")])])],1)]),_c('div',{staticClass:"mt-auto"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"navbar-brand-img mx-auto py-1",attrs:{"src":"/logo-p.png","alt":"Zesec"}})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }